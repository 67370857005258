import 'draft-js/dist/Draft.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { useMsal } from '@azure/msal-react';
import { DefaultButton, TextField } from '@fluentui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { convertToRaw, EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import draftToHtml from 'draftjs-to-html';
import React from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { QUERY_KEYS, TEXT_EDITOR_TOOLBAR } from '../../../consts';
import { notifyRequestErr } from '../../../helpers/utils';
import { LoaderContext } from '../../../routes/router';
import { orderService } from '../../../services/orderService';
import { ICommentRes } from '../../../store/models.ts/comment';
import { IExeption } from '../../../store/models.ts/common';

const defualtValues: ICommentRes = {
    poDocId: '',
    parentMessageId: null,
    content: '',
    title: null,
};

const TextEditor: React.FC = () => {
    const ctxt = React.useContext(LoaderContext);
    const queryClient = useQueryClient();
    const { id } = useParams();
    const { accounts } = useMsal();
    const editorState = EditorState.createEmpty();
    const [commentContent, setCommentContent] = React.useState(editorState);

    const onEditorStateChange = (editorState: any) => {
        setCommentContent(editorState);
    };

    const { handleSubmit, control, reset } = useForm<ICommentRes>({
        defaultValues: defualtValues,
        reValidateMode: 'onChange',
    });

    const { mutate } = useMutation(orderService.postComments, {
        onSuccess: async () => {
            ctxt?.setLoaderIsActive(false);
            setCommentContent(editorState);
            reset();

            queryClient.invalidateQueries([QUERY_KEYS.COMMENTS]);
        },
        onError: async (err: { response: { data: IExeption } }) => {
            // toast.error(err?.response?.data.message, {
            //     position: toast.POSITION.BOTTOM_RIGHT,
            // });
            notifyRequestErr(err);
            ctxt?.setLoaderIsActive(false);
        },
    });

    const onSubmit = (data: ICommentRes) => {
        const htmlOutput = stateToHTML(commentContent.getCurrentContent());
        if (id) {
            ctxt?.setLoaderIsActive(true);
            mutate({
                ...defualtValues,
                ...data,
                content: htmlOutput,
                poDocId: id,
            });
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
                control={control}
                name="title"
                render={({ field: { value, onChange } }) => (
                    <TextField
                        value={value as string}
                        onChange={onChange}
                        label={`${accounts[0]?.name}`}
                        className="filter_border_style"
                        placeholder="Title"
                        styles={{
                            fieldGroup: {
                                borderColor: '#e6e6e6',
                            },
                        }}
                    />
                )}
            />
            <Editor
                editorState={commentContent}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={onEditorStateChange}
                toolbar={TEXT_EDITOR_TOOLBAR}
            />
            <textarea
                style={{ display: 'none' }}
                disabled
                value={draftToHtml(convertToRaw(commentContent.getCurrentContent()))}
            />
            <div className="text-right">
                <DefaultButton
                    text="Post your comment"
                    allowDisabledFocus
                    className="mt-4 bg-black text-white pt-4 pb-4"
                    type="submit"
                />
            </div>
        </form>
    );
};
export default TextEditor;
