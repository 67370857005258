import { PublicClientApplication } from '@azure/msal-browser';

import { loginRequest, msalConfig } from './authConfig';

export const msalInstance = new PublicClientApplication(msalConfig);

export const getAuthHead = async () => {
    const accounts = msalInstance.getAllAccounts();

    const res = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
    });

    return {
        headers: {
            authorization: `Bearer ${res.accessToken}`,
            'cache-control': 'no-cache',
            pragma: 'no-cache',
        },
    };
};
