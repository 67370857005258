import { Spinner, SpinnerSize } from '@fluentui/react';
import React from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { useSelector } from 'react-redux';

import { Panel } from '../../common/panel';
import { EProcessStatus } from '../../helpers/utils/redux';
import { dashboardSelector } from '../../store/reducers/dashboardSlice';

export const CountsPanel: React.FC = () => {
    const orders = useSelector(dashboardSelector);
    return (
        <Container style={{ maxWidth: '97.5%' }} className="pt-2 pb-3">
            <p className="text-[20px] mr-[30px] pb-3">
                <b>Orders in production</b>
            </p>
            <Row>
                <Col md={4}>
                    <Panel>
                        <p className="text-[20px] text-center pt-[5px] pb-[5px] flex items-center justify-center">
                            {orders.status === EProcessStatus.PENDING ? (
                                <Spinner size={SpinnerSize.medium} className="mr-4" />
                            ) : (
                                <b className="mr-2">
                                    {new Intl.NumberFormat('en-us').format(
                                        orders.data.orderSummaries.totalNumberOfOrders,
                                    )}
                                </b>
                            )}
                            orders
                        </p>
                    </Panel>
                </Col>
                <Col md={4}>
                    <Panel>
                        <p className="text-[20px] pt-[5px] pb-[5px] flex items-center justify-center">
                            {orders.status === EProcessStatus.PENDING ? (
                                <Spinner size={SpinnerSize.medium} className="mr-4" />
                            ) : (
                                <b className="mr-2">
                                    {new Intl.NumberFormat('en-us').format(
                                        orders.data.orderSummaries.totalNumberOfItems,
                                    )}
                                </b>
                            )}
                            items
                        </p>
                    </Panel>
                </Col>
                <Col md={4}>
                    <Panel>
                        <p className="text-[20px] text-center pt-[5px] pb-[5px] flex items-center justify-center">
                            {orders.status === EProcessStatus.PENDING ? (
                                <Spinner size={SpinnerSize.medium} className="mr-4" />
                            ) : (
                                <b className="mr-2">
                                    {new Intl.NumberFormat('en-us').format(
                                        orders.data.orderSummaries.totalNumberOfPieces,
                                    )}
                                </b>
                            )}
                            pieces
                        </p>
                    </Panel>
                </Col>
            </Row>
        </Container>
    );
};
