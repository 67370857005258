import { DefaultButton } from '@fluentui/react';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';

import { EmptyPanel } from '../../common/emptyPanel';
import { Panel } from '../../common/panel';
import { SkeletonElement } from '../../common/skeleton';
import { ROUTES } from '../../consts';
import { EProcessStatus } from '../../helpers/utils/redux';
import { dashboardSelector } from '../../store/reducers/dashboardSlice';

export const OrderListPanel: React.FC = () => {
    const orders = useSelector(dashboardSelector);
    const navigate = useNavigate();
    return (
        <Panel>
            <div className="panelHeader border-b-2 p-8 pb-[18px] pt-[25]">
                <div className="flex items-center justify-between">
                    <p className="text-[20px]">
                        <b>Orders list</b>
                    </p>
                    <DefaultButton onClick={() => navigate(ROUTES.PRODUCTION_FOLLOW_UP)}>
                        Detailed view
                    </DefaultButton>
                </div>
            </div>
            {orders.status === EProcessStatus.PENDING ? (
                <SkeletonElement itemCount={3} className="p-8 pt-11 pb-5 height-full" />
            ) : orders.status === EProcessStatus.SUCCESS ? (
                orders.data.orders.length ? (
                    <Table className="production_follow_table_dashboard mt-5 mb-11">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left" className="border-none pb-[5px]">
                                    PO#
                                </TableCell>
                                <TableCell align="left" className="border-none pb-[5px]">
                                    Style#
                                </TableCell>
                                <TableCell align="left" className="border-none pb-[5px]">
                                    Production stage
                                </TableCell>
                                <TableCell align="left" className="border-none pb-[5px]">
                                    Days to delivery
                                </TableCell>
                                <TableCell align="left" className="border-none pb-[5px]">
                                    ETD
                                </TableCell>
                                <TableCell align="right" className="border-none pb-[5px]">
                                    QTY
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {orders.data.orders.slice(0, 5).map((order) => (
                                <TableRow key={order.poDocId}>
                                    <TableCell
                                        align="left"
                                        className="border-none underline pb-[3px]"
                                    >
                                        <NavLink
                                            to={`../${ROUTES.ORDER_DETAILS}/${order.poDocId}?isMaster=${order.isMaster}`}
                                            className="underline"
                                        >
                                            {order.orderIdToBeDisplayed}
                                        </NavLink>
                                    </TableCell>
                                    <TableCell align="left" className="border-none pb-[3px]">
                                        {order.item}
                                    </TableCell>
                                    <TableCell align="center" className="border-none pb-[3px]">
                                        <div className="flex items-center">
                                            <div>
                                                <div
                                                    className={`dot mr-3 ${
                                                        order.productionStatusCompleted ===
                                                        order.productionStatusTotal
                                                            ? 'bg-hard-green'
                                                            : 'bg-hard-blue'
                                                    }`}
                                                ></div>
                                            </div>
                                            <div>
                                                {order.productionStage}
                                                <p className="text-hard-gray text-left text-[13px] mt-[3px]">
                                                    {order.productionStatusCompleted}/
                                                    {order.productionStatusTotal} completed
                                                </p>
                                            </div>
                                        </div>
                                    </TableCell>
                                    <TableCell align="center" className="border-none pb-[3px]">
                                        <div
                                            className={`chip ${
                                                order.daysToDelivery <= 1 &&
                                                order.daysToDelivery !== 0
                                                    ? 'bg-estimate-danger'
                                                    : order.daysToDelivery === 0
                                                    ? 'bg-soft-green'
                                                    : 'bg-estimate-normal'
                                            }`}
                                        >
                                            <div className="el pr-3 pl-3">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="h-6 w-6 mr-3"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                    strokeWidth={2}
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                                                    />
                                                </svg>
                                                {order.productionStatusCompleted ===
                                                order.productionStatusTotal
                                                    ? 'Delivered'
                                                    : `${order.daysToDelivery} days left`}
                                            </div>
                                        </div>
                                    </TableCell>
                                    <TableCell align="center" className="border-none pb-[5px]">
                                        {moment(order.expectedDeliveryDate).format('MM-DD-YYYY')}
                                    </TableCell>
                                    <TableCell align="right" className="border-none pb-[5px]">
                                        {order.pieces} pcs.
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                ) : (
                    <EmptyPanel text="Nothing to show here!" className="p-10" />
                )
            ) : (
                <p>Error...</p>
            )}
        </Panel>
    );
};
