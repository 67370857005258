import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DEFAULT_DASHBOARD_FILTERS } from '../../consts';
import { EProcessStatus } from '../../helpers/utils/redux';
import { fetchDashboardInfo } from '../asyncActions/dashboard';
import { IDashboardFilter, IDashboardInfo } from '../models.ts/dashboard';
import { RootState } from '../store';

export interface IInitialState {
    data: IDashboardInfo;
    status: EProcessStatus;
    filter: IDashboardFilter;
}
const initialState: IInitialState = {
    data: {
        orderSummaries: {
            totalNumberOfItems: 0,
            totalNumberOfOrders: 0,
            totalNumberOfPieces: 0,
        },
        orders: [],
        productionStagesOrderedQuantities: [],
        messages: [],
    },
    status: EProcessStatus.IDLE,
    filter: {
        ...DEFAULT_DASHBOARD_FILTERS,
    },
};

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: initialState,
    reducers: {
        setFilterFields: (state, action: PayloadAction<any>) => {
            state.filter = action.payload;
        },
        changeMessageValue: (state, action: PayloadAction<any>) => {
            state.data.messages = action.payload;
        },
    },

    extraReducers: {
        [fetchDashboardInfo.pending as any]: (state: IInitialState) => {
            state.status = EProcessStatus.PENDING;
        },
        [fetchDashboardInfo.fulfilled as any]: (state: IInitialState, action: any) => {
            state.status = EProcessStatus.SUCCESS;
            state.data = action.payload;
        },
        [fetchDashboardInfo.rejected as any]: (state: IInitialState) => {
            state.status = EProcessStatus.ERROR;
        },
    },
});

export const dashboardSelector = (state: RootState) => state.dashboard;

export default dashboardSlice.reducer;
