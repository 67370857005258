import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { BaseUrl } from '../../consts';
import { loginRequest } from '../../helpers/authConfig';
import { getAuthHead, msalInstance } from '../../helpers/authHelper';
import { ITechSheetRes } from '../models.ts/techSheet';

export const getAuthHeadFormData = async () => {
    const accounts = msalInstance.getAllAccounts();

    const res = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
    });

    return {
        headers: {
            'Content-Type': 'multipart/form-data',
            authorization: `Bearer ${res.accessToken}`,
        },
    };
};

export const fetchSheets = createAsyncThunk('files/getFiles', async (id: string) => {
    const auth = await getAuthHead();
    const response = await axios.get(`${BaseUrl}files/${id}`, auth);
    return response.data as ITechSheetRes[];
});

export const uploadFile = createAsyncThunk('files/uploadFile', async (data: any) => {
    const auth = await getAuthHeadFormData();
    const response = await axios.post(`${BaseUrl}files/uploadFile`, data, auth);
    return response.data as ITechSheetRes[];
});

export const deleteFile = createAsyncThunk(
    'files/deleteFile',
    async (data: { orderId: string; fileName: string }) => {
        const auth = await getAuthHead();
        const response = await axios.delete(
            `${BaseUrl}files/${data.orderId}/${data.fileName}`,
            auth,
        );
        return response.data as { status: boolean; message: string };
    },
);
