import { TextField } from '@fluentui/react';
import React from 'react';
import { UseFormSetValue } from 'react-hook-form';

import { IGenderFormType } from '../vendorSurvey/gender';

interface IProps {
    setValue: UseFormSetValue<IGenderFormType>;
    genderValues: IGenderFormType | null;
}

export const RatioInput: React.FC<IProps> = ({ setValue, genderValues }) => {
    const [menValue, setMenValue] = React.useState(0);
    const [womenValue, setWomenValue] = React.useState(0);
    const [nonBinaryValue, setNonBinaryValue] = React.useState(0);

    const handleInputValueChange = React.useCallback(
        (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, option: string) => {
            const total = 100;
            if (option === 'women') {
                setWomenValue(Number(e.currentTarget.value));
                setMenValue((total - Number(e.currentTarget.value)) / 2);
                setNonBinaryValue((total - Number(e.currentTarget.value)) / 2);
            } else if (option === 'men') {
                setMenValue(Number(e.currentTarget.value));
                setWomenValue((total - Number(e.currentTarget.value)) / 2);
                setNonBinaryValue((total - Number(e.currentTarget.value)) / 2);
            } else if (option === 'nonBinary') {
                setNonBinaryValue(Number(e.currentTarget.value));
                setWomenValue((total - Number(e.currentTarget.value)) / 2);
                setMenValue((total - Number(e.currentTarget.value)) / 2);
            }
        },
        [menValue, womenValue, nonBinaryValue],
    );

    React.useEffect(() => {
        if (menValue !== 0 || womenValue !== 0 || nonBinaryValue !== 0) {
            setValue('ratioValue', `${menValue} - ${womenValue} - ${nonBinaryValue}`);
        } else {
            setValue('ratioValue', '');
        }
    }, [menValue, womenValue]);

    React.useEffect(() => {
        if (genderValues !== null || genderValues !== ({} as any)) {
            const b = genderValues?.ratioValue?.split('-');
            if (b?.length) {
                setMenValue(Number(b[0]));
                setWomenValue(Number(b[1]));
                setNonBinaryValue(Number(b[2]));
            }
        }
    }, [genderValues]);

    return (
        <>
            <div className="flex items-center text-center">
                <TextField
                    type="number"
                    placeholder="___"
                    label="Men"
                    className="mr-4 ratio-input"
                    value={String(menValue)}
                    onChange={(e) => handleInputValueChange(e, 'men')}
                    min={0}
                    max={100}
                    styles={{
                        fieldGroup: {
                            borderColor: '#E6E6E6',
                            textAlign: 'center',
                            padding: '10px',
                            paddingLeft: '25px',
                            height: '60px',
                            width: '180px',
                        },
                        field: {
                            color: 'black',
                            fontWeight: 'bold',
                            font: 'font: normal normal normal 26px/34px Circular XX;',
                            textAlign: 'center',
                            fontSize: '25px',
                        },
                    }}
                    step={0.5}
                />
                <TextField
                    type="number"
                    placeholder="___"
                    label="Women"
                    className="ratio-input mr-4"
                    value={String(womenValue)}
                    onChange={(e) => handleInputValueChange(e, 'women')}
                    min={0}
                    max={100}
                    styles={{
                        fieldGroup: {
                            borderColor: '#E6E6E6',
                            textAlign: 'center',
                            padding: '10px',
                            paddingLeft: '25px',
                            height: '60px',
                            width: '180px',
                        },
                        field: {
                            color: 'black',
                            fontWeight: 'bold',
                            font: 'font: normal normal normal 26px/34px Circular XX;',
                            textAlign: 'center',
                            fontSize: '25px',
                        },
                    }}
                    step={0.5}
                />
                <TextField
                    type="number"
                    placeholder="___"
                    label="Non binary"
                    className="ratio-input"
                    value={String(nonBinaryValue)}
                    onChange={(e) => handleInputValueChange(e, 'nonBinary')}
                    min={0}
                    max={100}
                    styles={{
                        fieldGroup: {
                            borderColor: '#E6E6E6',
                            textAlign: 'center',
                            padding: '10px',
                            paddingLeft: '25px',
                            height: '60px',
                            width: '180px',
                        },
                        field: {
                            color: 'black',
                            fontWeight: 'bold',
                            font: 'font: normal normal normal 26px/34px Circular XX;',
                            textAlign: 'center',
                            fontSize: '25px',
                        },
                    }}
                    step={0.5}
                />
            </div>
        </>
    );
};
