import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useParams } from 'react-router-dom';

interface IFileDropzoneProps {
    formData: FormData;
    onUpload: (formData: FormData) => void;
}

export const FileDropzone = ({ onUpload, formData }: IFileDropzoneProps) => {
    const { id } = useParams();

    const baseStyle = {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '40px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#E6E6E6',
        borderStyle: 'dashed',
        backgroundColor: '#fff',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out',
    };

    const focusedStyle = {
        borderColor: '#2196f3',
    };

    const acceptStyle = {
        borderColor: '#00e676',
    };

    const rejectStyle = {
        borderColor: '#ff1744',
    };

    const onDropAccepted = useCallback(
        (acceptedFiles) => {
            acceptedFiles.map((file: any) => {
                formData.append('Files', file);
            });
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            formData.append('poDocId', id!);
            onUpload(formData);
        },
        [onUpload],
    );

    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject,
        isDragActive,
        acceptedFiles,
    } = useDropzone({
        onDropAccepted,
    });

    const style = React.useMemo(
        () => ({
            ...baseStyle,
            ...(isFocused ? focusedStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isFocused, isDragAccept, isDragReject],
    );

    const acceptedFileItems = acceptedFiles.map((file: any) => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    ));

    return (
        <div className="cursor-pointer">
            <div {...getRootProps({ style } as any)}>
                <input {...getInputProps()} />
                {isDragAccept && <p>All files will be accepted</p>}
                {isDragReject && <p>Some files will be rejected</p>}
                {!isDragActive && (
                    <>
                        <div className="flex items-center text-[17px]">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-6 w-6 mr-3"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth={2}
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
                                />
                            </svg>
                            <p className="text-txt-gray">
                                Drag and drop or{' '}
                                <b className="text-black">
                                    <u>Browse</u>
                                </b>
                            </p>
                        </div>
                        {acceptedFileItems.length >= 1 && (
                            <aside>
                                <h4>Uploaded files</h4>
                                <ul>{acceptedFileItems}</ul>
                            </aside>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};
