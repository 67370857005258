import React from 'react';

import { ISurveyQuestionIds } from '../../consts';
import { EQuestionIds } from '../../enums';
import { VendorSurveyContext } from '../../helpers/hooks';
import { Access, IAccessFormType } from './access';
import { Charity, ICharityFormType } from './charity';
import { EnergySaving, IEnergyFormType } from './energySaving';
import { Gender, IGenderFormType } from './gender';
import { IRecyclingFormType, Recycling } from './recycling';
import { SurveyFinishAlert } from './surveyFinishAlert';
import { IToxicFormType, Toxic } from './toxic';
import { IWaterFormType, WaterUsage } from './waterUsage';

interface IProps {
    setActiveStepId: React.Dispatch<React.SetStateAction<number[]>>;
    activeStepId: number[];
}

export const SurveyForm: React.FC<IProps> = ({ setActiveStepId, activeStepId }) => {
    const vendorValue = React.useContext(VendorSurveyContext);
    const [energyValues, setEnergyValues] = React.useState<IEnergyFormType | null>(null);
    const [waterValues, setWaterValues] = React.useState<IWaterFormType | null>(null);
    const [recyclingValues, setRecyclingValues] = React.useState<IRecyclingFormType | null>(null);
    const [toxicValues, setToxicValues] = React.useState<IToxicFormType | null>(null);
    const [accessValues, setAccessValues] = React.useState<IAccessFormType | null>(null);
    const [genderValues, setGenderValues] = React.useState<IGenderFormType | null>(null);
    const [charityValues, setCharityValues] = React.useState<ICharityFormType | null>(null);
    const [showFinishAlert, setShowFinishAlert] = React.useState(false);

    const handlePrevStep = () => {
        if (activeStepId[0] === 9) {
            setActiveStepId([6, 8]);
        }
        if (activeStepId[0] === 6) {
            setActiveStepId((prev) => [6, prev[1] - 1]);
        }
        if (activeStepId[1] === 7) {
            setActiveStepId([1, 5]);
        }
        if (activeStepId[0] === 1) {
            setActiveStepId((prev) => [1, prev[1] - 1]);
        }
    };

    const handleNewStep = () => {
        if (activeStepId[1] === 10) {
            setShowFinishAlert(true);
        } else {
            if (activeStepId[0] === 1) {
                setActiveStepId((prev) => [1, prev[1] + 1]);
            }
            if (activeStepId[1] === 5) {
                setActiveStepId([6, 7]);
            }
            if (activeStepId[0] === 6) {
                setActiveStepId((prev) => [6, prev[1] + 1]);
            }
            if (activeStepId[1] === 8) {
                setActiveStepId([9, 10]);
            }
        }
    };

    React.useEffect(() => {
        const questions: any = [];
        const newEnergyValues: any = {};
        const newWaterValues: any = {};
        const newRecyclingValues: any = {};
        const newToxicValues: any = {};
        const newAccessValues: any = {};
        const newGenderValues: any = {};
        const newCharityValues: any = {};
        if (vendorValue) {
            vendorValue.sections.map((section: any) =>
                section.questions.map((question: any) => questions.push(question)),
            ),
                questions.forEach((quest: any) => {
                    if (quest.questionId <= 4) {
                        if (quest.questionType === 'upload') {
                            const x: any = [];
                            if (quest.answer !== null) {
                                quest.answerToBeDisplayed
                                    ?.split(',')
                                    .map((url: string) => x.push(url));
                            } else {
                                x.push('');
                            }
                            newEnergyValues[EQuestionIds[quest.questionId]] = x;
                        } else {
                            newEnergyValues[EQuestionIds[quest.questionId]] = quest.answer;
                        }
                    } else if (quest.questionId <= 8 && quest.questionId > 4) {
                        if (quest.questionType === 'upload') {
                            const x: any = [];
                            if (quest.answer !== null) {
                                quest.answerToBeDisplayed
                                    ?.split(',')
                                    .map((url: string) => x.push(url));
                            } else {
                                x.push('');
                            }
                            newWaterValues[EQuestionIds[quest.questionId]] = x;
                        } else {
                            newWaterValues[EQuestionIds[quest.questionId]] = quest.answer;
                        }
                    } else if (quest.questionId <= 12 && quest.questionId > 8) {
                        if (quest.questionType === 'upload') {
                            const x: any = [];
                            if (quest.answer !== null) {
                                quest.answerToBeDisplayed
                                    ?.split(',')
                                    .map((url: string) => x.push(url));
                            } else {
                                x.push('');
                            }
                            newRecyclingValues[EQuestionIds[quest.questionId]] = x;
                        } else {
                            newRecyclingValues[EQuestionIds[quest.questionId]] = quest.answer;
                        }
                    } else if (quest.questionId <= 16 && quest.questionId > 12) {
                        if (quest.questionType === 'upload') {
                            const x: any = [];
                            if (quest.answer !== null) {
                                quest.answerToBeDisplayed
                                    ?.split(',')
                                    .map((url: string) => x.push(url));
                            } else {
                                x.push('');
                            }
                            newToxicValues[EQuestionIds[quest.questionId]] = x;
                        } else {
                            newToxicValues[EQuestionIds[quest.questionId]] = quest.answer;
                        }
                    } else if (quest.questionId <= 19 && quest.questionId > 16) {
                        if (quest.questionType === 'upload') {
                            const x: any = [];
                            if (quest.answer !== null) {
                                quest.answerToBeDisplayed
                                    ?.split(',')
                                    .map((url: string) => x.push(url));
                            } else {
                                x.push('');
                            }
                            newAccessValues[EQuestionIds[quest.questionId]] = x;
                        } else {
                            newAccessValues[EQuestionIds[quest.questionId]] = quest.answer;
                        }
                    } else if (quest.questionId <= 21 && quest.questionId > 19) {
                        newGenderValues[EQuestionIds[quest.questionId]] = quest.answer;
                    } else if (quest.questionId <= 26 && quest.questionId > 21) {
                        if (quest.questionType === 'upload') {
                            const x: any = [];
                            if (quest.answer !== null) {
                                quest.answerToBeDisplayed
                                    ?.split(',')
                                    .map((url: string) => x.push(url.split('-')[6]));
                            } else {
                                x.push('');
                            }
                            newCharityValues[EQuestionIds[quest.questionId]] = x;
                        } else {
                            newCharityValues[EQuestionIds[quest.questionId]] = quest.answer;
                        }
                    }
                });
            setEnergyValues(newEnergyValues);
            setWaterValues(newWaterValues);
            setRecyclingValues(newRecyclingValues);
            setToxicValues(newToxicValues);
            setAccessValues(newAccessValues);
            setGenderValues(newGenderValues);
            setCharityValues(newCharityValues);
        }
        // eslint-disable-next-line no-console
    }, [vendorValue]);

    React.useEffect(() => {
        const lstVndrVal = vendorValue?.sections[vendorValue.sections.length - 1];
        if (lstVndrVal) {
            if (lstVndrVal.sectionId === 10) {
                setActiveStepId([1, 2]);
            } else {
                if (lstVndrVal.parentSectionId === 1 && lstVndrVal.sectionId !== 5) {
                    setActiveStepId([1, lstVndrVal.sectionId + 1]);
                }
                if (lstVndrVal.sectionId === 5) {
                    setActiveStepId([6, 7]);
                }
                if (lstVndrVal.parentSectionId === 6 && lstVndrVal.sectionId !== 8) {
                    setActiveStepId([6, lstVndrVal.sectionId + 1]);
                }
                if (lstVndrVal.sectionId === 8) {
                    setActiveStepId([9, 10]);
                }
            }
        }
    }, [vendorValue]);

    return (
        <>
            {ISurveyQuestionIds.ENERGY === activeStepId[1] && (
                <EnergySaving
                    handleNewStep={handleNewStep}
                    handlePrevStep={handlePrevStep}
                    activeStepId={activeStepId}
                    setEnergyValues={setEnergyValues}
                    energyValues={energyValues}
                />
            )}
            {ISurveyQuestionIds.WATER === activeStepId[1] && (
                <WaterUsage
                    handleNewStep={handleNewStep}
                    handlePrevStep={handlePrevStep}
                    activeStepId={activeStepId}
                    waterValues={waterValues}
                    setWaterValues={setWaterValues}
                />
            )}
            {ISurveyQuestionIds.RECYCLING === activeStepId[1] && (
                <Recycling
                    handleNewStep={handleNewStep}
                    handlePrevStep={handlePrevStep}
                    activeStepId={activeStepId}
                    recyclingValues={recyclingValues}
                    setRecyclingValues={setRecyclingValues}
                />
            )}
            {ISurveyQuestionIds.TOXIC === activeStepId[1] && (
                <Toxic
                    handleNewStep={handleNewStep}
                    handlePrevStep={handlePrevStep}
                    activeStepId={activeStepId}
                    toxicValues={toxicValues}
                    setToxicValues={setToxicValues}
                />
            )}
            {ISurveyQuestionIds.ACCESS === activeStepId[1] && (
                <Access
                    handleNewStep={handleNewStep}
                    handlePrevStep={handlePrevStep}
                    activeStepId={activeStepId}
                    accessValues={accessValues}
                    setAccessValues={setAccessValues}
                />
            )}
            {ISurveyQuestionIds.GENDER === activeStepId[1] && (
                <Gender
                    handleNewStep={handleNewStep}
                    handlePrevStep={handlePrevStep}
                    activeStepId={activeStepId}
                    genderValues={genderValues}
                    setGenderValues={setGenderValues}
                />
            )}
            {ISurveyQuestionIds.CHARITY === activeStepId[1] && (
                <Charity
                    handleNewStep={handleNewStep}
                    handlePrevStep={handlePrevStep}
                    activeStepId={activeStepId}
                    charityValues={charityValues}
                    setCharityValues={setCharityValues}
                />
            )}
            <SurveyFinishAlert setOpen={showFinishAlert} onClose={setShowFinishAlert} />
        </>
    );
};
