import { RedirectBaseUrl } from '../consts';

export const msalConfig = {
    auth: {
        clientId: '4d8cdebd-c53f-4bbf-bd1c-bdf52c801d80',
        authority:
            'https://FRAMEB2C.b2clogin.com/FRAMEB2C.onmicrosoft.com/B2C_1_Frame_SignIn_SignUp_v1/',
        knownAuthorities: ['https://FRAMEB2C.b2clogin.com/'],
        redirectUri: RedirectBaseUrl,
    },
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false,
    },
};

export const loginRequest = {
    scopes: ['https://FRAMEB2C.onmicrosoft.com/4d8cdebd-c53f-4bbf-bd1c-bdf52c801d80/access-web'],
};

export const graphConfig = {
    graphMeEndpoint: '',
};
