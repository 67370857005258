import { useMsal } from '@azure/msal-react';
import { DefaultButton, IIconProps } from '@fluentui/react';
import * as React from 'react';

import { signOutClickHandler } from '../helpers/utils';
import { IExeption } from '../store/models.ts/common';
import { Panel } from './panel';

interface IProps {
    /** Common error. */
    error: IExeption;

    /** Validation error list. */
    validationErrors?: string[];

    /** dataTestId. */
    dataTestId?: string;
}

/**
 * Error panel - render panel with error message or list of panels with validation errors.
 */
export const ExeptionPanel: React.FC<IProps> = (props) => {
    const tableIcon: IIconProps = { iconName: 'ChevronLeft' };
    const { accounts, instance } = useMsal();
    const { error } = props;

    return (
        <Panel
            className="w-[650px] translate-y-1/2 p-10 shadow-md
        "
        >
            <h4 className="text-lg mb-3">Something went wrong..</h4>
            <p className="text-lg">{error?.message || 'Unexpected error'}</p>
            {error.statusCode === 401 && (
                <div className="flex flex-row-reverse">
                    <DefaultButton
                        onClick={() => signOutClickHandler(instance, accounts[0]?.homeAccountId)}
                        className="mt-5"
                        iconProps={tableIcon}
                    >
                        Back to login
                    </DefaultButton>
                </div>
            )}
        </Panel>
    );
};

ExeptionPanel.displayName = 'ExeptionPanel';
