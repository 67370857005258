import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';

import comment from './reducers/commentSlice';
import dashboard from './reducers/dashboardSlice';
import order from './reducers/orderSlice';
import productionFollow from './reducers/productionFollowSlice';
import productionStages from './reducers/productionStagesSlice';
import publishViewOrders from './reducers/publishViewOrderSlice';
import supplier from './reducers/supplierSlice';
import techSheet from './reducers/techSheetSlice';

const reducer = {
    productionFollow,
    publishViewOrders,
    productionStages,
    dashboard,
    supplier,
    comment,
    techSheet,
    order,
};

export const store = configureStore({
    reducer: reducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
