import React from 'react';

interface IProps {
    children: React.ReactNode;
    className?: string;
}

export const Panel: React.FC<IProps> = ({ children, className }) => {
    return (
        <div
            className={`bg-white border-solid border-[#E6E6E6] p-[10px] border mx-auto w-full ${className}`}
        >
            {children}
        </div>
    );
};
