import { DefaultButton } from '@fluentui/react';
import moment from 'moment';
import React, { useMemo } from 'react';
import { Col, Row } from 'react-grid-system';
import { useSearchParams } from 'react-router-dom';

import { Panel } from '../../common/panel';
import p30 from '../../images/155x231.png';
import { IOrder } from '../../store/models.ts/common';
import { EditDetailForm } from './editDetailForm';

interface IProps {
    order: IOrder;
}

export const PoInfo: React.FC<IProps> = ({ order }) => {
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [searchParams] = useSearchParams();
    const isMaster = useMemo(() => searchParams.get('isMaster'), [searchParams]);

    return (
        <>
            <Panel className="pt-5 pb-5 pl-10 pr-10 mt-10 border-b-0">
                <div className="head flex items-center justify-between">
                    <div className="flex items-center">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5 mr-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                        >
                            <path d="M4 3a2 2 0 100 4h12a2 2 0 100-4H4z" />
                            <path
                                fillRule="evenodd"
                                d="M3 8h14v7a2 2 0 01-2 2H5a2 2 0 01-2-2V8zm5 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z"
                                clipRule="evenodd"
                            />
                        </svg>
                        <p>PO - Purchase order info</p>
                    </div>
                    {isMaster === 'false' && (
                        <DefaultButton
                            text="Edit"
                            allowDisabledFocus
                            type="submit"
                            onClick={() => setIsModalOpen(true)}
                        />
                    )}
                </div>
            </Panel>
            <Panel className="pt-5 pb-5 pl-10 pr-10">
                <Row>
                    <Col md={1.5}>
                        <div className="img">
                            <img src={p30} alt="help" />
                        </div>
                    </Col>
                    <Col md={10.5}>
                        <Row>
                            <Col md={12}>
                                <div className="flex justify-between mb-5">
                                    <div className="itemz">
                                        <p>
                                            <b>#Style</b>
                                        </p>
                                        <p className="text-txt-gray">{order.style}</p>
                                    </div>
                                    <div className="itemz">
                                        <p>
                                            <b>#Style Desc</b>
                                        </p>
                                        <p className="text-txt-gray">{order.styleDesc}</p>
                                    </div>
                                    <div className="itemz">
                                        <p>
                                            <b>Color</b>
                                        </p>
                                        <p className="text-txt-gray">{order.color}</p>
                                    </div>
                                    <div className="itemz">
                                        <p>
                                            <b>Color Desc</b>
                                        </p>
                                        <p className="text-txt-gray">{order.colorDescription}</p>
                                    </div>
                                    <div className="itemz">
                                        <p>
                                            <b>Vendor</b>
                                        </p>
                                        <p className="text-txt-gray">{order.vendorDs}</p>
                                    </div>
                                    <div className="itemz max-w-xs">
                                        <p>
                                            <b>Destination</b>
                                        </p>
                                        <p className="text-txt-gray">{order.destination}</p>
                                    </div>
                                    <div className="itemz">
                                        <p>
                                            <b>Order date</b>
                                        </p>
                                        <p className="text-txt-gray">
                                            {moment(order.orderDate).format('MM-DD-YYYY')}
                                        </p>
                                    </div>
                                    <div className="itemz">
                                        <p>
                                            <b>Days to delivery</b>
                                        </p>
                                        <p className="text-txt-gray">{order.daysToDelivery}</p>
                                    </div>
                                    <div className="itemz">
                                        <p>
                                            <b>ETD</b>
                                        </p>
                                        <p className="text-txt-gray">
                                            {moment(order.expectedDepartureDate).format(
                                                'MM-DD-YYYY',
                                            )}
                                        </p>
                                    </div>
                                    <div className="itemz">
                                        <p>
                                            <b>Original ETD</b>
                                        </p>
                                        <p className="text-txt-gray">
                                            {moment(order.originalExpectedDepartureDate).format(
                                                'MM-DD-YYYY',
                                            )}
                                        </p>
                                    </div>
                                    <div className="itemz">
                                        <p>
                                            <b>ETA</b>
                                        </p>
                                        <p className="text-txt-gray">
                                            {moment(order.expectedDeliveryDate).format(
                                                'MM-DD-YYYY',
                                            )}
                                        </p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col md={10.5}>
                                <div className="flex items-center justify-between mt-5">
                                    <div className="itemz">
                                        <p>
                                            <b>
                                                {isMaster === 'false'
                                                    ? 'Shipped Size Grid'
                                                    : 'Size Grid'}
                                            </b>
                                        </p>
                                        <div className="flex items-center">
                                            <div className="flex flex-col m-3 ml-0 text-center">
                                                <span className="p-2 text-black font-bold">
                                                    SIZE
                                                </span>
                                                <span className="p-2 text-black font-bold">
                                                    QTY
                                                </span>
                                            </div>
                                            {order.sizeGrid?.map((s, i) => (
                                                <div
                                                    className="flex flex-col m-3 ml-0 text-center"
                                                    key={i}
                                                >
                                                    <span className="p-2 border text-black">
                                                        {s.size}
                                                    </span>
                                                    <span className="p-2 border-t-0 border text-black">
                                                        {isMaster === 'false'
                                                            ? s.shippedQuantity
                                                            : s.count}
                                                    </span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="itemz ">
                                        <p>
                                            <b>{isMaster === 'false' && 'Shipped '}QTY</b>
                                        </p>
                                        <p className="text-txt-gray">
                                            {isMaster === 'false'
                                                ? order.totalShippedQuantity
                                                : order.quantity}
                                        </p>
                                    </div>
                                    {isMaster !== 'false' ? (
                                        <>
                                            <div className="itemz">
                                                <p>
                                                    <b>Price</b>
                                                </p>
                                                <p className="text-txt-gray">
                                                    {new Intl.NumberFormat(
                                                        order.cost?.currencyId === 'USD'
                                                            ? 'en-Us'
                                                            : 'de-De',
                                                        {
                                                            style: 'currency',
                                                            currency:
                                                                order.cost?.currencyId || 'USD',
                                                            currencyDisplay: 'code',
                                                        },
                                                    ).format(Number(order.cost?.value))}
                                                </p>
                                            </div>
                                            <div className="itemz">
                                                <p>
                                                    <b>Total</b>
                                                </p>
                                                <p className="text-txt-gray">
                                                    {new Intl.NumberFormat(
                                                        order.cost?.currencyId === 'USD'
                                                            ? 'en-Us'
                                                            : 'de-De',
                                                        {
                                                            style: 'currency',
                                                            currency:
                                                                order.cost?.currencyId || 'USD',
                                                            currencyDisplay: 'code',
                                                        },
                                                    ).format(Number(order.totalCost?.value || 0))}
                                                </p>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="itemz "></div>
                                            <div className="itemz "></div>
                                            <div className="itemz "></div>
                                        </>
                                    )}
                                </div>
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col md={10.5}>
                                <div className="flex items-center justify-between mt-5">
                                    <div className="itemz">
                                        <p>
                                            <b>Original Size Grid</b>
                                        </p>
                                        <div className="flex items-center">
                                            <div className="flex flex-col m-3 ml-0 text-center">
                                                <span className="p-2 text-black font-bold">
                                                    SIZE
                                                </span>
                                                <span className="p-2 text-black font-bold">
                                                    QTY
                                                </span>
                                            </div>
                                            {order.sizeGrid?.map((s, i) => (
                                                <div
                                                    className="flex flex-col m-3 ml-0 text-center"
                                                    key={i}
                                                >
                                                    <span className="p-2 border text-black">
                                                        {s.size}
                                                    </span>
                                                    <span className="p-2 border-t-0 border text-black">
                                                        {s.originalCount}
                                                    </span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="itemz">
                                        <p>
                                            <b>Original QTY</b>
                                        </p>
                                        <p className="text-txt-gray">{order.originalQuantity}</p>
                                    </div>
                                    <div className="itemz">
                                        <p>
                                            <b>Original price</b>
                                        </p>
                                        <p className="text-txt-gray">
                                            {new Intl.NumberFormat(
                                                order.cost?.currencyId === 'USD'
                                                    ? 'en-Us'
                                                    : 'de-De',
                                                {
                                                    style: 'currency',
                                                    currency: order.cost?.currencyId || 'USD',
                                                    currencyDisplay: 'code',
                                                },
                                            ).format(Number(order.cost?.originalValue))}
                                        </p>
                                    </div>
                                    <div className="itemz">
                                        <p>
                                            <b>Original total</b>
                                        </p>
                                        <p className="text-txt-gray">
                                            {new Intl.NumberFormat(
                                                order.cost?.currencyId === 'USD'
                                                    ? 'en-Us'
                                                    : 'de-De',
                                                {
                                                    style: 'currency',
                                                    currency: order.cost?.currencyId || 'USD',
                                                    currencyDisplay: 'code',
                                                },
                                            ).format(Number(order.totalCost?.originalValue))}
                                        </p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Panel>
            <EditDetailForm
                isModalOpen={isModalOpen}
                setModalState={setIsModalOpen}
                order={order}
            />
        </>
    );
};
