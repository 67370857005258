import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { EProcessStatus } from '../../helpers/utils/redux';
import { fetchComments } from '../asyncActions/comment';
import { IComment } from '../models.ts/comment';
import { RootState } from '../store';

export interface IInitialState {
    data: IComment[];
    status: EProcessStatus;
}
const initialState: IInitialState = {
    data: [
        {
            messageId: 24,
            parentMessageId: null,
            poDocId: '',
            content: '',
            title: '',
            createdBy: '',
            createdOn: '',
        },
    ],
    status: EProcessStatus.IDLE,
};

export const commentSlice = createSlice({
    name: 'comment',
    initialState: initialState,
    reducers: {
        addComment: (state, action: PayloadAction<any>) => {
            state.data.unshift(action.payload);
        },
    },

    extraReducers: {
        [fetchComments.pending as any]: (state: IInitialState) => {
            state.status = EProcessStatus.PENDING;
        },
        [fetchComments.fulfilled as any]: (state: IInitialState, action: any) => {
            state.status = EProcessStatus.SUCCESS;
            state.data = action.payload;
        },
        [fetchComments.rejected as any]: (state: IInitialState) => {
            state.status = EProcessStatus.ERROR;
        },
    },
});

export const commentSelector = (state: RootState) => state.comment;

export default commentSlice.reducer;
