import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DEFAULT_PRODUCTION_FOLLOW_FILTERS } from '../../consts';
import { EProcessStatus } from '../../helpers/utils/redux';
import { fetchOrders } from '../asyncActions/order';
import { IOrder, IOrderRes } from '../models.ts/common';
import { IProductionFollowOrderFilters } from '../models.ts/ProductionFollowUp';
import { RootState } from '../store';

export interface IInitialState {
    data: IOrderRes;
    status: EProcessStatus;
    filter: IProductionFollowOrderFilters;
}
const initialState: IInitialState = {
    data: {
        elements: [],
        totalResults: 0,
    },
    status: EProcessStatus.IDLE,
    filter: { ...DEFAULT_PRODUCTION_FOLLOW_FILTERS },
};

export const orderSlice = createSlice({
    name: 'order',
    initialState: initialState,
    reducers: {
        setFilterFields: (state, action: PayloadAction<any>) => {
            state.filter = action.payload;
        },
        setLoadMore: (state, action: PayloadAction<any>) => {
            state.data = action.payload;
        },
        setPageSize: (state, action: PayloadAction<any>) => {
            state.filter.pageSize = action.payload;
        },
        setStageStatus: (state, action: PayloadAction<IOrder[]>) => {
            action.payload.forEach((pyl) => {
                const idxEl = state.data.elements.findIndex(
                    (el) => el.document.poDocId === pyl.poDocId,
                );
                state.data.elements[idxEl].document = pyl;
            });
        },
    },
    extraReducers: {
        [fetchOrders.pending as any]: (state: IInitialState) => {
            state.status = EProcessStatus.PENDING;
        },
        [fetchOrders.fulfilled as any]: (state: IInitialState, action: any) => {
            state.status = EProcessStatus.SUCCESS;
            state.data = action.payload;
        },
        [fetchOrders.rejected as any]: (state: IInitialState) => {
            state.status = EProcessStatus.ERROR;
        },
    },
});

export const orderSelector = (state: RootState) => state.order;

export default orderSlice.reducer;
