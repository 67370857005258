import * as React from 'react';

import { useExpose } from '../../../helpers/hooks';
import { ILogisticCompany, IOrderElems } from '../../../store/models.ts/common';
import { EditModal } from '../edit-modal';

type Tcontext = {
    orders: IOrderElems[];
    checkedRows: string[];
    isEditModalOpen: boolean;
    closeEditModal: () => void;
    isLoadMoreData: boolean | null;
    toggleEditModal: (val: any) => void;
    logisticsCompanies: ILogisticCompany[] | null;
    setCheckedRows: React.Dispatch<React.SetStateAction<string[]>>;
    setOrders: React.Dispatch<React.SetStateAction<IOrderElems[]>>;
    setLoadMoreData: React.Dispatch<React.SetStateAction<boolean | null>>;
    setLogisticsCompanies: React.Dispatch<React.SetStateAction<ILogisticCompany[] | null>>;
};

const ShippingAndTrackingContext = React.createContext<Tcontext>(null as any);

function ShippingAndTrackingProvider({ children }) {
    const [checkedRows, setCheckedRows] = React.useState<string[]>([]);
    const [orders, setOrders] = React.useState<IOrderElems[]>([]);
    const [logisticsCompanies, setLogisticsCompanies] = React.useState<ILogisticCompany[] | null>(
        null,
    );
    const { close: closeEditModal, toggle: toggleEditModal, isOpen: isEditModalOpen } = useExpose();
    const [isLoadMoreData, setLoadMoreData] = React.useState<boolean | null>(false);

    const value = {
        orders,
        setOrders,
        checkedRows,
        isLoadMoreData,
        setCheckedRows,
        closeEditModal,
        isEditModalOpen,
        toggleEditModal,
        setLoadMoreData,
        logisticsCompanies,
        setLogisticsCompanies,
    };

    return (
        <ShippingAndTrackingContext.Provider value={value}>
            {children}
            <EditModal />
        </ShippingAndTrackingContext.Provider>
    );
}

function useShippingAndTrackingContext() {
    const context = React.useContext(ShippingAndTrackingContext);
    if (context === undefined) {
        throw new Error('useModalContext must be used within a ModalProvider');
    }
    return context;
}

export { ShippingAndTrackingProvider, useShippingAndTrackingContext };
