import { AxiosRequestConfig } from 'axios';

/**
 * Language cookie key.
 */
export const COOKIE_LANGUAGE_KEY = 'lang';

/**
 * Header.
 */
export const config = {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
};

/**
 * Common REST configuration.
 */
export const commonRestConfig: AxiosRequestConfig = {
    baseURL: '',
};

/**
 * REST configuration with increased timeout.
 */
export const increasedTimeoutRestConfig: AxiosRequestConfig = {
    timeout: 60000,
};
