import axios from 'axios';

import { BaseUrl } from '../consts';
import { getAuthHead } from '../helpers/authHelper';
import { getAuthHeadFormData } from '../store/asyncActions/techSheet';

export const uploadSurveyFile = async (data: any) => {
    const auth = await getAuthHeadFormData();
    return await axios.post(`${BaseUrl}surveys/uploadFile`, data, auth);
};

export const saveSurveySection = async (data: any) => {
    const auth = await getAuthHead();
    return await axios.post(`${BaseUrl}surveys/insertSurveyAnswers`, data, auth);
};

export const deleteFile = async (fileName: string) => {
    const auth = await getAuthHead();
    return await axios.delete(`${BaseUrl}surveys/${fileName}`, auth);
};

export const getSurveyInfosForFrame = async () => {
    const auth = await getAuthHead();
    return await axios.get(`${BaseUrl}surveys/getSurveys`, auth);
};

export const getSurveyInfosForVendor = async () => {
    const auth = await getAuthHead();
    return await axios.get(`${BaseUrl}surveys/getSurveyAnswers`, auth);
};
