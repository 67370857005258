import { DefaultButton } from '@fluentui/react';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { usePermission, VendorSurveyContext } from '../../../helpers/hooks';
import { getSurveyInfosForFrame } from '../../../services/survey';
import { AnswersOfSurveyModal } from '../answersOfSurvey';

export const SurveyListTable: React.FC = () => {
    const [frameInfo, setFrameInfo] = React.useState<any>(null);
    const [isViewModalOpen, setIsViewModalOpen] = React.useState(false);
    const [sections, setSections] = React.useState(null);
    const { showFrameSurveyPage, showVendorSurveyPage } = usePermission();
    const [completionDate, setCompletionDate] = React.useState<string | null>(null);
    const vendorValue = React.useContext(VendorSurveyContext);
    const navigate = useNavigate();

    React.useEffect(() => {
        if (showFrameSurveyPage) {
            getSurveyInfosForFrame().then((data) => {
                setFrameInfo(data.data);
            });
        }
    }, [showFrameSurveyPage]);

    return (
        <>
            <div className="container mx-auto border mt-10 mb-10">
                <Table>
                    <TableHead>
                        <TableRow>
                            {showFrameSurveyPage && <TableCell>Vendor Name</TableCell>}
                            <TableCell>Survey Submission Date</TableCell>
                            <TableCell>Submitted By</TableCell>
                            <TableCell>Last Modified Date</TableCell>
                            <TableCell>Last Modified By</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {showFrameSurveyPage &&
                            frameInfo &&
                            frameInfo.map((info: any) => (
                                <TableRow key={info.vendorId}>
                                    <TableCell>{info.vendorDescription}</TableCell>
                                    <TableCell>{info.createdOn}</TableCell>
                                    <TableCell>{info.createdBy}</TableCell>
                                    <TableCell>{info.modifiedOn}</TableCell>
                                    <TableCell>{info.modifiedBy}</TableCell>
                                    <TableCell>
                                        <DefaultButton
                                            text="View"
                                            allowDisabledFocus
                                            type="submit"
                                            onClick={() => {
                                                setIsViewModalOpen(true);
                                                setSections(info.sections);
                                                setCompletionDate(info.createdOn);
                                            }}
                                            disabled={!info.sections}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}

                        {showVendorSurveyPage && vendorValue && (
                            <TableRow key={vendorValue.vendorId}>
                                <TableCell>{vendorValue.createdOn}</TableCell>
                                <TableCell>{vendorValue.createdBy}</TableCell>
                                <TableCell>{vendorValue.modifiedOn}</TableCell>
                                <TableCell>{vendorValue.modifiedBy}</TableCell>
                                <TableCell>
                                    <DefaultButton
                                        text="Edit"
                                        allowDisabledFocus
                                        type="submit"
                                        onClick={() => navigate('/survey')}
                                    />
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </div>
            {isViewModalOpen && (
                <AnswersOfSurveyModal
                    isModalOpen={isViewModalOpen}
                    setModalState={setIsViewModalOpen}
                    answers={sections}
                    completionDate={completionDate}
                />
            )}
        </>
    );
};
