import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { BaseUrl } from '../../consts';
import { getAuthHead } from '../../helpers/authHelper';
import { IComment, ICommentRes } from '../models.ts/comment';

export const fetchComments = createAsyncThunk('comments/getComments', async (id: string) => {
    const auth = await getAuthHead();
    const response = await axios.get(`${BaseUrl}purchaseOrders/getMessages/${id}`, auth);
    return response.data as IComment[];
});

export const postComment = createAsyncThunk('comments/postComment', async (cmnt: ICommentRes) => {
    const auth = await getAuthHead();
    const response = await axios.post(`${BaseUrl}purchaseOrders/insertMessage`, cmnt, auth);
    return response.data as IComment[];
});
