import { useMsal } from '@azure/msal-react';
import { ActionButton } from '@fluentui/react';
import React from 'react';
import { Container } from 'react-grid-system';
import { Link, NavLink } from 'react-router-dom';

import { ROUTES } from '../consts';
import { usePermission, VendorSurveyContext } from '../helpers/hooks';
import { signOutClickHandler } from '../helpers/utils';
import logo from '../images/logo.svg';

export const Navbar: React.FC = () => {
    const { accounts, instance } = useMsal();
    const vendorValue = React.useContext(VendorSurveyContext);
    const {
        showProdFollowUpPage,
        showVendorSurveyPage,
        showFrameSurveyPage,
        // showShippingAndTrackingPage,
    } = usePermission();

    return (
        <header className="sticky top-0 bg-white z-index-full">
            <Container
                style={{ maxWidth: '97.5%' }}
                className="header pt-6 pb-6 flex justify-between items-center"
            >
                <div className="logo-menu-wrapper flex">
                    <div className="flex items-center">
                        <Link to="/">
                            <img src={logo} alt="" />
                        </Link>
                    </div>
                    <div className="menus pl-20">
                        <NavLink to={ROUTES.DASHBOARD}>Order Dashboard</NavLink>
                        {showProdFollowUpPage && (
                            <NavLink to={ROUTES.PRODUCTION_FOLLOW_UP}>Production follow up</NavLink>
                        )}
                        {/* {showShippingAndTrackingPage && ( */}
                        <NavLink to={ROUTES.SHIPPING_AND_TRACKING}>Shipping & Tracking</NavLink>
                        {/* )} */}
                        <NavLink to={ROUTES.PACKING_LIST}>Packing List</NavLink>
                        {showFrameSurveyPage && (
                            <NavLink to={ROUTES.SURVEY_LIST}>Vendor ESG compliance form</NavLink>
                        )}
                        {showVendorSurveyPage && (
                            <NavLink
                                to={
                                    vendorValue === null || vendorValue?.length === 0
                                        ? ROUTES.SURVEY
                                        : ROUTES.SURVEY_LIST
                                }
                            >
                                Vendor ESG compliance form
                            </NavLink>
                        )}
                    </div>
                </div>

                <div className="utils flex justify-end items-center">
                    <div className="flex items-center">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5 mr-2"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={2}
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                            />
                        </svg>
                        <NavLink to="/">Notifications</NavLink>
                    </div>
                    <div className="flex items-center">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5 mr-2"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={2}
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                            />
                        </svg>
                        <NavLink to="/" className="pr-0 mr-5">
                            {accounts[0]?.name}
                        </NavLink>
                        <ActionButton
                            className="underline p-0 m-0"
                            onClick={() =>
                                signOutClickHandler(instance, accounts[0]?.homeAccountId)
                            }
                        >
                            Logout
                        </ActionButton>
                    </div>
                </div>
            </Container>
            <hr />
        </header>
    );
};
