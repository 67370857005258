import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PackingList from '../components/packing-list';
import { PackingListProvider } from '../components/packing-list/context';
import { EProcessStatus } from '../helpers/utils/redux';
import { fetchSuppliers } from '../store/asyncActions/supplier';
import { supplierSelector } from '../store/reducers/supplierSlice';

const PackingListPage = () => {
    const suppliers = useSelector(supplierSelector);
    const disptach = useDispatch();

    useEffect(() => {
        if (suppliers.status === EProcessStatus.IDLE) {
            disptach(fetchSuppliers());
        }
    }, []);

    return (
        <PackingListProvider>
            <PackingList />
        </PackingListProvider>
    );
};

export default PackingListPage;
