import React, { useState } from 'react';

import { Panel } from '../../../common/panel';
import { FILE_TYPES } from '../../../consts';
import { cn } from '../../../utilities';
import { Files } from './files';

interface IFileSystemProps {
    setFileData: React.Dispatch<
        React.SetStateAction<{
            orderId: string;
            fileName: string;
            fileNameToBeDisplayed: string;
            fileType: string;
        }>
    >;
    setIsConfirmModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const FileSystem = ({ setFileData, setIsConfirmModalOpen }: IFileSystemProps) => {
    const [activeFileTab, setActiveFileTab] = useState(FILE_TYPES.FrameTechnicalSheets);

    return (
        <>
            <Panel className="mt-10 border-b-0 flex items-center py-0 px-0">
                <div
                    className={cn(
                        'flex items-center font-bold p-5 hover:bg-black/20 cursor-pointer border-b-2 border-transparent',
                        activeFileTab === FILE_TYPES.FrameTechnicalSheets && 'border-black',
                    )}
                    onClick={() => setActiveFileTab(FILE_TYPES.FrameTechnicalSheets)}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 mr-3"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                    >
                        <path
                            fillRule="evenodd"
                            d="M2 5a2 2 0 012-2h8a2 2 0 012 2v10a2 2 0 002 2H4a2 2 0 01-2-2V5zm3 1h6v4H5V6zm6 6H5v2h6v-2z"
                            clipRule="evenodd"
                        />
                        <path d="M15 7h1a2 2 0 012 2v5.5a1.5 1.5 0 01-3 0V7z" />
                    </svg>
                    <p>Technical sheet</p>
                </div>
                <div
                    className={cn(
                        'flex items-center font-bold p-5 hover:bg-black/20 cursor-pointer border-b-2 border-transparent',
                        activeFileTab === FILE_TYPES.GarmentTestingResults && 'border-black',
                    )}
                    onClick={() => setActiveFileTab(FILE_TYPES.GarmentTestingResults)}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 mr-3"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                    >
                        <path
                            fillRule="evenodd"
                            d="M2 5a2 2 0 012-2h8a2 2 0 012 2v10a2 2 0 002 2H4a2 2 0 01-2-2V5zm3 1h6v4H5V6zm6 6H5v2h6v-2z"
                            clipRule="evenodd"
                        />
                        <path d="M15 7h1a2 2 0 012 2v5.5a1.5 1.5 0 01-3 0V7z" />
                    </svg>
                    <p>Garment testing results</p>
                </div>
            </Panel>

            <Files
                setFileData={setFileData}
                setIsConfirmModalOpen={setIsConfirmModalOpen}
                fileType={activeFileTab}
            />
        </>
    );
};

export default FileSystem;
