import { IconButton, Modal, Spinner, SpinnerSize } from '@fluentui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { iconButtonStyles, modalContentStyles } from '../../../consts';
import { notifyRequestErr } from '../../../helpers/utils';
import { EProcessStatus } from '../../../helpers/utils/redux';
import { ReactComponent as LayersIcon } from '../../../images/icons/layers.svg';
import { packingListService } from '../../../services/packingList';
import { supplierSelector } from '../../../store/reducers/supplierSlice';
import { usePackingListContext } from '../context';

const validationSchema = yup.object({
    vendorId: yup.string().required('Vendor is required'),
});

const CreatePackingListModal = () => {
    const { isCreateModalOpen, closeCreateModal, setPackingLists } = usePackingListContext();
    const { data: suppliersData, status: suppliersStatus } = useSelector(supplierSelector);
    const [loading, setLoading] = useState(false);

    const form = useForm<yup.InferType<typeof validationSchema>>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            vendorId: '',
        },
    });

    const onSubmit = (values: yup.InferType<typeof validationSchema>) => {
        setLoading(true);
        packingListService
            .createPackingList(values)
            .then((data) => {
                setPackingLists((prev) => [data, ...prev]);
                toast.success('Packing list created successfully');
                closeCreateModal();
            })
            .catch((err) => notifyRequestErr(err))
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Modal
            titleAriaId={'dd'}
            isOpen={isCreateModalOpen}
            onDismiss={() => closeCreateModal()}
            isBlocking={false}
            containerClassName={
                modalContentStyles.container +
                ' w-[40%] max-w-[600px] min-w-[400px] h-fit min-h-[360px] overflow-visible'
            }
            className="edit-pre-modal"
        >
            <div className={modalContentStyles.header}>
                <b id="dsd" className="text-black bold head flex items-center gap-x-4 ">
                    <LayersIcon />
                    Add New Packing List
                </b>
                <IconButton
                    styles={iconButtonStyles}
                    iconProps={{ iconName: 'Cancel' }}
                    ariaLabel="Close popup modal"
                    onClick={() => closeCreateModal()}
                />
            </div>
            <form onSubmit={form.handleSubmit(onSubmit)} className="mt-6">
                <div className="px-6 ">
                    <label htmlFor="packingListVendorSelect" className="text-sm font-medium">
                        Select the vendor
                    </label>
                    <Select
                        id="packingListVendorSelect"
                        isClearable
                        isSearchable
                        className="basic-single mt-1"
                        classNamePrefix="select"
                        isLoading={suppliersStatus === EProcessStatus.PENDING}
                        name="color"
                        options={suppliersData.map((supplier) => ({
                            value: supplier.vendorId,
                            label: supplier.vendorDs,
                        }))}
                        styles={{
                            menuList(base) {
                                return { ...base, maxHeight: '140px', overflow: 'auto' };
                            },
                        }}
                        onChange={(selectedOption) => {
                            form.setValue('vendorId', selectedOption?.value ?? '');
                        }}
                    />
                    {form.formState.errors.vendorId && !form.formState.touchedFields.vendorId && (
                        <p className="mt-1 text-rose-700">
                            {form.formState.errors.vendorId.message}
                        </p>
                    )}
                </div>
                <div
                    className={
                        modalContentStyles.footer +
                        ' border-t border-gray-200 flex items-end justify-end gap-x-4 mt-20'
                    }
                >
                    <button
                        disabled={loading}
                        type="button"
                        onClick={closeCreateModal}
                        className="bg-white px-4 py-2.5 flex gap-x-2 border border-gray-300 hover:bg-gray-100/70 transition disabled:opacity-50"
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        disabled={loading}
                        className="bg-black text-white px-4 py-2.5 flex gap-x-2 hover:opacity-80 disabled:opacity-50 transition"
                    >
                        {loading && <Spinner size={SpinnerSize.medium} className="mr-1" />}
                        Save New Packing List
                    </button>
                </div>
            </form>
        </Modal>
    );
};

export default CreatePackingListModal;
