import * as React from 'react';

import { useExpose } from '../../../helpers/hooks';
import { IPackingList, IPaginationInfo } from '../../../store/models.ts/common';
import CreatePackingListModal from '../createModal';
import { PACKING_LIST_INITIAL_PAGINATION_INFO } from '../../../consts';

type Tcontext = {
    packingLists: IPackingList[];
    isListLoading: boolean;
    isCreateModalOpen: boolean;
    openCreateModal: () => void;
    closeCreateModal: () => void;
    paginationInfo: IPaginationInfo;
    setPaginationInfo: React.Dispatch<React.SetStateAction<IPaginationInfo>>;
    setListLoading: React.Dispatch<React.SetStateAction<boolean>>;
    setPackingLists: React.Dispatch<React.SetStateAction<IPackingList[]>>;
};

const PackingListContext = React.createContext<Tcontext>(null as any);

function PackingListProvider({ children }) {
    const [isListLoading, setListLoading] = React.useState(false);
    const [packingLists, setPackingLists] = React.useState<IPackingList[]>([]);
    const [paginationInfo, setPaginationInfo] = React.useState(
        PACKING_LIST_INITIAL_PAGINATION_INFO,
    );
    const {
        close: closeCreateModal,
        open: openCreateModal,
        isOpen: isCreateModalOpen,
    } = useExpose();

    const value = {
        packingLists,
        isListLoading,
        setListLoading,
        paginationInfo,
        openCreateModal,
        setPackingLists,
        closeCreateModal,
        isCreateModalOpen,
        setPaginationInfo,
    };

    return (
        <PackingListContext.Provider value={value}>
            {children}
            <CreatePackingListModal />
        </PackingListContext.Provider>
    );
}

function usePackingListContext() {
    return React.useContext(PackingListContext);
}

export { PackingListProvider, usePackingListContext };
