import { AppBar, IconButton, Toolbar, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import React from 'react';

import { Panel } from '../../common/panel';

interface IProps {
    isModalOpen: boolean;
    setModalState: React.Dispatch<React.SetStateAction<boolean>>;
    answers: any;
    completionDate: string | null;
}

export const AnswersOfSurveyModal: React.FC<IProps> = ({
    isModalOpen,
    setModalState,
    answers,
    completionDate,
}) => {
    const Transition = React.forwardRef(function Transition(
        props: TransitionProps & {
            children: React.ReactElement;
        },
        ref: React.Ref<unknown>,
    ) {
        return <Slide direction="up" ref={ref} {...props} />;
    });
    return (
        <>
            <Dialog
                fullScreen
                open={isModalOpen}
                onClose={() => setModalState(false)}
                TransitionComponent={Transition}
                className="z-[9999999]"
            >
                <AppBar sx={{ position: 'relative', background: 'black' }}>
                    <Toolbar>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Survey Completion Date: <b>{completionDate}</b>
                        </Typography>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={() => setModalState(false)}
                            aria-label="close"
                        >
                            <span>X</span>
                        </IconButton>
                    </Toolbar>
                </AppBar>
                {answers && (
                    <Panel className="container mx-auto border-none">
                        {answers.map((answer: any) => (
                            <div key={answer.vendorId} className="my-5">
                                <h2 className="mb-2" key={answer.sectionId}>
                                    {answer.sectionDescription}:
                                </h2>
                                {answer.questions.map((question: any, i: number) => (
                                    <div
                                        key={question.questionId}
                                        className="border-b border-l border-r ml-10 px-10 py-5"
                                    >
                                        <h2 className="mt-10 mb-5 leading-7">
                                            {i + 1}. {question.questionContent}
                                        </h2>
                                        {question.questionType !== 'upload' &&
                                            question.questionType === 'checkbox' && (
                                                <b className="text-2xl ml-10">
                                                    {question.answerToBeDisplayed === null
                                                        ? ''
                                                        : question.answerToBeDisplayed}
                                                </b>
                                            )}
                                        {question.questionType !== 'upload' &&
                                            question.questionType !== 'checkbox' && (
                                                <b className="text-2xl ml-10">{question.answer}</b>
                                            )}
                                        {question.questionType === 'upload' && (
                                            <>
                                                {question.answer &&
                                                    question.answer
                                                        .split(',')
                                                        .map((url: any, i: number) => (
                                                            <div
                                                                className="flex items-center"
                                                                key={i}
                                                            >
                                                                {(question.answerToBeDisplayed !==
                                                                    '' ||
                                                                    question.answerToBeDisplayed !==
                                                                        null) &&
                                                                    question.answerToBeDisplayed.split(
                                                                        ',',
                                                                    ).length && (
                                                                        <p className="text-2xl">
                                                                            {i + 1}.{' '}
                                                                        </p>
                                                                    )}
                                                                <a
                                                                    href={url}
                                                                    className="underline block text-blue-600 ml-2 text-2xl mt-3"
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                >
                                                                    {url}
                                                                </a>
                                                            </div>
                                                        ))}
                                            </>
                                        )}
                                    </div>
                                ))}
                            </div>
                        ))}
                    </Panel>
                )}
            </Dialog>
        </>
    );
};
