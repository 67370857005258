import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DEFAULT_PRODUCTION_FOLLOW_FILTERS } from '../../consts';
import { EProcessStatus } from '../../helpers/utils/redux';
import { fetchPublishViewOrders } from '../asyncActions/publishViewOrders';
import {
    IPublishViewOrder,
    IPublishViewOrderFilters,
    IPublishViewOrderRes,
} from '../models.ts/publishViewOrder';
import { RootState } from '../store';

export interface IInitialState {
    data: IPublishViewOrderRes;
    status: EProcessStatus;
    filter: IPublishViewOrderFilters;
}
const initialState: IInitialState = {
    data: {
        elements: [],
        totalResults: 0,
    },
    status: EProcessStatus.IDLE,
    filter: { ...DEFAULT_PRODUCTION_FOLLOW_FILTERS },
};

export const publishViewOrderSlice = createSlice({
    name: 'publishViewOrders',
    initialState: initialState,
    reducers: {
        setFilterFields: (state, action: PayloadAction<any>) => {
            state.filter = action.payload;
        },
        setLoadMore: (state, action: PayloadAction<any>) => {
            state.data = action.payload;
        },
        setPageSize: (state, action: PayloadAction<any>) => {
            state.filter.pageSize = action.payload;
        },
        setPublishStatus: (state, action: PayloadAction<IPublishViewOrder[]>) => {
            action.payload.forEach((pyl) => {
                const idxEl = state.data.elements.findIndex(
                    (el) => el.document.poDocId === pyl.poDocId,
                );
                state.data.elements[idxEl].document = pyl;
            });
        },
        setAcknowledgeStatus: (state, action: PayloadAction<IPublishViewOrder[]>) => {
            action.payload.forEach((pyl) => {
                const idxEl = state.data.elements.findIndex(
                    (el) => el.document.poDocId === pyl.poDocId,
                );
                state.data.elements[idxEl].document = pyl;
            });
        },
    },
    extraReducers: {
        [fetchPublishViewOrders.pending as any]: (state: IInitialState) => {
            state.status = EProcessStatus.PENDING;
        },
        [fetchPublishViewOrders.fulfilled as any]: (state: IInitialState, action: any) => {
            state.status = EProcessStatus.SUCCESS;
            state.data = action.payload;
        },
        [fetchPublishViewOrders.rejected as any]: (state: IInitialState) => {
            state.status = EProcessStatus.ERROR;
        },
    },
});

export const publishViewOrderSelector = (state: RootState) => state.publishViewOrders;

export default publishViewOrderSlice.reducer;
