export enum EProductionStatus {
    COMPLETED = 'Completed',
    NOTSTARTED = 'Not Started',
}

export enum EProductionFollowOrderBy {
    EARLIEST_DELIVERY_DATE = 'Earliest delivery date',
    LATEST_DELIVERY_DATE = 'Latest delivery date',
    ORDER_ID = 'Order ID',
    ITEM = 'Item',
}

export enum EProductionFollowOrderByRaw {
    EXPECTED_DELIVERY_DATE = 'expectedDeliveryDate',
    ORDER_ID = 'orderId',
    ITEM = 'item',
}

export enum EEnvironmentVars {
    DEV = 'development',
    PROD = 'production',
    LOCAL = 'local',
    STAGE = 'stage',
}

export enum EInputTypes {
    FREETEXT = 'freetext',
    FREETEXT2 = 'freetext2',
    CHECKBOX = 'checkbox',
    UPLOAD = 'upload',
    ADDUPTO100 = 'addupto100',
    UPLOAD2 = 'upload2',
}

export enum EQuestionIds {
    energyTextSelect = 1,
    energyText = 2,
    energyFileSelect = 3,
    energyFile = 4,
    waterTextSelect = 5,
    waterText = 6,
    waterFileSelect = 7,
    waterFile = 8,
    recyclingTextSelect = 9,
    recyclingText = 10,
    recyclingFileSelect = 11,
    recyclingFile = 12,
    toxicTextSelect = 13,
    toxicText = 14,
    toxicFileSelect = 15,
    toxicFile = 16,
    firstQuestionSelect = 17,
    secondQuestionSelect = 18,
    thirdQuestionSelect = 19,
    firstGenderQuestionSelect = 20,
    ratioValue = 21,
    charityFirstTextSelect = 22,
    charityFirstText = 23,
    charityFile = 24,
    charitySecondTextSelect = 25,
    charitySecondText = 26,
}

export enum EPackingListStatus {
    DRAFT = 1,
    CONFIRMED = 2,
    CANCELLED = 3,
}
