import moment from 'moment';
import React from 'react';

import { ReactComponent as LayersIcon } from '../../images/icons/layers.svg';

interface IHeadingProps {
    updatedAt: Date;
}

const Heading = ({ updatedAt }: IHeadingProps) => {
    return (
        <div className="max-w-80 flex items-center gap-x-5 z-[9999]">
            <LayersIcon />
            <div className="flex flex-col gap-y-2 ">
                <h1 className="text-gray-900 text-2xl font-extrabold">Packing Lists</h1>
                <p className="text-gray-500 text-sm">
                    Updated on {moment(updatedAt).format('DD MMM YYYY, HH:mm')}
                </p>
            </div>
        </div>
    );
};

export default Heading;
