import { InfoIcon } from '@fluentui/react-icons-mdl2';
import { IconButton, Tooltip, Zoom } from '@mui/material';

const TooltipIcon = ({ title }: { title: string }) => {
    return (
        <Tooltip TransitionComponent={Zoom} title={<p className="text-center">{title}</p>}>
            <IconButton size="small">
                <InfoIcon />
            </IconButton>
        </Tooltip>
    );
};

export default TooltipIcon;
