import { UseQueryResult } from '@tanstack/react-query';
import * as React from 'react';

import { IError } from '../store/models.ts/common';
import { ErrorPanel } from './errorPanel';

interface IProps<T> {
    branch: UseQueryResult<T>;
    renderError?: (error: IError) => JSX.Element;
    renderLoading?: JSX.Element;
    renderSuccess: (data: T) => JSX.Element;
    renderEmpty?: JSX.Element;
}

export const asyncRenderError = (error: IError): JSX.Element => <ErrorPanel error={error} />;

export function Async<T>({
    branch,
    renderError,
    renderLoading,
    renderEmpty,
    renderSuccess,
}: IProps<T>) {
    const { status, data, error } = branch;

    if (status === 'error') {
        return (
            renderError?.({
                message: (error as any).message ?? 'An unexpected error happened.',
            }) ?? null
        );
    }

    if (status === 'loading') {
        return renderLoading ?? null;
    }

    if (data === undefined || (status === 'success' && Array.isArray(data) && data.length === 0)) {
        return renderEmpty ?? null;
    }

    return renderSuccess(data);
}
