import { Spinner, SpinnerSize, TextField } from '@fluentui/react';
import { CancelIcon, CheckMarkIcon } from '@fluentui/react-icons-mdl2';
import { Menu } from '@headlessui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { EPackingListStatus } from '../../../enums';
import { notifyRequestErr } from '../../../helpers/utils';
import { ReactComponent as PackageIcon } from '../../../images/icons/package.svg';
import { ReactComponent as PlusCircleIcon } from '../../../images/icons/plus-cricle.svg';
import { packingListService } from '../../../services/packingList';
import { usePackingListDetailContext } from '../context';

const PackingListPackage = () => {
    const { packageData, openPackageModal, onPackageEdit, packingList, purchaseOrders } =
        usePackingListDetailContext();
    const packages = packageData?.packages || [];
    const hasAnyPackageMultiPO = packages.some((p) => p.packageContents.length > 1);

    const isCompleted =
        packingList?.statusId === EPackingListStatus.CANCELLED ||
        packingList?.statusId === EPackingListStatus.CONFIRMED;

    const disableAddPackage = isCompleted || purchaseOrders.length === 0;

    const poColumnNames = useMemo(
        () =>
            hasAnyPackageMultiPO
                ? ['poIdToBeDisplayed', 'style', 'color', 'size', 'packedQty']
                : ['poIdToBeDisplayed', 'style', 'color', 'size'],
        [hasAnyPackageMultiPO],
    );

    const { packagesCount = 0, totalPackedQty = 0 } = packageData || {};

    return (
        <div>
            <div className="flex justify-between items-center p-4 w-full rounded-t-lg border border-gray-200 ">
                <div className="flex items-center gap-x-4">
                    <PackageIcon />
                    <div>
                        <h1 className="text-gray-700 text-xl leading-8 font-bold">Cartons</h1>
                        <p className="text-sm text-[rgba(52,64,84,0.80)]">
                            {`Total Units/Cartons: ${totalPackedQty}/${packagesCount}`}
                        </p>
                    </div>
                </div>
                <button
                    onClick={openPackageModal}
                    disabled={disableAddPackage}
                    className="flex gap-x-2 text-sm font-medium px-3.5 py-2 border border-gray-300 rounded-lg hover:bg-gray-100 transition disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:bg-transparent"
                    style={{
                        boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                    }}
                >
                    <PlusCircleIcon />
                    Add Carton
                </button>
            </div>
            <div className="text-sm p-4 w-full rounded-b-lg border border-gray-200 border-t-0">
                <Table
                    className="border-x rounded-lg"
                    style={{
                        boxShadow:
                            '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
                    }}
                >
                    <TableHead className="bg-bg-gray py-10 sticky top-[88px] z-20">
                        <TableRow>
                            <TableCell className="border-none">Carton ID</TableCell>
                            <TableCell className="border-none px-0 text-center">PO</TableCell>
                            <TableCell className="border-none px-0 text-center">Item</TableCell>
                            <TableCell className="border-none px-0 text-center">Color</TableCell>
                            <TableCell className="border-none px-0 text-center">Size</TableCell>
                            {hasAnyPackageMultiPO && (
                                <TableCell className="border-none px-0 text-center">
                                    Detailed content
                                </TableCell>
                            )}
                            <TableCell className="border-none px-0 text-center">
                                Total content
                            </TableCell>
                            <TableCell className="border-none w-5"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {packages.length === 0 && (
                            <TableRow className="group">
                                <TableCell
                                    className="border-none py-20 text-center text-[28px] text-gray-500"
                                    colSpan={9}
                                >
                                    <p className="text-[28px]">No Carton yet</p>
                                    <p className="text-[18px]">
                                        You will be able to add a carton after your add a PO Item.
                                    </p>
                                </TableCell>
                            </TableRow>
                        )}
                        {packages.map((p) => (
                            <TableRow key={p.packageId} className="group">
                                <TableCell className="group-last:border-none font-bold w-28">{`Pkg ${p.packageId}`}</TableCell>
                                {poColumnNames.map((key) => (
                                    <TableCell
                                        key={key}
                                        className="text-center group-last:border-none text-gray-500 px-0"
                                    >
                                        {p.packageContents.map((c) => (
                                            <p
                                                key={`${c.poDocId}-${c.size}`}
                                                className="border-b border-solid border-gray-200 py-3  first:pt-0 last:pb-0 last:border-none"
                                            >
                                                {c[key]}
                                            </p>
                                        ))}
                                    </TableCell>
                                ))}
                                <TableCell className="text-center group-last:border-none max-w-40">
                                    {p.totalPackedQty}
                                </TableCell>
                                <TableCell className=" group-last:border-none text-gray-500 px-0">
                                    {!isCompleted && (
                                        <div className="flex gap-x-1 px-6">
                                            <DuplicateAction packageId={p.packageId} />
                                            <button
                                                onClick={() => onPackageEdit(p)}
                                                className="flex justify-center items-center px-5 py-2.5 gap-x-2 text-gray-500 text-sm hover:bg-gray-100 disabled:hover:bg-transparent disabled:opacity-60 rounded-md transition"
                                            >
                                                Edit
                                            </button>
                                        </div>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        </div>
    );
};

const validationSchema = yup.object({
    count: yup.number().required().min(1),
});

const DuplicateAction = ({ packageId }: { packageId: number }) => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const { setPackageData, packageData } = usePackingListDetailContext();
    const form = useForm<yup.InferType<typeof validationSchema>>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            count: 1,
        },
    });

    const onSubmit = useCallback(
        ({ count }: { count: number }) => {
            setLoading(true);
            setOpen(false);

            packingListService
                .duplicatePackage({
                    count,
                    packageId,
                })
                .then((data) => {
                    setPackageData(data);
                    toast.success('Carton duplicated successfully');
                })
                .catch((err) => notifyRequestErr(err))
                .finally(() => {
                    setLoading(false);
                });
        },
        [packageId /*setPackages, packages*/],
    );

    return (
        <Menu as="div" className="relative inline-block text-left">
            {() => (
                <>
                    <div>
                        <Menu.Button
                            onClick={() => setOpen(!open)}
                            disabled={loading}
                            className="w-[110px] flex justify-center items-center p-2.5 gap-x-2 text-gray-500 text-sm hover:bg-gray-100 disabled:hover:bg-transparent disabled:opacity-60 rounded-md transition"
                        >
                            {loading && <Spinner size={SpinnerSize.small} />}
                            Duplicate
                        </Menu.Button>
                    </div>
                    {open && (
                        <Menu.Items
                            static
                            className="absolute right-0 mt-2 w-44  origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none z-[40]"
                        >
                            <div className="px-1 py-1" onClick={(e) => e.stopPropagation()}>
                                <Menu.Item>
                                    {() => (
                                        <form onSubmit={form.handleSubmit(onSubmit)}>
                                            <div className="flex items-center gap-x-1">
                                                <div className="w-full">
                                                    <Controller
                                                        control={form.control}
                                                        name="count"
                                                        render={({
                                                            field: { value, onChange },
                                                        }) => (
                                                            <div className="relative">
                                                                <TextField
                                                                    value={value.toString()}
                                                                    type="number"
                                                                    onChange={onChange}
                                                                    placeholder="Count"
                                                                    className="w-full"
                                                                    styles={{
                                                                        fieldGroup: {
                                                                            borderColor: form
                                                                                .formState.errors
                                                                                .count
                                                                                ? '#f43f5e'
                                                                                : '#D0D5DD',
                                                                            outline: 'none',
                                                                            height: 30,
                                                                        },
                                                                    }}
                                                                />
                                                            </div>
                                                        )}
                                                    />
                                                </div>
                                                <button
                                                    className={`hover:bg-green-500 hover:text-white transition group flex px-3 items-center rounded-md py-1 text-sm`}
                                                >
                                                    <CheckMarkIcon className="text-xl" />
                                                </button>
                                                <button
                                                    type="button"
                                                    onClick={() => setOpen(false)}
                                                    className={`hover:bg-black hover:text-white transition group flex px-2 items-center rounded-md py-1 text-sm`}
                                                >
                                                    <CancelIcon className="text-lg" />
                                                </button>
                                            </div>
                                        </form>
                                    )}
                                </Menu.Item>
                            </div>
                        </Menu.Items>
                    )}
                </>
            )}
        </Menu>
    );
};

export default PackingListPackage;
