import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { EProcessStatus } from '../../helpers/utils/redux';
import { fetchSheets } from '../asyncActions/techSheet';
import { ITechSheetRes } from '../models.ts/techSheet';
import { RootState } from '../store';

export interface IInitialState {
    data: ITechSheetRes[];
    status: EProcessStatus;
}
const initialState: IInitialState = {
    data: [
        {
            uri: '',
            fileName: '',
            fileNameToBeDisplayed: '',
            createdBy: '',
            createdOn: '',
        },
    ],
    status: EProcessStatus.IDLE,
};

export const techSheetSlice = createSlice({
    name: 'files',
    initialState: initialState,
    reducers: {
        deleteFile: (state, action: PayloadAction<any>) => {
            const b = state.data.filter((item) => item.fileName !== action.payload.fileName);
            state.data = b;
        },
        uploadFile: (state, action: PayloadAction<any>) => {
            action.payload.map((act: any) => {
                state.data.unshift(act);
            });
        },
    },

    extraReducers: {
        [fetchSheets.pending as any]: (state: IInitialState) => {
            state.status = EProcessStatus.PENDING;
        },
        [fetchSheets.fulfilled as any]: (state: IInitialState, action: any) => {
            state.status = EProcessStatus.SUCCESS;
            state.data = action.payload;
        },
        [fetchSheets.rejected as any]: (state: IInitialState) => {
            state.status = EProcessStatus.ERROR;
        },
    },
});

export const techSheetSelector = (state: RootState) => state.techSheet;

export default techSheetSlice.reducer;
