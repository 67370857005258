import { DatePicker } from '@fluentui/react';
import { TableCell } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import moment from 'moment';
import React, { useMemo } from 'react';
import { toast } from 'react-toastify';

import { QUERY_KEYS } from '../../../../consts';
import { LoaderContext } from '../../../../routes/router';
import { orderService } from '../../../../services/orderService';
import { IOrderElems, IOrderStages } from '../../../../store/models.ts/common';
import { useOrderContext } from '../../context/orderContext';

interface IProps {
    stage: IOrderStages;
    order: IOrderElems;
}
export const StageDates: React.FC<IProps> = ({ order, stage }) => {
    const ctxt = React.useContext(LoaderContext);
    const { setCheckedRows } = useOrderContext();
    const queryClient = useQueryClient();
    const plannedCompletion = useMemo(
        () =>
            order.document.orderPlannedCompletionDateOfProductionStages.find(
                (s) => s.productionStageId === stage.productionStageId,
            ) || null,
        [order, stage],
    );

    const [dateVal, setDateVal] = React.useState<Date | undefined>(
        plannedCompletion &&
            (plannedCompletion.plannedCompletionDate ||
                plannedCompletion.plannedCompletionDate !== null)
            ? getDateWithoutTimeZoneOffset(plannedCompletion.plannedCompletionDate)
            : undefined,
    );

    React.useEffect(() => {
        setDateVal(
            plannedCompletion && plannedCompletion.plannedCompletionDate
                ? getDateWithoutTimeZoneOffset(plannedCompletion.plannedCompletionDate)
                : undefined,
        );
    }, []);
    const { mutate: updateDateTime } = useMutation(orderService.updateplannedCompletionTime, {
        onSuccess: () => {
            setCheckedRows([]);
            ctxt?.setLoaderIsActive(false);
            toast.success('Date succesfully changed !', {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
            const previousOrder: any = queryClient.getQueryData(
                [QUERY_KEYS.PRODUCTION_FOLLOW_UP_ORDER],
                {
                    exact: false,
                },
            );
            const index = previousOrder?.elements.findIndex(
                (el) => el.document.poDocId === order.document.poDocId,
            );
            const b = previousOrder.elements[
                index
            ].document.orderPlannedCompletionDateOfProductionStages.find(
                (el) => el.productionStageId === stage.productionStageId,
            );

            b.plannedCompletionDate = moment(dateVal).format('MM-DD-YYYY');
            queryClient.invalidateQueries([QUERY_KEYS.PRODUCTION_FOLLOW_UP_ORDER]);
        },
    });
    const handleUpdateDateChange = (date: Date) => {
        ctxt?.setLoaderIsActive(true);
        updateDateTime({
            poDocId: order.document.poDocId,
            plannedCompletionDate: moment(date).format('YYYY-MM-DD'),
            productionStageId: stage.productionStageId,
        });
    };
    return (
        <TableCell>
            <DatePicker
                onSelectDate={(date) => {
                    setDateVal(date as any);
                    handleUpdateDateChange(date as Date);
                }}
                value={dateVal}
                placeholder="Select a date"
                formatDate={(date) => moment(date).format('MM-DD-YYYY')}
            />
        </TableCell>
    );
};

function getDateWithoutTimeZoneOffset(date: string) {
    return new Date(new Date(date).getTime() + new Date(date).getTimezoneOffset() * 60000);
}
