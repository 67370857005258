import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { BaseUrl } from '../../consts';
import { getAuthHead } from '../../helpers/authHelper';
import { ISupplier } from '../models.ts/common';
import { IDashboardFilter } from '../models.ts/dashboard';

//http://localhost:8061/v1/ => replace baseUrl for mock data

export const fetchDashboardInfo = createAsyncThunk(
    'dashboardActions/getAllInfo',
    async (filter: IDashboardFilter) => {
        const auth = await getAuthHead();
        const response = await axios.post(
            `${BaseUrl}purchaseorders/getDashboardPurchaseOrders`,
            filter,
            auth,
        );
        return response.data as ISupplier[];
    },
);
