import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { BaseUrl } from '../../consts';
import { getAuthHead } from '../../helpers/authHelper';
import { IPageable } from '../models.ts/common';
import { IOrder, IProductionFollowOrderFilters } from '../models.ts/ProductionFollowUp';

//http://localhost:8061/v1/ => replace baseUrl for mock data

export const fetchOrders = createAsyncThunk(
    'Orders/getAll',
    async ({
        filter = {},
        isMaster,
    }: {
        filter?: IProductionFollowOrderFilters;
        isMaster: boolean;
    }) => {
        const auth = await getAuthHead();
        const response = await axios.post(
            `${BaseUrl}purchaseorders/getPurchaseOrders`,
            { ...filter, isMaster },
            auth,
        );
        return response.data as IPageable<IOrder>;
    },
);
