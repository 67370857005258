import { Dropdown, IDropdownOption, IDropdownStyles } from '@fluentui/react';
import React from 'react';

interface IProps {
    backGroundColor?: string;
    borderRadius?: number;
    border?: string;
    width?: number | string;
    height?: number;
    color?: string;
    options: IDropdownOption<any>;
    className?: string;
    label?: string;
    multiselect?: boolean;
    required?: boolean;
    value?: any;
    onChange?: (...event: any) => void;
    placeholder?: string;
    borderColor?: string;
    lineHeight?: number;
    defaultValue?: string;
    fontWeight?: string;
    disabled?: boolean;
    selectedKey?: string | number | string[] | number[];
    clear?: boolean;
    dropdownHeight?: number;
}

export const CustomSelect: React.FC<IProps> = ({
    backGroundColor,
    borderRadius,
    border,
    width,
    height,
    color,
    options,
    className,
    label,
    multiselect,
    required,
    value,
    onChange,
    placeholder,
    borderColor,
    lineHeight,
    defaultValue,
    fontWeight,
    disabled,
    clear,
    dropdownHeight,
    selectedKey,
}) => {
    const [selected, setSelected] = React.useState<any>({ key: null });

    const onChangeCallback = React.useCallback(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        (event: React.FormEvent<HTMLDivElement>, opt?: IDropdownOption<any>, index?: number) => {
            setSelected(opt);
            if (onChange && multiselect) {
                return onChange(
                    value &&
                        (opt?.selected
                            ? [...value, opt?.index as number]
                            : value.filter((key: any) => key !== opt?.index)),
                );
            } else if (onChange && !multiselect) {
                return onChange(opt?.key);
            }
        },
        [value, onChange],
    );

    React.useEffect(() => {
        if (clear) {
            setSelected({ key: null });
        }
    }, [clear]);

    const dropdownStyles: Partial<IDropdownStyles> = {
        dropdown: {
            width: width,
            border: border,
            color: color,
            borderRadius: borderRadius,
            selectors: {
                ':focus::after': {
                    borderRadius: borderRadius,
                },
            },
        },
        title: {
            height: height,
            borderRadius: borderRadius,
            backgroundColor: backGroundColor,
            color: color,
            borderColor: borderColor,
            lineHeight: lineHeight,
            border: border,
            fontWeight: fontWeight,
        },
        caretDown: {
            color: color,
            top: 4,
            lineHeight: lineHeight,
        },
        dropdownItemsWrapper: {
            maxHeight: dropdownHeight,
        },
        root: { borderRadius: borderRadius },
    };

    return (
        <Dropdown
            options={options as any}
            onChange={onChangeCallback}
            className={className}
            calloutProps={{ doNotLayer: true }}
            styles={dropdownStyles}
            label={label}
            multiSelect={multiselect}
            required={required}
            placeholder={placeholder}
            defaultSelectedKey={defaultValue}
            disabled={disabled}
            selectedKey={!multiselect ? (selectedKey ? selectedKey : selected.key) : undefined}
        />
    );
};
