import { IconButton, IIconProps, Separator, TooltipHost } from '@fluentui/react';
import {
    Box,
    CircularProgress,
    Collapse,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import ProgressBar from '@ramonak/react-progress-bar';
import { UseMutateFunction } from '@tanstack/react-query';
import moment from 'moment';
import React from 'react';
import { Col, Container, Row } from 'react-grid-system';

import { CustomSelect } from '../../../../common/select';
import { stageOpts } from '../../../../consts';
import { EProductionStatus } from '../../../../enums';
import { handleIfCheckBoxChecked } from '../../../../helpers/utils';
import { LoaderContext } from '../../../../routes/router';
import { IOrderElems, IOrderStages } from '../../../../store/models.ts/common';
import { ICheckedValues } from '../../../../store/models.ts/ProductionStages';
import { useOrderContext } from '../../context/orderContext';
import { infoButtonStyles } from '../../EditModal/consts';
import { StageDates } from './stages';

interface IProps {
    order: IOrderElems;
    isRowOpen: boolean;
    mutateStatuses: UseMutateFunction<any, any, ICheckedValues, unknown>;
}

export const CollapseRow: React.FC<IProps> = ({ order, isRowOpen, mutateStatuses }) => {
    const { checkedRows } = useOrderContext();
    const ctxt = React.useContext(LoaderContext);
    const infoIcon: IIconProps = { iconName: 'Info' };

    const handleProductionStatus = React.useCallback(() => {
        if (order.document.productionStatusCompleted > 0) return EProductionStatus.COMPLETED;
        if (order.document.productionStatusCompleted === 0) return EProductionStatus.NOTSTARTED;
    }, [order.document.productionStatusCompleted, order.document.productionStatusTotal]);

    const handleStatusChange = async (value: string, stage: IOrderStages) => {
        const statusId = stageOpts.find((status) => status.key === value)?.index;
        const stageIndex = order?.document?.orderProductionStages.indexOf(stage);
        ctxt?.setLoaderIsActive(true);

        const allIds =
            statusId === '3'
                ? order?.document?.orderProductionStages
                      .slice(0, stageIndex + 1)
                      .filter((el) => el.productionStatusId !== '3')
                      .map((id) => id.productionStageId)
                : order?.document?.orderProductionStages
                      .slice(stageIndex)
                      .filter((el) => el.productionStatusId !== '1')
                      .map((id) => id.productionStageId);

        const data: ICheckedValues = {
            poDocIds: [order?.document?.poDocId] as string[],
            productionStageId: allIds,
            productionStatusId: statusId ? statusId : '',
        };

        mutateStatuses(data);
    };

    return (
        <TableRow
            className="bg-bg-gray"
            sx={
                handleIfCheckBoxChecked(checkedRows, order.document.poDocId)
                    ? { border: '1.1px solid #f5f5f5' }
                    : { border: '1px solid #f5f5f5' }
            }
        >
            <TableCell colSpan={19} className="w-full pb-0 pt-0 pl-[33px]">
                <Collapse in={isRowOpen} timeout="auto" unmountOnExit>
                    <Row className="justify-between t-row">
                        <Col xs={12} md={7} className="pr-0 pl-0">
                            <Table>
                                <TableHead>
                                    <TableRow className="pt-4 pb-4">
                                        <TableCell className="text-black font-bold">
                                            Production stage
                                        </TableCell>
                                        <TableCell className="text-black font-bold">
                                            Planned completion date
                                        </TableCell>
                                        <TableCell className="text-black font-bold">
                                            Actual completion date
                                        </TableCell>
                                        <TableCell className="text-black font-bold">
                                            Modified By
                                        </TableCell>
                                        <TableCell className="text-black font-bold">
                                            Production status
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {order.document?.orderProductionStages?.map((pStage) => (
                                        <TableRow
                                            key={pStage.productionStageId}
                                            className="production_follow_table_2row"
                                        >
                                            <TableCell className="flex items-center">
                                                {pStage.productionStageDs}
                                                <TooltipHost
                                                    id={pStage.productionStageId}
                                                    content={pStage.productionStageInfo}
                                                >
                                                    <IconButton
                                                        aria-describedby={pStage.productionStageId}
                                                        styles={infoButtonStyles}
                                                        iconProps={infoIcon}
                                                    />
                                                </TooltipHost>
                                            </TableCell>
                                            <StageDates
                                                stage={pStage}
                                                order={order}
                                                key={pStage.productionStageId}
                                            />
                                            <TableCell>
                                                <div className="bg-white border p-2 mt-[-5px]">
                                                    <span>
                                                        {pStage.modifiedOn
                                                            ? moment(pStage.modifiedOn).format(
                                                                  'MM-DD-YYYY',
                                                              )
                                                            : '...'}
                                                    </span>
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <p>{pStage.modifiedBy ?? '...'}</p>
                                            </TableCell>
                                            <TableCell>
                                                <CustomSelect
                                                    options={stageOpts as any}
                                                    onChange={(value) =>
                                                        handleStatusChange(value, pStage)
                                                    }
                                                    defaultValue={
                                                        stageOpts.find(
                                                            (stg) =>
                                                                stg.index ===
                                                                pStage.productionStatusId,
                                                        )?.text
                                                    }
                                                    selectedKey={
                                                        stageOpts.find(
                                                            (stg) =>
                                                                stg.index ===
                                                                pStage.productionStatusId,
                                                        )?.text
                                                    }
                                                    backGroundColor={`${
                                                        pStage.percentage === 100 ||
                                                        pStage.productionStatusId === '3'
                                                            ? '#60AF85'
                                                            : '#ACACAC'
                                                    }`}
                                                    color="white"
                                                    width={150}
                                                    height={26.2}
                                                    borderRadius={90}
                                                    lineHeight={25}
                                                    value={
                                                        pStage.productionStatusId === '1'
                                                            ? 'Not started'
                                                            : 'Completed'
                                                    }
                                                />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Col>
                        <Separator vertical className="production-seperator" />
                        <Col xs={4} md={4}>
                            <Container className="p-20 pl-10 production_follow_right_part">
                                <h2 className="text-left font-bold">
                                    Remaining time to the expected delivery day
                                </h2>
                                <h4 className="text-gray-400 mt-8 mb-3">
                                    <b
                                        className={
                                            order.document.daysToDelivery <= 1
                                                ? 'text-hard-danger mr-2'
                                                : 'text-black mr-2'
                                        }
                                    >
                                        {order.document.daysToDelivery}
                                    </b>
                                    days left
                                </h4>
                                <ProgressBar
                                    completed={order.document.productionCompletionPercentage}
                                    bgColor={
                                        order.document.daysToDelivery <= 1 ? '#F4A4B6' : '#8AB3D9'
                                    }
                                    height="6px"
                                    isLabelVisible={false}
                                    width="340px"
                                    className="mb-4 mt-3"
                                />
                                <Box className="flex items-center mt-20">
                                    <Box
                                        sx={{
                                            position: 'relative',
                                            display: 'inline-flex',
                                        }}
                                    >
                                        <CircularProgress
                                            variant="determinate"
                                            value={order.document.productionCompletionPercentage}
                                            size={150}
                                            color="info"
                                        />
                                        <Box
                                            sx={{
                                                top: 0,
                                                left: 0,
                                                bottom: 0,
                                                right: 0,
                                                position: 'absolute',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <Typography
                                                variant="caption"
                                                component="div"
                                                fontSize={40}
                                            >
                                                {order.document.productionCompletionPercentage}%
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <h6 className="text-gray-400 ml-5 font_16_20">
                                        Production {handleProductionStatus()}
                                    </h6>
                                </Box>
                            </Container>
                        </Col>
                    </Row>
                </Collapse>
            </TableCell>
        </TableRow>
    );
};
