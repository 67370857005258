import { toast } from 'react-toastify';

import { EEnvironmentVars, EProductionFollowOrderBy } from '../../enums';
import {
    IOrderPreProductionStages,
    ISupplier,
    IWashCareInstruction,
} from '../../store/models.ts/common';

export function parseInteger(v: number | string | undefined | null): number | undefined {
    if (!v) {
        return;
    }

    const parsed = parseInt(v.toString(), 10);
    if (isNaN(parsed)) {
        return;
    }

    return parsed;
}

export const productionOrderMap = (value: string) => {
    if (value === EProductionFollowOrderBy.EARLIEST_DELIVERY_DATE) {
        return { expectedDeliveryDate: 'asc' };
    }

    if (value === EProductionFollowOrderBy.LATEST_DELIVERY_DATE)
        return { expectedDeliveryDate: 'desc' };

    if (value === EProductionFollowOrderBy.ORDER_ID) return { orderId: 'asc' };

    if (value === EProductionFollowOrderBy.ITEM) return { style: 'asc' };
};

export const mapSuppliers = (supps: ISupplier[]) => {
    const b = [{ key: '', text: 'Select an option' }];
    supps.map((supp) => {
        b.push({
            key: supp.vendorId,
            text: supp.vendorDs,
        });
    });
    return b;
};

export const mapWashCareInstuctions = (instuctions: IWashCareInstruction[]) => {
    const b: any = [];
    instuctions.map((instuction) => {
        b.push({
            key: instuction.washCareSuggestionId,
            label: `${instuction.washCareSuggestionId}~${instuction.washCareSuggestionDs.substring(
                0,
                15,
            )}`,
        });
    });
    return b;
};

export const checkEnvironment = (): EEnvironmentVars => {
    if (!process.env.REACT_APP_ENV || process.env.REACT_APP_ENV === 'development') {
        return EEnvironmentVars.DEV;
    } else if (process.env.REACT_APP_ENV === 'stage') {
        return EEnvironmentVars.STAGE;
    } else {
        return EEnvironmentVars.PROD;
    }
};

export const checkPreProdcutionStgStatus = (id: number, data: IOrderPreProductionStages[]) => {
    const currentStage = data.filter((dt) => dt.categoryId === id);
    return currentStage.every((currstg) => currstg.notApplicable || currstg.value);
};

//typeof defaultPreProdUpdateValues
export const mapPreProdUpdateData = (
    data: any,
    allStgs: IOrderPreProductionStages[],
    editedFields: string[],
) => {
    const arrData = Object.keys(data);
    const mainObj: any = [];
    const applicableObjArr: any = [];
    allStgs.forEach((stg) => {
        const trimmedStg = stg.description.replace(/ /g, '').replace(/-/g, '');
        if (!editedFields.includes(trimmedStg) && !editedFields.includes(trimmedStg + 'NA')) return;
        arrData.forEach((dt) => {
            if (dt !== trimmedStg) return;
            if (editedFields.includes(trimmedStg)) {
                mainObj.push({
                    stageId: stg.stageId,
                    value: data[dt] === null ? null : data[dt].toString(),
                });
            }
            if (editedFields.includes(trimmedStg + 'NA')) {
                applicableObjArr.push({
                    stageId: stg.stageId,
                    notApplicable: data[`${dt}NA`],
                });
            }
        });
    });
    return [mainObj, applicableObjArr];
};

export function signOutClickHandler(instance: any, homeAccountId: string) {
    const logoutRequest = {
        account: instance.getAccountByHomeId(homeAccountId),
        postLogoutRedirectUri: '/',
    };
    instance.logoutRedirect(logoutRequest);
}

//order
export const handleIfCheckBoxChecked = (selectedRows: string[], poDocId: string): boolean => {
    if (selectedRows.find((id) => id === poDocId)) {
        return true;
    } else {
        return false;
    }
};

export function formatDateToString(date?: Date | null) {
    if (!date) return '';
    function addLeadingZero(number) {
        return number < 10 ? '0' + number : number;
    }

    const month = addLeadingZero(date.getMonth() + 1); // Ay 0'dan başladığı için 1 eklenir
    const day = addLeadingZero(date.getDate());
    const year = date.getFullYear();

    return month + '-' + day + '-' + year;
}

export function groupByObjArrayByField(array: object[], fieldName: string): object[] {
    return Object.values(
        array.reduce((acc, current) => {
            acc[current[fieldName]] = acc[current[fieldName]] ?? [];
            acc[current[fieldName]].push(current);
            return acc;
        }, {}),
    );
}

export function notifyError(message: string) {
    toast.error(truncate(message, 200), {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
    });
}

export function notifyRequestErr(error: any) {
    if (error.response) {
        console.error(error.response.status);
        console.error(error.response.headers);
        notifyError(
            error.response.data?.message ||
                error.response.data?.title ||
                error.response.statusText ||
                (typeof error.response.data === 'string' ? error.response.data : 'Error occured!'),
        );
    } else if (error.request && typeof error.request === 'string') {
        notifyError(error.request || 'Error occured!');
    } else {
        notifyError(error.message || 'Error occured!');
    }
}

export function truncate(str: string, n: number) {
    return str.length > n ? str.slice(0, n - 1) + '...' : str;
}

export function removeSameObjectsWithId(elements: any[], key: string) {
    return elements.reduce((accumulator, current) => {
        const exists = accumulator.find((item) => {
            return item[key] === current[key];
        });
        if (!exists) {
            accumulator = accumulator.concat(current);
        }
        return accumulator;
    }, []);
}
