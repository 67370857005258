import * as React from 'react';

import { useExpose } from '../../../helpers/hooks';
import { IOrderElems, IWashCareInstruction } from '../../../store/models.ts/common';
import EditModal from '../EditModal';

type Tcontext = {
    orders: IOrderElems[];
    checkedRows: string[];
    isLoadMoreData: boolean | null;
    isEditModalOpen: boolean;
    isAdvancedActive: boolean;
    closeEditModal: () => void;
    closeAdvancedView: () => void;
    toggleEditModal: (val: any) => void;
    toggleAdvancedView: (val: any) => void;
    washCareInstructionsOptions: IWashCareInstruction[];
    setCheckedRows: React.Dispatch<React.SetStateAction<string[]>>;
    setLoadMoreData: React.Dispatch<React.SetStateAction<boolean | null>>;
    setOrders: React.Dispatch<React.SetStateAction<IOrderElems[]>>;
    setWashCareInstructionsOptions: React.Dispatch<React.SetStateAction<IWashCareInstruction[]>>;
};

const OrderContext = React.createContext<Tcontext>(null as any);

function OrderProvider({ children }) {
    const [checkedRows, setCheckedRows] = React.useState<string[]>([]);
    const [orders, setOrders] = React.useState<IOrderElems[]>([]);
    const [isLoadMoreData, setLoadMoreData] = React.useState<boolean | null>(false);
    const [washCareInstructionsOptions, setWashCareInstructionsOptions] = React.useState<
        IWashCareInstruction[]
    >([]);
    const {
        close: closeAdvancedView,
        toggle: toggleAdvancedView,
        isOpen: isAdvancedActive,
    } = useExpose();

    const { close: closeEditModal, toggle: toggleEditModal, isOpen: isEditModalOpen } = useExpose();

    const value = {
        orders,
        setOrders,
        checkedRows,
        setCheckedRows,
        closeEditModal,
        isLoadMoreData,
        isEditModalOpen,
        setLoadMoreData,
        toggleEditModal,
        isAdvancedActive,
        closeAdvancedView,
        toggleAdvancedView,
        washCareInstructionsOptions,
        setWashCareInstructionsOptions,
    };

    return (
        <OrderContext.Provider value={value}>
            {children}
            <EditModal />
        </OrderContext.Provider>
    );
}

function useOrderContext() {
    const context = React.useContext(OrderContext);
    if (context === undefined) {
        throw new Error('useModalContext must be used within a ModalProvider');
    }
    return context;
}

export { OrderProvider, useOrderContext };
