import { DefaultButton, TextField } from '@fluentui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { ISurveybuttonStyles } from '../../consts';
import { LoaderContext } from '../../routes/router';
import { deleteFile, saveSurveySection } from '../../services/survey';
import { ActionsButton } from './actionButtons';
import { VendorSurveyDropzone } from './surveyDropzone';

export interface IEnergyFormType {
    energyTextSelect: boolean | any;
    energyFileSelect: boolean | any;
    energyText?: string;
    energyFile?: any;
}

interface IProps {
    handlePrevStep: () => void;
    activeStepId: number[];
    handleNewStep: () => void;
    setEnergyValues: React.Dispatch<React.SetStateAction<IEnergyFormType | null>>;
    energyValues: IEnergyFormType | null;
}

const formSchema = yup.object().shape({
    energyTextSelect: yup.string().required('This field is required'),
    energyFileSelect: yup.string().required('This field is required'),
    energyText: yup.string().when('energyTextSelect', {
        is: 'true',
        then: yup.string().required('This field is required'),
    }),
    energyFile: yup.array().when('energyFileSelect', {
        is: 'true',
        then: yup.array().min(1, 'This field is required').required(),
    }),
});

export const EnergySaving: React.FC<IProps> = ({
    handleNewStep,
    handlePrevStep,
    activeStepId,
    setEnergyValues,
    energyValues,
}) => {
    const [showTextField, setShowTextField] = React.useState(false);
    const [showFileField, setShowFileField] = React.useState(false);
    const [_, setTest] = React.useState(false);

    const ctxt = React.useContext(LoaderContext);

    const {
        control,
        formState: { errors },
        setValue,
        reset,
        handleSubmit,
        getValues,
    } = useForm({
        resolver: yupResolver(formSchema),
        defaultValues:
            energyValues !== null
                ? energyValues
                : {
                      energyTextSelect: '',
                      energyFileSelect: '',
                      energyText: '',
                      energyFile: [],
                  },
        reValidateMode: 'onChange',
    });

    React.useEffect(() => {
        if (
            energyValues?.energyText &&
            energyValues?.energyFile &&
            energyValues !== null &&
            energyValues !== undefined
        ) {
            reset(energyValues as any);
            if (energyValues?.energyText?.length) {
                setShowTextField(true);
            }
            if (energyValues?.energyFile && energyValues?.energyFile[0]?.length) {
                setShowFileField(true);
            }
        }
    }, [energyValues]);

    const onSubmit = React.useCallback(
        (data: IEnergyFormType) => {
            const newVal = [
                { questionId: 1, answer: data.energyTextSelect },
                { questionId: 2, answer: data.energyText },
                { questionId: 3, answer: data.energyFileSelect },
                { questionId: 4, answer: data.energyFile.toString() },
            ];
            setEnergyValues(data);
            ctxt?.setLoaderIsActive(true);
            saveSurveySection(newVal).then(() => {
                handleNewStep();
                ctxt?.setLoaderIsActive(false);
            });
        },
        [errors],
    );

    const removeFile = React.useCallback(
        (file: any) => {
            ctxt?.setLoaderIsActive(true);
            deleteFile(file).then(() => {
                const newFiles = [...getValues('energyFile')];
                setValue(
                    'energyFile',
                    newFiles.filter((item) => item !== file.toString()),
                );
                ctxt?.setLoaderIsActive(false);
            });
        },
        [getValues('energyFile')],
    );

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="survey-main">
                <p className="border-b border-black pb-1 mt-[100px]">Question 1</p>
                <h3>
                    Does the vendor facility use technology, machinery or processes that attributes
                    to energy savings or efficient energy usage? E.g., renewable energy
                </h3>

                <Controller
                    control={control}
                    name="energyTextSelect"
                    render={({ field }) => (
                        <>
                            <DefaultButton
                                text="Yes"
                                onClick={() => {
                                    setValue('energyTextSelect', 'true');
                                    setShowTextField(true);
                                }}
                                styles={ISurveybuttonStyles}
                                className={`mr-4  ${
                                    field.value === 'true' ? 'bg-black text-white' : ''
                                }`}
                            />
                            <DefaultButton
                                text="No"
                                onClick={() => {
                                    setValue('energyTextSelect', 'false');
                                    setValue('energyText', '');
                                    setShowTextField(false);
                                }}
                                styles={ISurveybuttonStyles}
                                className={`mr-4  ${
                                    field.value === 'false' ? 'bg-black text-white' : ''
                                }`}
                            />
                            <span className="text-red-500">{errors.energyTextSelect?.message}</span>
                        </>
                    )}
                />
                {showTextField && (
                    <div className="border-l border-black pl-5 mt-[30px]">
                        <span className="subQuestion mb-5">
                            Please outline what technology, machinery or processes the facility uses
                        </span>
                        <>
                            <Controller
                                control={control}
                                name="energyText"
                                render={({ field }) => (
                                    <TextField
                                        multiline={true}
                                        onChange={field.onChange}
                                        resizable={false}
                                        rows={5}
                                        className="mt-4"
                                        value={field.value}
                                    />
                                )}
                            />
                            <span className="text-red-500">{errors.energyText?.message}</span>
                        </>
                    </div>
                )}
            </div>
            <div className="survey-main">
                <p className="border-b border-black pb-1 mt-[100px]">Question 2</p>
                <h3>
                    Does the vendor facility have certification(s) with regards to energy savings?
                </h3>

                <Controller
                    control={control}
                    name="energyFileSelect"
                    render={({ field }) => (
                        <>
                            <DefaultButton
                                text="Yes"
                                onClick={() => {
                                    setValue('energyFileSelect', 'true');
                                    setShowFileField(true);
                                }}
                                styles={ISurveybuttonStyles}
                                className={`mr-4  ${
                                    field.value === 'true' ? 'bg-black text-white' : ''
                                }`}
                            />
                            <DefaultButton
                                text="No"
                                onClick={() => {
                                    setValue('energyFileSelect', 'false');
                                    setValue('energyFile', []);
                                    setShowFileField(false);
                                }}
                                styles={ISurveybuttonStyles}
                                className={`mr-4  ${
                                    field.value === 'false' ? 'bg-black text-white' : ''
                                }`}
                            />
                            <span className="text-red-500">{errors.energyFileSelect?.message}</span>
                        </>
                    )}
                />
                {showFileField && (
                    <div className="border-l border-black pl-5 mt-[30px]">
                        <span className="subQuestion mb-5">
                            Please upload certifications as evidence (relevant certifications are
                            e.g., Cradle to Cradle, Made in Green by OEKO-TEX, Nordic Ecolabel, Step
                            by OEKO-TEX - this list is non-exhaustive)
                        </span>
                        <Controller
                            control={control}
                            name="energyFile"
                            render={() => (
                                <VendorSurveyDropzone
                                    questionId={4}
                                    setValue={setValue}
                                    name="energyFile"
                                />
                            )}
                        />
                        {getValues('energyFile') && getValues('energyFile')[0]?.length > 0 && (
                            <div className="acceptedFile mt-10">
                                <h3 className="mb-5">Accepted files :</h3>
                                {getValues('energyFile')?.map((item: any) => (
                                    <div
                                        key={item}
                                        className="flex items-center p-4 py-2 border justify-between mb-2"
                                    >
                                        <p>{item.split('-')[6]}</p>
                                        <button
                                            type="button"
                                            onClick={() => {
                                                removeFile(item);
                                                setTest((prev) => !prev);
                                            }}
                                            className="px-5 py-3 border-l pr-0 text-2xl"
                                        >
                                            X
                                        </button>
                                    </div>
                                ))}
                            </div>
                        )}
                        <span className="text-red-500 mt-3">{errors.energyFile?.message}</span>
                    </div>
                )}
            </div>
            <ActionsButton handlePrevStep={handlePrevStep} activeStepId={activeStepId} />
        </form>
    );
};
