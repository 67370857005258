import React from 'react';

import { ISurveyItem } from '../../store/models.ts/common';

interface IProps {
    surveyData: any;
    active: number[];
}

export const SurveySidebar: React.FC<IProps> = ({ surveyData, active }) => {
    return (
        <div className="pr-[40px] border-r h-screen border-black">
            {surveyData.sections.map((item: ISurveyItem) => (
                <div key={item.sectionId} className="survey-item">
                    {item.parentSectionId === null && (
                        <h1
                            className={`pt-[40px] m-0 ${
                                active[0] === item.sectionId ? 'text-black' : ''
                            }`}
                        >
                            {item.descriptionToBeDisplayed}
                        </h1>
                    )}
                    {item.parentSectionId !== null && (
                        <div className="flex items-center">
                            <div
                                className={`block-act ${
                                    active[1] === item.sectionId && 'bg-black'
                                }`}
                            ></div>
                            <p className={`mt-2 ${active[1] === item.sectionId && 'text-black'}`}>
                                {item.descriptionToBeDisplayed}
                            </p>
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};
