import { InteractionType } from '@azure/msal-browser';
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    useMsal,
    useMsalAuthentication,
} from '@azure/msal-react';
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import { Icon, Spinner, SpinnerSize } from '@fluentui/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { motion } from 'framer-motion';
import React from 'react';

import { ExeptionPanel } from './common/exeptionPanel';
import { CustomLoader } from './common/loader';
import { loginRequest } from './helpers/authConfig';
import { PermissionContext } from './helpers/hooks';
import { Router } from './routes/router';
import { getPermissions } from './services/permissionService';
import { IExeption, IPermission } from './store/models.ts/common';

export const App: React.FC = () => {
    const { error } = useMsalAuthentication(InteractionType.Redirect, loginRequest);
    const { accounts } = useMsal();
    const [permissions, setPermissions] = React.useState<IPermission | null>(null);
    const [errRes, setErrRes] = React.useState<IExeption | null>(null);
    const [isLoaderActive, setIsLoaderActive] = React.useState(true);

    const queryClient = new QueryClient();

    initializeIcons();

    React.useEffect(() => {
        let timer;
        setErrRes(null);
        getPermissions()
            .then((res) => {
                setPermissions(res.data);
                setErrRes(null);
                timer = setTimeout(() => {
                    setIsLoaderActive(false);
                }, 2000);
            })
            .catch((err: { response: { data: IExeption } }) => {
                setErrRes(
                    err?.response?.data || {
                        message: 'Unexpected error. Please contact with administrator.',
                        statusCode: 500,
                        stackTrace: '',
                    },
                );
                setIsLoaderActive(false);
            });

        return () => {
            clearTimeout(timer);
        };
    }, [accounts]);

    return (
        <>
            <AuthenticatedTemplate>
                {isLoaderActive ? (
                    <CustomLoader dismiss={permissions !== null ? true : false} />
                ) : (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.6 }}
                    >
                        {errRes !== null && <ExeptionPanel error={errRes} />}
                        {permissions && (
                            <>
                                <QueryClientProvider client={queryClient}>
                                    <PermissionContext.Provider value={permissions}>
                                        <Router />
                                    </PermissionContext.Provider>
                                    <ReactQueryDevtools initialIsOpen={false} />
                                </QueryClientProvider>
                            </>
                        )}
                    </motion.div>
                )}
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                {!error && (
                    <div className="p-4 flex flex-row items-center justify-center">
                        <Spinner size={SpinnerSize.xSmall} />
                        <span className="text-base pl-2">
                            We are redirecting to login. Please wait.
                        </span>
                    </div>
                )}
                {error && (
                    <div className="pt-32 flex justify-center">
                        <div style={{ maxWidth: 700 }} className="p-2 m-2">
                            <div className="flex flex-row items-center justify-center">
                                <Icon iconName="Warning" />
                                <span className="pl-2 font-medium text-lg">
                                    We are sorry but there were some errors in the authentication
                                    process.
                                </span>
                            </div>
                            <div className="text-red-800 pt-4 text-base">
                                {false ? (
                                    <span>
                                        Error code: <b>{error?.errorCode}</b>
                                    </span>
                                ) : (
                                    error.errorMessage
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </UnauthenticatedTemplate>
        </>
    );
};
