import { TableBody } from '@mui/material';
import { UseMutateFunction } from '@tanstack/react-query';
import React from 'react';

import { ICheckedValues } from '../../../store/models.ts/ProductionStages';
import { useOrderContext } from '../context/orderContext';
import { CollapsableProductionTableRow } from './Row/row';

interface IProps {
    mutateStatuses: UseMutateFunction<any, any, ICheckedValues, unknown>;
}

export const CollapsableProductionTableBody: React.FC<IProps> = ({ mutateStatuses }) => {
    const { orders } = useOrderContext();

    return (
        <TableBody>
            {orders.map((order, idx) => (
                <CollapsableProductionTableRow
                    order={order}
                    key={order.document.poDocId + idx}
                    mutateStatuses={mutateStatuses}
                />
            ))}
        </TableBody>
    );
};
