import axios from 'axios';

import { BaseUrl } from '../consts';
import { getAuthHead } from '../helpers/authHelper';
import { IWashCareInstruction } from '../store/models.ts/common';

export const getWashCareInstructions = async () => {
    const auth = await getAuthHead();
    return await axios.get<IWashCareInstruction[]>(
        `${BaseUrl}PurchaseOrders/getWashCareSuggestions`,
        auth,
    );
};
