import { IconButton, Modal, Spinner, SpinnerSize } from '@fluentui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { iconButtonStyles, modalContentStyles } from '../../../consts';
import { notifyRequestErr } from '../../../helpers/utils';
import { ReactComponent as LayersIcon } from '../../../images/icons/layers.svg';
import { packingListService } from '../../../services/packingList';
import { usePackingListDetailContext } from '../context';

const validationSchema = yup.object({
    poDocId: yup.string().required('Purchase Order is required'),
});

const CreatePackingListModal = () => {
    const {
        packingList,
        setPackageData,
        setPurchaseOrders,
        isAddPurchaseOrderModalOpen,
        closeAddPurchaseOrderModal,
        availablePurchaseOrders,
    } = usePackingListDetailContext();
    //const { data: purchaseOrdersData, status: purchaseOrdersStatus } = useSelector(orderSelector);
    const [loading, setLoading] = useState(false);

    const form = useForm<yup.InferType<typeof validationSchema>>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            poDocId: '',
        },
    });

    const onSubmit = (values: yup.InferType<typeof validationSchema>) => {
        setLoading(true);
        packingListService
            .addPurchaseOrder({
                packingListId: packingList?.packingListId ?? 0,
                poDocId: values.poDocId,
            })
            .then((data) => {
                setPurchaseOrders((prev) => [...prev, ...data]);
                toast.success('Purchase order added successfully');
                return packingListService.getPackages({
                    packingListId: packingList?.packingListId ?? 0,
                });
            })
            .then((data) => {
                setPackageData(data);
                closeAddPurchaseOrderModal();
            })
            .catch((err) => notifyRequestErr(err))
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Modal
            titleAriaId={'dd'}
            isOpen={isAddPurchaseOrderModalOpen}
            onDismiss={closeAddPurchaseOrderModal}
            isBlocking={false}
            containerClassName={
                modalContentStyles.container +
                ' w-[40%] max-w-[600px] min-w-[400px] h-fit min-h-[360px] overflow-visible'
            }
            className="edit-pre-modal"
        >
            <div className={modalContentStyles.header}>
                <b id="dsd" className="text-black bold head flex items-center gap-x-4 ">
                    <LayersIcon />
                    Add Purchase Order
                </b>
                <IconButton
                    styles={iconButtonStyles}
                    iconProps={{ iconName: 'Cancel' }}
                    ariaLabel="Close popup modal"
                    onClick={closeAddPurchaseOrderModal}
                />
            </div>
            <form onSubmit={form.handleSubmit(onSubmit)} className="mt-6">
                <div className="px-6 ">
                    <label htmlFor="packingListVendorSelect" className="text-sm font-medium">
                        Select the purchase order
                    </label>
                    <Select
                        id="packingListVendorSelect"
                        isClearable
                        isSearchable
                        className="basic-single mt-1"
                        classNamePrefix="select"
                        //isLoading={purchaseOrdersStatus === EProcessStatus.PENDING}
                        name="color"
                        options={availablePurchaseOrders.map((document) => ({
                            value: document.poDocId,
                            label: document.poIdToBeDisplayed,
                        }))}
                        styles={{
                            menuList(base) {
                                return { ...base, maxHeight: '140px', overflow: 'auto' };
                            },
                        }}
                        onChange={(selectedOption) => {
                            form.setValue('poDocId', selectedOption?.value ?? '');
                        }}
                    />
                    {form.formState.errors.poDocId && !form.formState.touchedFields.poDocId && (
                        <p className="mt-1 text-rose-700">
                            {form.formState.errors.poDocId.message}
                        </p>
                    )}
                </div>
                <div
                    className={
                        modalContentStyles.footer +
                        ' border-t border-gray-200 flex items-end justify-end gap-x-4 mt-20'
                    }
                >
                    <button
                        disabled={loading}
                        type="button"
                        onClick={closeAddPurchaseOrderModal}
                        className="bg-white px-4 py-2.5 flex gap-x-2 border border-gray-300 hover:bg-gray-100/70 transition disabled:opacity-50"
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        disabled={loading}
                        className="bg-black text-white px-4 py-2.5 flex gap-x-2 hover:opacity-80 disabled:opacity-50 transition"
                    >
                        {loading && <Spinner size={SpinnerSize.medium} className="mr-1" />}
                        Save
                    </button>
                </div>
            </form>
        </Modal>
    );
};

export default CreatePackingListModal;
