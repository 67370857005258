import { DefaultButton } from '@fluentui/react';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { EmptyPanel } from '../../common/emptyPanel';
import { Panel } from '../../common/panel';
import { SkeletonElement } from '../../common/skeleton';
import { ROUTES } from '../../consts';
import { EProcessStatus } from '../../helpers/utils/redux';
import { dashboardSelector } from '../../store/reducers/dashboardSlice';

export const MessagesPanel: React.FC = () => {
    const messages = useSelector(dashboardSelector);
    const [messagez, setMessagez] = React.useState(messages.data.messages);
    const [groupValues, setGroupValues] = React.useState({
        all: true,
        unread: false,
        read: false,
    });

    const handleMessageStatusChange = (val: string) => {
        if (val === 'all') {
            setMessagez(messages.data.messages);
        } else if (val === 'unread') {
            const cloneMessages = [...messages.data.messages];
            setMessagez(cloneMessages.filter((mess) => !mess.isSeen));
        } else if (val === 'read') {
            const cloneMessages = [...messages.data.messages];
            setMessagez(cloneMessages.filter((mess) => mess.isSeen));
        }
    };

    React.useEffect(() => {
        if (messages.status === EProcessStatus.SUCCESS) {
            setMessagez(messages.data.messages);
        }
    }, [messages.status]);

    return (
        <Panel className="mt-3 max-h-[350px] overflow-hidden">
            <div className="panelHeader border-b-2 p-8 pb-[25px] pt-[15]">
                <div className="flex items-center justify-between">
                    <p className="text-[20px]">
                        <b>Messages</b>
                    </p>
                    <div className="group">
                        <DefaultButton
                            className={groupValues.all ? 'bg-btn-gray text-white' : ''}
                            onClick={() => {
                                handleMessageStatusChange('all');
                                setGroupValues({ all: true, unread: false, read: false });
                            }}
                        >
                            All
                        </DefaultButton>
                        <DefaultButton
                            className={groupValues.unread ? 'bg-btn-gray text-white' : ''}
                            onClick={() => {
                                handleMessageStatusChange('unread');
                                setGroupValues({ all: false, unread: true, read: false });
                            }}
                        >
                            Unread
                        </DefaultButton>
                        <DefaultButton
                            className={groupValues.read ? 'bg-btn-gray text-white' : ''}
                            onClick={() => {
                                handleMessageStatusChange('read');
                                setGroupValues({ all: false, unread: false, read: true });
                            }}
                        >
                            Read
                        </DefaultButton>
                    </div>
                    <DefaultButton onClick={() => 0}>View all</DefaultButton>
                </div>
            </div>
            {messages.status === EProcessStatus.PENDING ? (
                <SkeletonElement itemCount={4} className="p-8 pb-5 height-full" />
            ) : messages.status === EProcessStatus.SUCCESS ? (
                messagez.length ? (
                    <div className="message_body p-5 pt-[6px] pr-[5px] max-h-[300px] min-h-[250px] overflow-y-scroll">
                        {messagez.map((message) => (
                            <div
                                className="flex items-center justify-between border-b-2 p-4"
                                key={message.messageId}
                            >
                                <div className="order_id_field flex flex-col">
                                    <span className="text-txt-gray font-[11px] flex items-center">
                                        {!message.isSeen && (
                                            <div
                                                className={`rounded-full w-[13px] h-[13px] bg-dot-red ml-[-20px] mr-[5px]`}
                                            ></div>
                                        )}
                                        Order ID
                                    </span>
                                    <span
                                        className={
                                            !message.isSeen
                                                ? 'font-bold underline'
                                                : 'font-medium  text-txt-gray underline'
                                        }
                                    >
                                        <NavLink
                                            to={`../${ROUTES.ORDER_DETAILS}/${message.poDocId}?isMaster=${message.isMaster}`}
                                            className="underline"
                                        >
                                            {message.orderIdToBeDisplayed}
                                        </NavLink>
                                    </span>
                                </div>
                                <div className="max-w-xl">
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: message.messageText,
                                        }}
                                        className={
                                            !message.isSeen
                                                ? 'font-bold'
                                                : 'font-medium  text-txt-gray'
                                        }
                                    ></span>
                                </div>
                                <span
                                    className={
                                        !message.isSeen
                                            ? 'font-bold text-txt-gray italic'
                                            : 'font-medium text-txt-gray italic'
                                    }
                                >
                                    {moment(message.messageDate).format('MM-DD-YYYY')}
                                </span>
                            </div>
                        ))}
                    </div>
                ) : (
                    <EmptyPanel text="Nothing to show here!" className="p-[90px]" />
                )
            ) : (
                <p>Error...</p>
            )}
        </Panel>
    );
};
