import { DefaultButton } from '@fluentui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { ISurveybuttonStyles } from '../../consts';
import { LoaderContext } from '../../routes/router';
import { saveSurveySection } from '../../services/survey';
import { ActionsButton } from './actionButtons';

export interface IAccessFormType {
    firstQuestionSelect: string;
    secondQuestionSelect: string;
    thirdQuestionSelect: string;
}

interface IProps {
    handlePrevStep: () => void;
    activeStepId: number[];
    handleNewStep: () => void;
    setAccessValues: React.Dispatch<React.SetStateAction<IAccessFormType | null>>;
    accessValues: IAccessFormType | null;
}

const formSchema = yup.object().shape({
    firstQuestionSelect: yup.string().required('This field is required'),
    secondQuestionSelect: yup.string().required('This field is required'),
    thirdQuestionSelect: yup.string().required('This field is required'),
});

export const Access: React.FC<IProps> = ({
    handleNewStep,
    handlePrevStep,
    activeStepId,
    accessValues,
    setAccessValues,
}) => {
    const ctxt = React.useContext(LoaderContext);
    const {
        control,
        formState: { errors },
        setValue,
        reset,
        handleSubmit,
    } = useForm({
        resolver: yupResolver(formSchema),
        defaultValues:
            accessValues !== null
                ? accessValues
                : {
                      firstQuestionSelect: '',
                      secondQuestionSelect: '',
                      thirdQuestionSelect: '',
                  },
        reValidateMode: 'onChange',
    });

    const onSubmit = React.useCallback(
        (data: IAccessFormType) => {
            const newVal = [
                { questionId: 17, answer: data.firstQuestionSelect },
                { questionId: 18, answer: data.secondQuestionSelect },
                { questionId: 19, answer: data.thirdQuestionSelect },
            ];
            setAccessValues(data);
            ctxt?.setLoaderIsActive(true);
            saveSurveySection(newVal).then(() => {
                handleNewStep();
                ctxt?.setLoaderIsActive(false);
            });
        },
        [errors],
    );

    React.useEffect(() => {
        if (accessValues) {
            reset(accessValues);
        }
    }, [accessValues]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="survey-main">
                <p className="border-b border-black pb-1 mt-[100px]">Question 1</p>
                <h3>
                    Does the facility provide or provide access to any free or subsidized extended
                    education or education courses (online or offline) for employees?
                </h3>

                <Controller
                    control={control}
                    name="firstQuestionSelect"
                    render={({ field }) => (
                        <>
                            <DefaultButton
                                text="Yes"
                                onClick={() => {
                                    setValue('firstQuestionSelect', 'true');
                                }}
                                styles={ISurveybuttonStyles}
                                className={`mr-4  ${
                                    field.value === 'true' ? 'bg-black text-white' : ''
                                }`}
                            />
                            <DefaultButton
                                text="No"
                                onClick={() => {
                                    setValue('firstQuestionSelect', 'false');
                                }}
                                styles={ISurveybuttonStyles}
                                className={`mr-4  ${
                                    field.value === 'false' ? 'bg-black text-white' : ''
                                }`}
                            />
                            <span className="text-red-500">
                                {errors.firstQuestionSelect?.message}
                            </span>
                        </>
                    )}
                />
            </div>
            <div className="survey-main">
                <p className="border-b border-black pb-1 mt-[100px]">Question 2</p>
                <h3>
                    Does the facility provide or provide access to any free or subsidized onsite
                    child daycare for employees?
                </h3>

                <Controller
                    control={control}
                    name="secondQuestionSelect"
                    render={({ field }) => (
                        <>
                            <DefaultButton
                                text="Yes"
                                onClick={() => {
                                    setValue('secondQuestionSelect', 'true');
                                }}
                                styles={ISurveybuttonStyles}
                                className={`mr-4  ${
                                    field.value === 'true' ? 'bg-black text-white' : ''
                                }`}
                            />
                            <DefaultButton
                                text="No"
                                onClick={() => {
                                    setValue('secondQuestionSelect', 'false');
                                }}
                                styles={ISurveybuttonStyles}
                                className={`mr-4  ${
                                    field.value === 'false' ? 'bg-black text-white' : ''
                                }`}
                            />
                            <span className="text-red-500">
                                {errors.secondQuestionSelect?.message}
                            </span>
                        </>
                    )}
                />
            </div>
            <div className="survey-main">
                <p className="border-b border-black pb-1 mt-[100px]">Question 3</p>
                <h3>
                    Does the facility provide or provide access to any free or subsidized onsite
                    medical care for employees?
                </h3>

                <Controller
                    control={control}
                    name="thirdQuestionSelect"
                    render={({ field }) => (
                        <>
                            <DefaultButton
                                text="Yes"
                                onClick={() => {
                                    setValue('thirdQuestionSelect', 'true');
                                }}
                                styles={ISurveybuttonStyles}
                                className={`mr-4  ${
                                    field.value === 'true' ? 'bg-black text-white' : ''
                                }`}
                            />
                            <DefaultButton
                                text="No"
                                onClick={() => {
                                    setValue('thirdQuestionSelect', 'false');
                                }}
                                styles={ISurveybuttonStyles}
                                className={`mr-4  ${
                                    field.value === 'false' ? 'bg-black text-white' : ''
                                }`}
                            />
                            <span className="text-red-500">
                                {errors.thirdQuestionSelect?.message}
                            </span>
                        </>
                    )}
                />
            </div>
            <ActionsButton handlePrevStep={handlePrevStep} activeStepId={activeStepId} />
        </form>
    );
};
