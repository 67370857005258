import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { BaseUrl } from '../../consts';
import { getAuthHead } from '../../helpers/authHelper';
import { ISupplier } from '../models.ts/common';

export const fetchSuppliers = createAsyncThunk('dashboardActions/getSuppliers', async () => {
    const auth = await getAuthHead();
    const response = await axios.get(`${BaseUrl}purchaseorders/getVendors`, auth);
    return response.data as ISupplier[];
});
