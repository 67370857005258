import { TableCell } from '@mui/material';
import * as React from 'react';

import { IOrderElems } from '../../../../store/models.ts/common';
import { useOrderContext } from '../../context/orderContext';

interface IProps {
    order: IOrderElems;
}

export const AdvancedViewFields: React.FC<IProps> = ({ order }) => {
    const { isAdvancedActive, washCareInstructionsOptions } = useOrderContext();
    return (
        <>
            {isAdvancedActive && (
                <>
                    {order.document.orderPreProductionStages.map((stage) => {
                        if (stage.description === 'Confirmed Wash Care Instructions Desc') {
                            const wciValue = order.document.orderPreProductionStages.find(
                                (stage) => stage.description === 'Confirmed Wash Care Instructions',
                            );
                            if (wciValue) {
                                const wciOption = washCareInstructionsOptions.find(
                                    (option) => option.washCareSuggestionId === wciValue.value,
                                );
                                if (wciOption)
                                    return (
                                        <TableCell key={stage.stageId}>
                                            {wciOption.washCareSuggestionDs || (
                                                <div className="bg-estimate-danger">
                                                    <div className="p-2">No data</div>
                                                </div>
                                            )}
                                        </TableCell>
                                    );
                            }
                        }
                        return (
                            <TableCell key={stage.stageId}>
                                {stage.reportValue ? (
                                    stage.url ? (
                                        <a href={stage.url} target="_blank">
                                            {stage.reportValue}
                                        </a>
                                    ) : (
                                        stage.reportValue
                                    )
                                ) : (
                                    <div className="bg-estimate-danger">
                                        <div className="p-2">No data</div>
                                    </div>
                                )}
                            </TableCell>
                        );
                    })}
                    <TableCell></TableCell>
                </>
            )}
        </>
    );
};
