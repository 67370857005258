import { Checkbox, TableCell, TableHead, TableRow } from '@mui/material';
import React, { useMemo } from 'react';

import { groupByObjArrayByField } from '../../../helpers/utils';
import { useOrderContext } from '../context/orderContext';

export const CollapsableProductionTableHeader: React.FC = () => {
    const { checkedRows, setCheckedRows, isAdvancedActive, orders } = useOrderContext();

    const isIndeterminate = checkedRows.length && checkedRows.length < orders.length ? true : false;

    const fullyChecked = checkedRows.length && checkedRows.length === orders.length ? true : false;

    const handleCheckAll = () => {
        setCheckedRows(orders.map((elem) => elem.document.poDocId));
    };

    const handleUncheckAll = () => {
        setCheckedRows([]);
    };

    const handleCheckChanges = () => {
        if (isIndeterminate || !checkedRows.length) {
            handleCheckAll();
        } else {
            handleUncheckAll();
        }
    };

    const grouppedOrderPreProdStagesByCategory = useMemo(
        () =>
            groupByObjArrayByField(
                orders[0]?.document.orderPreProductionStages ?? [],
                'categoryId',
            ),
        [orders],
    );

    return (
        <TableHead className="bg-bg-gray py-10 container">
            <TableRow className="parent-row">
                {!isAdvancedActive && <TableCell />}
                <TableCell
                    className={`sticky left-0 bg-white z-[3] pl-0 ${
                        isAdvancedActive && 'border-r-2 shadowy'
                    }`}
                >
                    {/* <TableCell className="p-0 border-none">
                        <button
                            disabled={!checkedRows.length}
                            onClick={() => toggleEditModal(false)}
                            className="cursor-pointer flex gap-2 items-center text-black border border-[#8a8886] px-3 py-1 rounded-sm disabled:opacity-50 disabled:cursor-not-allowed"
                        >
                            Edit
                        </button>
                    </TableCell> */}
                    <TableCell padding="checkbox" className="border-none">
                        <Checkbox
                            color="primary"
                            indeterminate={isIndeterminate}
                            checked={fullyChecked}
                            onChange={handleCheckChanges}
                        />
                    </TableCell>
                    <TableCell className="border-none min-w-[150px]">Season / Delivery</TableCell>
                    <TableCell className="border-none min-w-[130px]">PO#</TableCell>
                    <TableCell className="border-none min-w-[170px]">Style#</TableCell>
                    <TableCell className="border-none min-w-[160px]">Color Desc</TableCell>
                </TableCell>
                <TableCell>Style Desc</TableCell>
                {isAdvancedActive && <TableCell>Style Type</TableCell>}
                <TableCell>QTY</TableCell>
                <TableCell>ETD</TableCell>
                <TableCell>ETA</TableCell>
                <TableCell>Production Progress</TableCell>
                <TableCell>Days to delivery</TableCell>
                <TableCell>Vendor</TableCell>
                {!isAdvancedActive && (
                    <>
                        <TableCell>WHS</TableCell>
                        {grouppedOrderPreProdStagesByCategory.map((preProdStage) => (
                            <TableCell key={preProdStage[0].categoryId}>
                                {preProdStage[0].categoryShortDesc}
                            </TableCell>
                        ))}
                    </>
                )}
                {isAdvancedActive && (
                    <>
                        {orders[0]?.document?.orderPreProductionStages?.map(({ description }) => (
                            <TableCell>{description}</TableCell>
                        ))}
                        <TableCell></TableCell>
                    </>
                )}
            </TableRow>
        </TableHead>
    );
};
