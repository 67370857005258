import { TableBody } from '@mui/material';
import React from 'react';

import { useShippingAndTrackingContext } from '../context/shippingAndTracking';
import { ShippingAndTrackingTableRow } from './row';

export const ShippingAndTrackingTableBody: React.FC = () => {
    const { orders } = useShippingAndTrackingContext();

    return (
        <TableBody>
            {orders.map((order) => (
                <ShippingAndTrackingTableRow order={order} key={order.document.poDocId} />
            ))}
        </TableBody>
    );
};
