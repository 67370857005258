import { useQuery } from '@tanstack/react-query';
import * as queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';

import { QUERY_KEYS } from '../../consts';
import { orderService } from '../../services/orderService';
import { IOrderFilter } from '../../store/models.ts/common';

export function useSearchParams<T>(defaultParams?: T): [T, (params?: Partial<T>) => void] {
    const navigate = useNavigate();
    const location = useLocation();
    const params = queryString.parse(location.search.trim().replace(/^[?#&]/, ''));

    function setSearchParams(p?: Partial<T>): void {
        navigate({
            ...location,
            search: queryString.stringify(
                { ...p },
                { skipEmptyString: true, skipNull: true, arrayFormat: 'comma' },
            ),
        });
    }
    Object.keys(params).forEach((key) => {
        if (
            [
                'orderIds',
                'vendors',
                'styles',
                'seasons',
                'deliveries',
                'warehouses',
                'searchAfter',
            ].includes(key)
        ) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore: Unreachable code error
            params[key] = typeof params[key] == 'string' ? params[key]?.split(',') : [];
        }
    });

    return [{ ...(defaultParams ?? {}), ...params } as any, setSearchParams];
}

export const useFilterSearchParams = (
    params?: IOrderFilter,
): [IOrderFilter, (params?: Partial<IOrderFilter>) => void, () => void] => {
    const defaultParams: IOrderFilter = {
        ...params,
    };

    const [searchParams, setSearchParams] = useSearchParams(defaultParams);

    const reset = () => {
        setSearchParams();
    };

    return [{ ...searchParams }, setSearchParams, reset];
};

export const useProductionFollowUpOrders = (filters: IOrderFilter) =>
    useQuery(
        [QUERY_KEYS.PRODUCTION_FOLLOW_UP_ORDER, filters],
        () => orderService.getOrders(filters, true),
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        },
    );
export const useShippingTrackingOrders = (filters: IOrderFilter) =>
    useQuery(
        [QUERY_KEYS.SHIPPING_TRACKING_ORDER, filters],
        () => orderService.getOrders(filters, false),
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        },
    );

export const useAsyncStages = () =>
    useQuery([QUERY_KEYS.STAGES], () => orderService.getOrderStages(), {
        refetchOnWindowFocus: false,
    });

export const useAsyncOrderDetails = (id: string) =>
    useQuery([QUERY_KEYS.ORDER_DETAILS], () => orderService.getOrderDetails(id), {
        refetchOnWindowFocus: false,
    });

export const useAsyncComments = (id: string) =>
    useQuery([QUERY_KEYS.COMMENTS], () => orderService.getComments(id), {
        refetchOnWindowFocus: false,
    });

export const useOrderFiles = (id: string, fileType: string) =>
    useQuery([QUERY_KEYS.SHEETS, fileType], () => orderService.getFiles(id, fileType), {
        keepPreviousData: true,
        refetchOnWindowFocus: false,
    });
