import { DatePicker, IDatePickerProps } from '@fluentui/react';
import { FC } from 'react';
import * as React from 'react';
import { Controller } from 'react-hook-form';

import { formatDateToString } from '../helpers/utils';
import { HookFormProps } from '../store/models.ts/common';

interface IProps {
    setEditedFields?: React.Dispatch<React.SetStateAction<string[]>>;
}

export const ControlledDatePicker: FC<HookFormProps & IDatePickerProps & IProps> = (props) => {
    return (
        <Controller
            name={props.name}
            control={props.control}
            rules={props.rules}
            defaultValue={props.defaultValue || ''}
            render={({
                field: { onChange, onBlur, name: fieldName, value },
                fieldState: { error },
            }) => (
                <DatePicker
                    {...props}
                    textField={{
                        name: fieldName,
                        errorMessage: error && error.message,
                        onRenderSuffix: () =>
                            value && !props.disabled ? (
                                <div
                                    onClick={() => {
                                        onChange(null);
                                        props.setEditedFields?.((prev) => [...prev, props.name]);
                                    }}
                                    className="mr-5 h-full flex justify-center items-center pr-2 pl-1 pb-1 text-black text-lg cursor-pointer hover:text-red-500 duration-100"
                                >
                                    x
                                </div>
                            ) : (
                                <div className="w-4"></div>
                            ),
                    }}
                    allowTextInput
                    onSelectDate={(date) => {
                        props.setEditedFields?.((prev) => [...prev, props.name]);
                        onChange(formatDateToString(date));
                    }}
                    value={value !== null && value !== '' ? new Date(value) : undefined}
                    onBlur={onBlur}
                    formatDate={formatDateToString}
                    defaultValue={props.defaultValue}
                    label={props.label}
                />
            )}
        />
    );
};
