import { DefaultButton, Modal } from '@fluentui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

interface IProps {
    setOpen: boolean;
    onClose: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SurveyFinishAlert: React.FC<IProps> = ({ setOpen, onClose }) => {
    const navigate = useNavigate();
    return (
        <div>
            <Modal
                isOpen={setOpen}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className="px-10 pt-10">
                    <b className="text-2xl block mb-10">Thank you for completing the survey!</b>
                    <div className="flex justify-end">
                        <DefaultButton
                            className="py-[20] px-[40px] text-xl"
                            text="Okay"
                            onClick={() => {
                                navigate('/');
                                onClose(false);
                            }}
                        />
                    </div>
                </div>
            </Modal>
        </div>
    );
};
