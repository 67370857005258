import React from 'react';

import Actions from './actions';
import Heading from './heading';
import PackingListTable from './table';

const PackingList = () => {
    return (
        <div className="py-10">
            <div className="py-6 px-8 flex items-center justify-between sticky top-[80px] bg-white z-10">
                <Heading updatedAt={new Date()} />
                <Actions />
            </div>
            <div className="mt-8 px-8">
                <PackingListTable />
            </div>
        </div>
    );
};

export default PackingList;
