import { motion } from 'framer-motion';
import React from 'react';

import logo from '../images/logo.svg';

interface IProps {
    dismiss: boolean;
}

const show = {
    opacity: 1,
    display: 'block',
};

const hide = {
    opacity: 0,
    transitionEnd: {
        display: 'none',
    },
};

export const CustomLoader: React.FC<IProps> = ({ dismiss }) => {
    return (
        <motion.div
            className="w-screen h-screen overflow-hidden bg-orange-50"
            animate={dismiss ? hide : show}
            transition={{
                duration: 3,
            }}
        >
            <motion.div
                className="w-[200px] h-[200px] bg-white absolute top-0 bottom-0 left-0 right-0 m-auto"
                animate={{
                    scale: [1, 2, 2, 1, 1],
                    rotate: [0, 0, 180, 180, 0],
                    borderRadius: ['0%', '0%', '50%', '50%', '0%'],
                }}
                transition={{
                    duration: 2,
                    ease: 'easeInOut',
                    times: [0, 0.2, 0.5, 0.8, 1],
                    repeat: Infinity,
                    repeatDelay: 1,
                }}
            ></motion.div>
            <motion.img
                transition={{
                    duration: 2,
                    ease: 'easeInOut',
                    times: [0, 0.2, 0.5, 0.8, 1],
                    repeat: Infinity,
                    repeatDelay: 1,
                }}
                animate={{
                    scale: [1, 2, 2, 1, 1],
                }}
                src={logo}
                alt=""
                className="z-full absolute top-0 bottom-0 left-0 right-0 m-auto"
            />
        </motion.div>
    );
};
