import { DefaultButton, IconButton, Modal } from '@fluentui/react';
import { useMutation } from '@tanstack/react-query';
import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { useForm } from 'react-hook-form';
import { toast, ToastContainer } from 'react-toastify';

import { iconButtonStyles, modalContentStyles } from '../../../consts';
import { useFilterSearchParams, useProductionFollowUpOrders } from '../../../helpers/hooks/orders';
import {
    groupByObjArrayByField,
    mapPreProdUpdateData,
    notifyRequestErr,
} from '../../../helpers/utils';
import { LoaderContext } from '../../../routes/router';
import { orderService } from '../../../services/orderService';
import { IExeption } from '../../../store/models.ts/common';
import { IPreproductionStageDetail } from '../../../store/models.ts/ProductionFollowUp';
import { useOrderContext } from '../context/orderContext';
import { cancelIcon } from './consts';
import EditModalFields from './fields';

const subCategoryId = 1;
const EditModal = () => {
    const [filters] = useFilterSearchParams();
    const ctxt = React.useContext(LoaderContext);
    const asyncOrders = useProductionFollowUpOrders(filters);
    const [editedFields, setEditedFields] = React.useState<string[]>([]);
    const { handleSubmit, control, reset, setValue, getValues } = useForm();
    const [modalFieldsGroupByCategory, setModalFieldsGroupByCategory] = useState<
        IPreproductionStageDetail[][] | null
    >(null);
    const { orders, setOrders, checkedRows, setCheckedRows, closeEditModal, isEditModalOpen } =
        useOrderContext();

    useEffect(() => {
        orderService.getPreproductionStageDetails().then((res) => {
            setModalFieldsGroupByCategory(
                groupByObjArrayByField(res.elements, 'categoryId') as any,
            );
        });
    }, []);

    const stageDs = React.useMemo(() => {
        if (asyncOrders.isSuccess && orders.length) {
            return orders[0].document?.orderPreProductionStages;
        }
    }, [asyncOrders.status, orders]);

    const updateValuesOfSelectedOrder = useCallback(() => {
        if (checkedRows.length === 1 && asyncOrders.isSuccess) {
            const b = orders.find((elem) => elem.document.poDocId === checkedRows[0]);
            return b ? b.document.orderPreProductionStages : null;
        } else {
            return null;
        }
    }, [checkedRows, asyncOrders, orders]);

    const handleDefaultValues = useCallback(() => {
        const orderValues = updateValuesOfSelectedOrder();
        if (!orderValues) return {};

        const yy = orderValues.map((d) => {
            return {
                [d.description.replace(/ /g, '').replace(/-/g, '')]: d.value,
                [d.description.replace(/ /g, '').replace(/-/g, '') + 'NA']: d.notApplicable,
            };
        });
        return Object.assign({}, ...yy);
    }, [updateValuesOfSelectedOrder]);

    useEffect(() => {
        if (isEditModalOpen) {
            reset();
            setEditedFields([]);
        }
    }, [isEditModalOpen]);

    React.useEffect(() => {
        if (updateValuesOfSelectedOrder() && isEditModalOpen) {
            reset(handleDefaultValues());
        } else if (isEditModalOpen) {
            reset({});
        }
    }, [isEditModalOpen]);

    const { mutate } = useMutation(orderService.editStages, {
        onSuccess: (data) => {
            data.forEach((order) => {
                const orderIdx = orders.findIndex(
                    (o) => o.document.orderIdToBeDisplayed === order.orderIdToBeDisplayed,
                );
                if (orderIdx !== -1) orders[orderIdx].document = { ...order };
            });
            setOrders([...orders]);
            toast.success('Successfully updated!', {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
            ctxt?.setLoaderIsActive(false);
            closeEditModal();
        },
        onError: (err: { response: { data: IExeption } }) => {
            ctxt?.setLoaderIsActive(false);
            notifyRequestErr(err);
        },
        onMutate: () => {
            setCheckedRows([]);
        },
    });

    const onSubmit = useCallback(
        (data: any) => {
            ctxt?.setLoaderIsActive(true);
            const [preProductionStageValues, preProductionStageApplicabilities] =
                mapPreProdUpdateData(
                    data,
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    stageDs!,
                    editedFields,
                );
            const newVal = {
                poDocIds: [...checkedRows],
                preProductionStageValues,
                preProductionStageApplicabilities,
            };
            mutate(newVal);
        },
        [stageDs, editedFields, checkedRows],
    );

    return (
        <Modal
            titleAriaId={'dd'}
            isOpen={isEditModalOpen}
            onDismiss={() => closeEditModal()}
            isBlocking={false}
            containerClassName={modalContentStyles.container}
            className="edit-pre-modal"
        >
            <ToastContainer />
            <div className={modalContentStyles.header}>
                <b id="dsd" className="text-black bold head flex items-center">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-8 w-8 mr-4"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                        />
                    </svg>
                    Pre-production stages
                </b>
                <IconButton
                    styles={iconButtonStyles}
                    iconProps={cancelIcon}
                    ariaLabel="Close popup modal"
                    onClick={() => closeEditModal()}
                />
            </div>
            <div className={modalContentStyles.body}>
                {modalFieldsGroupByCategory && (
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            {modalFieldsGroupByCategory.map((categoryGroup, idx) => (
                                <Col sm={12} md={6} lg={4} xl={3} xxl={2} className="mb-4">
                                    <div className="heading flex items-center">
                                        <div className="rounded-full w-[24px] h-[24px] bg-black relative">
                                            <b className="text-white center_hor_ver text-[20px]">
                                                {idx + 1}
                                            </b>
                                        </div>
                                        <b className="heading ml-3">
                                            {categoryGroup[0].categoryDescription}
                                        </b>
                                    </div>
                                    <div className="form-box border bg-bg-gray p-[25px] pr-[40px] pl-[20px] mt-5">
                                        <EditModalFields
                                            control={control}
                                            setValue={setValue}
                                            getValues={getValues}
                                            editedFields={editedFields}
                                            subCategoryId={subCategoryId}
                                            categoryGroup={categoryGroup}
                                            setEditedFields={setEditedFields}
                                        />
                                    </div>
                                    {idx === 0 && (
                                        <div className="form-box border w-full min-h-[160px] py-[25px] pr-[40px] pl-[20px] bg-bg-gray mt-5">
                                            <h1 className="text-base font-bold">
                                                Selected Style-Color list:
                                            </h1>
                                            <ul className="flex flex-col gap-y-2 mt-4">
                                                {checkedRows &&
                                                    checkedRows
                                                        .filter((_, idx) => idx < 10)
                                                        .map((row) => {
                                                            const element = orders.find(
                                                                (elem) =>
                                                                    elem.document.poDocId === row,
                                                            );
                                                            return element ? (
                                                                <li>{`${element.document.style} - ${element.document.color}`}</li>
                                                            ) : undefined;
                                                        })}
                                                {checkedRows.length > 10 && (
                                                    <p className="font-bold">
                                                        ... <br /> <br />
                                                        another {checkedRows.length - 10} item
                                                        selected.
                                                    </p>
                                                )}
                                            </ul>
                                        </div>
                                    )}
                                </Col>
                            ))}
                        </Row>
                        <div className="flex justify-end mt-2">
                            <DefaultButton
                                text="Save details"
                                allowDisabledFocus
                                className="mr-4 pt-[20px] text-[18px] pl-[35px] pr-[35px] pb-[20px] bg-black text-white"
                                type="submit"
                            />
                        </div>
                    </form>
                )}
            </div>
        </Modal>
    );
};

export default EditModal;
