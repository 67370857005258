import { createSlice } from '@reduxjs/toolkit';

import { EProcessStatus } from '../../helpers/utils/redux';
import { fetchProductionStages } from '../asyncActions/productionFollow';
import { IProductionStageRes } from '../models.ts/ProductionStages';
import { RootState } from '../store';

export interface IInitialState {
    status: EProcessStatus;
    data: IProductionStageRes[];
}
const initialState: IInitialState = {
    data: [
        {
            sortOrder: 0,
            productionStageId: '',
            productionStageDs: '',
        },
    ],
    status: EProcessStatus.IDLE,
};

export const productionStagesSlice = createSlice({
    name: 'productionStages',
    initialState: initialState,
    reducers: {},
    extraReducers: {
        [fetchProductionStages.pending as any]: (state: IInitialState) => {
            state.status = EProcessStatus.PENDING;
        },
        [fetchProductionStages.fulfilled as any]: (state: IInitialState, action: any) => {
            state.status = EProcessStatus.SUCCESS;
            state.data = action.payload;
        },
        [fetchProductionStages.rejected as any]: (state: IInitialState) => {
            state.status = EProcessStatus.ERROR;
        },
    },
});

export const productionStagesSelector = (state: RootState) => state.productionStages;

export default productionStagesSlice.reducer;
