import {
    FontWeights,
    getTheme,
    IButtonStyles,
    ITextFieldStyles,
    mergeStyleSets,
} from '@fluentui/react';
import { addDays, isSameDay } from 'date-fns';
import moment from 'moment';

import { EEnvironmentVars, EProductionFollowOrderBy, EProductionStatus } from './enums';
import { checkEnvironment } from './helpers/utils';
import { IDashboardFilter } from './store/models.ts/dashboard';

export const ROUTES = {
    DASHBOARD: '',
    PRODUCTION_FOLLOW_UP: 'production-follow-up',
    PRE_PRODUCTION_FOLLOW_UP: 'pre-production-follow-up',
    PUBLISH_VIEW_ORDERS: 'publish-view-order',
    ORDER_DETAILS: 'order-detail',
    SURVEY_LIST: 'survey-list',
    SURVEY: 'survey',
    SHIPPING_AND_TRACKING: 'shipping-and-tracking',
    PACKING_LIST: 'packing-list',
};

export const PRODUCT_STATUSES = [
    { key: EProductionStatus.COMPLETED, text: EProductionStatus.COMPLETED },
    { key: EProductionStatus.NOTSTARTED, text: EProductionStatus.NOTSTARTED },
];

export const stageOpts = [
    {
        key: EProductionStatus.COMPLETED,
        text: EProductionStatus.COMPLETED,
        index: '3',
    },
    { key: EProductionStatus.NOTSTARTED, text: EProductionStatus.NOTSTARTED, index: '1' },
];

export const productionFollowOrderOpts = [
    {
        key: EProductionFollowOrderBy.EARLIEST_DELIVERY_DATE,
        text: EProductionFollowOrderBy.EARLIEST_DELIVERY_DATE,
    },
    {
        key: EProductionFollowOrderBy.LATEST_DELIVERY_DATE,
        text: EProductionFollowOrderBy.LATEST_DELIVERY_DATE,
    },
    {
        key: EProductionFollowOrderBy.ORDER_ID,
        text: EProductionFollowOrderBy.ORDER_ID,
    },
    {
        key: EProductionFollowOrderBy.ITEM,
        text: EProductionFollowOrderBy.ITEM,
    },
];

export const DEFAULT_PRODUCTION_FOLLOW_FILTERS = {
    pageSize: 10,
};

export const DEFAULT_DASHBOARD_FILTERS: IDashboardFilter = {
    expectedDeliveryFrom: moment(new Date()).format('YYYY-MM-DD'),
    expectedDeliveryTo: moment(new Date()).add(3, 'day').format('YYYY-MM-DD'),
};

export enum EBaseUrls {
    'BE_DEV' = 'https://localhost:7169/',
    'STAGE' = 'https://stage-vendor-portal-frame.azurewebsites.net/api/',
    'FE_DEV' = 'http://localhost:27559/',
    'MOCK_SERV' = 'http://localhost:8061/v1/',
    'PROD' = 'https://vendor.frame-brand.com/api/',
    'REDIRECT_PROD' = 'https://vendor.frame-brand.com/',
    'REDIRECT_DEV' = 'http://localhost:3000/',
    'REDIRECT_STAGE' = 'https://stage-vendor-portal-frame.azurewebsites.net/',
}
export const BaseUrl =
    checkEnvironment() === EEnvironmentVars.DEV
        ? EBaseUrls.PROD
        : checkEnvironment() === EEnvironmentVars.STAGE
        ? EBaseUrls.PROD
        : EBaseUrls.PROD;

export const RedirectBaseUrl =
    checkEnvironment() === EEnvironmentVars.DEV
        ? EBaseUrls.REDIRECT_PROD
        : checkEnvironment() === EEnvironmentVars.STAGE
        ? EBaseUrls.REDIRECT_PROD
        : EBaseUrls.REDIRECT_PROD;

export const staticRanges = [
    {
        label: 'Next 3 days',
        hasCustomRendering: false,
        range: () => ({
            startDate: new Date(),
            endDate: addDays(new Date(), 3),
        }),
        isSelected(range: any) {
            const definedRange = this.range();
            return (
                isSameDay(range.startDate, definedRange.startDate) &&
                isSameDay(range.endDate, definedRange.endDate)
            );
        },
    },
    {
        label: 'This week',
        hasCustomRendering: false,
        range: () => ({
            startDate: new Date(moment().startOf('isoWeek').toString()),
            endDate: new Date(moment().endOf('isoWeek').toString()),
        }),
        isSelected(range: any) {
            const definedRange = this.range();
            return (
                isSameDay(range.startDate, definedRange.startDate) &&
                isSameDay(range.endDate, definedRange.endDate)
            );
        },
    },
    {
        label: 'This month',
        hasCustomRendering: false,
        range: () => ({
            startDate: new Date(moment().startOf('month').toString()),
            endDate: new Date(moment().endOf('month').toString()),
        }),
        isSelected(range: any) {
            const definedRange = this.range();
            return (
                isSameDay(range.startDate, definedRange.startDate) &&
                isSameDay(range.endDate, definedRange.endDate)
            );
        },
    },
    {
        label: 'Next month',
        hasCustomRendering: false,
        range: () => ({
            startDate: new Date(moment().add(1, 'month').startOf('month').toString()),
            endDate: new Date(moment().add(1, 'month').endOf('month').toString()),
        }),
        isSelected(range: any) {
            const definedRange = this.range();
            return (
                isSameDay(range.startDate, definedRange.startDate) &&
                isSameDay(range.endDate, definedRange.endDate)
            );
        },
    },
];

export const textFieldStyles: Partial<ITextFieldStyles> = {
    fieldGroup: {
        borderColor: '#e6e6e6',
    },
};

export const TEXT_EDITOR_TOOLBAR = {
    options: ['blockType', 'inline', 'list', 'textAlign', 'link', 'emoji', 'image'],
    inline: {
        options: ['bold', 'italic', 'underline', 'strikethrough'],
    },
    blockType: {
        options: ['H1', 'H2', 'H3', 'H4', 'H5', 'Normal'],
        displayNames: [
            { label: 'Normal', displayName: 'Normal', style: 'unstyled' },
            { label: 'H1', displayName: 'Heading 1', style: 'header-one' },
            { label: 'H2', displayName: 'Heading 2', style: 'header-two' },
            { label: 'H3', displayName: 'Heading 3', style: 'header-three' },
            { label: 'H4', displayName: 'Heading 4', style: 'header-four' },
            { label: 'H5', displayName: 'Heading 5', style: 'header-five' },
        ],
    },
    textAlign: { inDropdown: true },
};

const theme = getTheme();

export const ISurveybuttonStyles: Partial<IButtonStyles> = {
    root: {
        padding: '20px 45px 20px 45px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
    textContainer: {
        color: '#AAAAAA',
        font: 'normal normal normal 24px/34px Circular XX',
    },
    label: {
        fontWeight: 100,
    },
};

export const modalContentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        backGroundColor: '#F8F8F8',
        width: '95%',
        height: '95%',
        font: 'normal normal medium 14px/18px Circular XX',
        overflow: 'hidden',
    },
    header: [
        theme.fonts.xLargePlus,
        {
            flex: '1 1 auto',
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '24px',
            borderBottom: '1px solid #DBDBDB',
            font: 'normal normal medium 14px/18px Circular XX',
        },
    ],
    body: {
        flex: '4 4 auto',
        padding: '24px',
        overflowY: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
        font: 'normal normal medium 14px/18px Circular XX',
    },
    footer: {
        flex: '4 4 auto',
        padding: '24px',
        overflowY: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
        font: 'normal normal medium 14px/18px Circular XX',
    },
});

export const iconButtonStyles: Partial<IButtonStyles> = {
    root: {
        color: theme.palette.black,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
    icon: {
        fontSize: '30px',
        fontWeight: 'bold',
    },
};

export const ISurveyQuestionIds = {
    ENERGY: 2,
    WATER: 3,
    RECYCLING: 4,
    TOXIC: 5,
    ACCESS: 7,
    GENDER: 8,
    CHARITY: 10,
};

export const QUERY_KEYS = {
    PRODUCTION_FOLLOW_UP_ORDER: 'production-follow-up-order',
    SHIPPING_TRACKING_ORDER: 'shipping-tracking-order',
    ORDER_DETAILS: 'order_details',
    STAGES: 'stages',
    COMMENTS: 'comments',
    SHEETS: 'sheets',
};

export const FILE_TYPES = {
    FrameTechnicalSheets: 'frame-technical-sheets',
    GarmentTestingResults: 'garment-testing-results',
    FrameSurveyFiles: 'frame-survey-files',
    ShippingAndTrackingFiles: 'shipping-and-tracking-files',
};

export const PACKING_LIST_INITIAL_PAGINATION_INFO = {
    pageIndex: 1,
    pageSize: 8,
    totalPages: 0,
    totalResults: 0,
};
