import { DefaultButton, TextField } from '@fluentui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { ISurveybuttonStyles } from '../../consts';
import { LoaderContext } from '../../routes/router';
import { deleteFile, saveSurveySection } from '../../services/survey';
import { ActionsButton } from './actionButtons';
import { VendorSurveyDropzone } from './surveyDropzone';

export interface IWaterFormType {
    waterTextSelect: boolean | any;
    waterFileSelect: boolean | any;
    waterText?: string;
    waterFile?: any;
}

interface IProps {
    handlePrevStep: () => void;
    activeStepId: number[];
    handleNewStep: () => void;
    setWaterValues: React.Dispatch<React.SetStateAction<IWaterFormType | null>>;
    waterValues: IWaterFormType | null;
}

const formSchema = yup.object().shape({
    waterTextSelect: yup.string().required('This field is required'),
    waterFileSelect: yup.string().required('This field is required'),
    waterText: yup
        .string()
        .nullable()
        .when('waterTextSelect', {
            is: 'true',
            then: yup.string().required('This field is required'),
        }),
    waterFile: yup.array().when('waterFileSelect', {
        is: 'true',
        then: yup.array().min(1, 'This field is required').required(),
    }),
});

export const WaterUsage: React.FC<IProps> = ({
    handleNewStep,
    handlePrevStep,
    activeStepId,
    waterValues,
    setWaterValues,
}) => {
    const [showTextField, setShowTextField] = React.useState(false);
    const [showFileField, setShowFileField] = React.useState(false);
    const [_, setTest] = React.useState(false);
    const ctxt = React.useContext(LoaderContext);
    const {
        control,
        formState: { errors },
        setValue,
        handleSubmit,
        reset,
        getValues,
    } = useForm({
        resolver: yupResolver(formSchema),
        defaultValues:
            waterValues !== null
                ? waterValues
                : {
                      waterTextSelect: '',
                      waterFileSelect: '',
                      waterText: '',
                      waterFile: [] as any,
                  },
        reValidateMode: 'onChange',
    });

    const onSubmit = React.useCallback(
        (data: IWaterFormType) => {
            const newVal = [
                { questionId: 5, answer: data.waterTextSelect },
                { questionId: 6, answer: data.waterText },
                { questionId: 7, answer: data.waterFileSelect },
                { questionId: 8, answer: data.waterFile.toString() },
            ];
            setWaterValues(data);
            ctxt?.setLoaderIsActive(true);
            saveSurveySection(newVal).then(() => {
                handleNewStep();
                ctxt?.setLoaderIsActive(false);
            });
        },
        [errors],
    );

    const removeFile = React.useCallback(
        (file: any) => {
            ctxt?.setLoaderIsActive(true);
            deleteFile(file).then(() => {
                const newFiles = [...getValues('waterFile')];
                setValue(
                    'waterFile',
                    newFiles.filter((item) => item !== file.toString()),
                );
                ctxt?.setLoaderIsActive(false);
            });
        },
        [getValues('waterFile')],
    );

    React.useEffect(() => {
        if (waterValues !== undefined) {
            reset(waterValues as any);
            waterValues?.waterText?.length && setShowTextField(true);
            waterValues?.waterFile && waterValues?.waterFile[0]?.length && setShowFileField(true);
        }
    }, [waterValues]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="survey-main">
                <p className="border-b border-black pb-1 mt-[100px]">Question 1</p>
                <h3>
                    Does the vendor facility use technology, machinery or processes that attributes
                    to water savings or efficient water usage?
                </h3>

                <Controller
                    control={control}
                    name="waterTextSelect"
                    render={({ field }) => (
                        <>
                            <DefaultButton
                                text="Yes"
                                onClick={() => {
                                    setValue('waterTextSelect', 'true');
                                    setShowTextField(true);
                                }}
                                styles={ISurveybuttonStyles}
                                className={`mr-4  ${
                                    field.value === 'true' ? 'bg-black text-white' : ''
                                }`}
                            />
                            <DefaultButton
                                text="No"
                                onClick={() => {
                                    setValue('waterTextSelect', 'false');
                                    setShowTextField(false);
                                    setValue('waterText', '');
                                }}
                                styles={ISurveybuttonStyles}
                                className={`mr-4  ${
                                    field.value === 'false' ? 'bg-black text-white' : ''
                                }`}
                            />
                            <span className="text-red-500">{errors.waterTextSelect?.message}</span>
                        </>
                    )}
                />
                {showTextField && (
                    <div className="border-l border-black pl-5 mt-[30px]">
                        <span className="subQuestion mb-5">
                            Please outline what technology, machinery or processes the facility uses
                        </span>
                        <>
                            <Controller
                                control={control}
                                name="waterText"
                                render={({ field }) => (
                                    <TextField
                                        multiline={true}
                                        onChange={field.onChange}
                                        resizable={false}
                                        rows={5}
                                        className="mt-4"
                                        value={field.value}
                                    />
                                )}
                            />
                            <span className="text-red-500">{errors.waterText?.message}</span>
                        </>
                    </div>
                )}
            </div>
            <div className="survey-main">
                <p className="border-b border-black pb-1 mt-[100px]">Question 2</p>
                <h3>Does the vendor facility have certification(s) with regards to water usage?</h3>

                <Controller
                    control={control}
                    name="waterFileSelect"
                    render={({ field }) => (
                        <>
                            <DefaultButton
                                text="Yes"
                                onClick={() => {
                                    setValue('waterFileSelect', 'true');
                                    setShowFileField(true);
                                }}
                                styles={ISurveybuttonStyles}
                                className={`mr-4  ${
                                    field.value === 'true' ? 'bg-black text-white' : ''
                                }`}
                            />
                            <DefaultButton
                                text="No"
                                onClick={() => {
                                    setValue('waterFileSelect', 'false');
                                    setShowFileField(false);
                                    setValue('waterFile', ['']);
                                }}
                                styles={ISurveybuttonStyles}
                                className={`mr-4  ${
                                    field.value === 'false' ? 'bg-black text-white' : ''
                                }`}
                            />
                            <span className="text-red-500">{errors.waterFileSelect?.message}</span>
                        </>
                    )}
                />
                {showFileField && (
                    <div className="border-l border-black pl-5 mt-[30px]">
                        <span className="subQuestion mb-5">
                            Please upload certifications as evidence (relevant certifications are
                            e.g., Bluedesign, Made in Green by OEKO-TEX, Nordic Ecolabel, Step by
                            OEKO-TEX - this list is non-exhaustive)
                        </span>
                        <Controller
                            control={control}
                            name="waterFile"
                            render={() => (
                                <VendorSurveyDropzone
                                    questionId={8}
                                    setValue={setValue}
                                    name="waterFile"
                                />
                            )}
                        />
                        {getValues('waterFile') && getValues('waterFile')[0]?.length > 0 && (
                            <div className="acceptedFile mt-10">
                                <h3 className="mb-5">Accepted files :</h3>
                                {getValues('waterFile')?.map((item: any) => (
                                    <div
                                        key={item}
                                        className="flex items-center p-4 py-2 border justify-between mb-2"
                                    >
                                        <p>{item.split('-')[6]}</p>
                                        <button
                                            type="button"
                                            onClick={() => {
                                                removeFile(item);
                                                setTest((prev) => !prev);
                                            }}
                                            className="px-5 py-3 border-l pr-0 text-2xl"
                                        >
                                            X
                                        </button>
                                    </div>
                                ))}
                            </div>
                        )}
                        <span className="text-red-500 mt-3">{errors.waterFile?.message}</span>
                    </div>
                )}
            </div>
            <ActionsButton handlePrevStep={handlePrevStep} activeStepId={activeStepId} />
        </form>
    );
};
