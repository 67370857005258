import { Box, Skeleton } from '@mui/material';
import React from 'react';

interface IProps {
    className?: string;
    itemCount: number;
    animation?: string;
}

export const SkeletonElement: React.FC<IProps> = ({ className, itemCount, animation }) => {
    const arr = Array(itemCount).fill(itemCount);
    return (
        <Box className={className}>
            {arr.map((_x) => (
                <Skeleton key={Math.random()} className="pt-4 pb-4" animation={animation as any} />
            ))}
        </Box>
    );
};
