import * as React from 'react';

import { IError } from '../store/models.ts/common';
import { Panel } from './panel';

interface IProps {
    /** Common error. */
    error: Partial<IError>;

    /** Validation error list. */
    validationErrors?: string[];

    /** dataTestId. */
    dataTestId?: string;
}

/**
 * Error panel - render panel with error message or list of panels with validation errors.
 */
export const ErrorPanel: React.FC<IProps> = (props) => {
    const { error } = props;

    return (
        <Panel>
            <p>{error?.code || error?.message || 'Unexpected error'}</p>
        </Panel>
    );
};

ErrorPanel.displayName = 'ErrorPanel';
