import { DirectionalHint, ITooltipHostStyles, TooltipDelay, TooltipHost } from '@fluentui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@fluentui/react-icons-mdl2';
import { Collapse, IconButton } from '@mui/material';
import useId from '@mui/material/utils/useId';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import moment from 'moment';
import React, { useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Panel } from '../../../../common/panel';
import { SkeletonElement } from '../../../../common/skeleton';
import { QUERY_KEYS } from '../../../../consts';
import { useOrderFiles } from '../../../../helpers/hooks/orders';
import { notifyRequestErr } from '../../../../helpers/utils';
import { LoaderContext } from '../../../../routes/router';
import { orderService } from '../../../../services/orderService';
import { IExeption } from '../../../../store/models.ts/common';
import { FileDropzone } from './dropzone';

interface IFilesProps {
    setFileData: React.Dispatch<
        React.SetStateAction<{
            orderId: string;
            fileName: string;
            fileNameToBeDisplayed: string;
            fileType: string;
        }>
    >;
    fileType: string;
    setIsConfirmModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Files = ({ setFileData, setIsConfirmModalOpen, fileType }: IFilesProps) => {
    const { id } = useParams();
    const queryClient = useQueryClient();
    const formData = new FormData();
    const [isOpen, setIsOpen] = React.useState(true);
    const asyncFiles: any = useOrderFiles(id!, fileType);
    const ctxt = useContext(LoaderContext);
    const tooltipId = useId('tooltip');

    const handleDownloadFile = (url: string) => {
        window.open(url, '_blank')?.focus();
    };

    const handleDeleteFile = () => {
        setIsConfirmModalOpen(false);
    };

    const hostStyles: Partial<ITooltipHostStyles> = { root: { display: 'inline-block' } };

    const { mutate } = useMutation(orderService.uploadFile, {
        onSuccess: async () => {
            ctxt?.setLoaderIsActive(false);

            toast.success('File succesfully added !', {
                position: toast.POSITION.BOTTOM_RIGHT,
            });

            formData.delete('Files');
            formData.delete('poDocId');
            formData.delete('fileType');
            queryClient.invalidateQueries([QUERY_KEYS.SHEETS]);
        },
        onError: async (err: { response: { data: IExeption } }) => {
            notifyRequestErr(err);
            ctxt?.setLoaderIsActive(false);
        },
    });

    const onFileUpload = useCallback(
        (formData: FormData) => {
            formData.append('fileType', fileType);
            mutate(formData);
        },
        [fileType],
    );

    return (
        <>
            {asyncFiles.isSuccess && !asyncFiles.data.hasOwnProperty('status') ? (
                <Panel>
                    <div className="p-4">
                        {asyncFiles.data.length ? (
                            <>
                                <p className="text-[16px]">
                                    <b>Last upload</b>
                                </p>
                                <div className="bg-file-bg flex items-center justify-between p-5 border border-color-[#E6E6E6] mt-3 mb-3 text-[16px]">
                                    <div className="left flex items-center">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="w-[35px] h-[35px] mr-5"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            strokeWidth={2}
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z"
                                            />
                                        </svg>
                                        <div className="file-info">
                                            <p className="file-info-text">
                                                <b className="text-black">
                                                    &quot;
                                                    {asyncFiles.data[0].fileNameToBeDisplayed}
                                                    &quot;
                                                </b>{' '}
                                                - by&nbsp;{asyncFiles.data[0].createdBy}
                                            </p>
                                            <p className="text-[#AFAFAF] mt-1">
                                                {moment(asyncFiles.data[0].createdOn).format(
                                                    'MM-DD-YYYY hh:mm:ss',
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="right flex items-center">
                                        <div
                                            className="download cursor-pointer mr-4"
                                            onClick={() =>
                                                handleDownloadFile(asyncFiles.data[0].uri)
                                            }
                                        >
                                            <TooltipHost
                                                content={'Download file'}
                                                delay={TooltipDelay.zero}
                                                id={tooltipId}
                                                directionalHint={DirectionalHint.topCenter}
                                                styles={hostStyles}
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="h-6 w-6"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="#777777"
                                                    strokeWidth={2}
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                                                    />
                                                </svg>
                                            </TooltipHost>
                                        </div>
                                        <div
                                            onClick={() => {
                                                handleDeleteFile();
                                                setFileData({
                                                    orderId: id!,
                                                    fileName: asyncFiles.data[0].fileName,
                                                    fileNameToBeDisplayed:
                                                        asyncFiles.data[0].fileNameToBeDisplayed,
                                                    fileType,
                                                });
                                            }}
                                            className="cursor-pointer"
                                        >
                                            <TooltipHost
                                                content={'Delete file'}
                                                delay={TooltipDelay.zero}
                                                id={tooltipId}
                                                directionalHint={DirectionalHint.topCenter}
                                                styles={hostStyles}
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="h-6 w-6"
                                                    viewBox="0 0 20 20"
                                                    fill="#777777"
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                                                        clipRule="evenodd"
                                                    />
                                                </svg>
                                            </TooltipHost>
                                        </div>
                                    </div>
                                </div>
                                {asyncFiles.data.length >= 2 && (
                                    <>
                                        <div className="flex items-center">
                                            <IconButton
                                                aria-label="expand row"
                                                size="small"
                                                onClick={() => setIsOpen(!isOpen)}
                                            >
                                                {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                                            </IconButton>
                                            <b className="text-[16px] ml-2">Historic documents</b>
                                        </div>
                                        <Collapse in={isOpen} timeout="auto" unmountOnExit>
                                            {asyncFiles.data.slice(1).map((file) => (
                                                <div key={file.fileName}>
                                                    <div className="bg-file-bg flex items-center justify-between p-5 border border-color-[#E6E6E6] mt-3 mb-3 text-[16px]">
                                                        <div className="left flex items-center">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                className="w-[35px] h-[35px] mr-5"
                                                                fill="none"
                                                                viewBox="0 0 24 24"
                                                                stroke="currentColor"
                                                                strokeWidth={2}
                                                            >
                                                                <path
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z"
                                                                />
                                                            </svg>
                                                            <div className="file-info">
                                                                <p className="file-info-text">
                                                                    <b className="text-black">
                                                                        &quot;
                                                                        {file.fileNameToBeDisplayed}
                                                                        &quot;
                                                                    </b>{' '}
                                                                    - by&nbsp;
                                                                    {file.createdBy}
                                                                </p>
                                                                <p className="text-[#AFAFAF] mt-1">
                                                                    {file.createdOn}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="right flex items-center">
                                                            <div
                                                                className="download cursor-pointer mr-4"
                                                                onClick={() =>
                                                                    handleDownloadFile(file.uri)
                                                                }
                                                            >
                                                                <TooltipHost
                                                                    content={'Download file'}
                                                                    delay={TooltipDelay.zero}
                                                                    id={tooltipId}
                                                                    directionalHint={
                                                                        DirectionalHint.topCenter
                                                                    }
                                                                    styles={hostStyles}
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        className="h-6 w-6"
                                                                        fill="none"
                                                                        viewBox="0 0 24 24"
                                                                        stroke="#777777"
                                                                        strokeWidth={2}
                                                                    >
                                                                        <path
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                            d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                                                                        />
                                                                    </svg>
                                                                </TooltipHost>
                                                            </div>
                                                            <div
                                                                onClick={() => {
                                                                    handleDeleteFile();
                                                                    setFileData({
                                                                        orderId: id!,
                                                                        fileName: file.fileName,
                                                                        fileNameToBeDisplayed:
                                                                            file.fileNameToBeDisplayed,
                                                                        fileType,
                                                                    });
                                                                }}
                                                                className="cursor-pointer"
                                                            >
                                                                <TooltipHost
                                                                    content={'Delete file'}
                                                                    delay={TooltipDelay.zero}
                                                                    id={tooltipId}
                                                                    directionalHint={
                                                                        DirectionalHint.topCenter
                                                                    }
                                                                    styles={hostStyles}
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        className="h-6 w-6"
                                                                        viewBox="0 0 20 20"
                                                                        fill="#777777"
                                                                    >
                                                                        <path
                                                                            fillRule="evenodd"
                                                                            d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                                                                            clipRule="evenodd"
                                                                        />
                                                                    </svg>
                                                                </TooltipHost>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </Collapse>
                                    </>
                                )}
                            </>
                        ) : (
                            <p className="mt-2 mb-4 bg-bg-gray p-4">
                                <b className="text-[18px]">No files found.</b>
                            </p>
                        )}
                    </div>
                    <FileDropzone onUpload={onFileUpload} formData={formData} />
                </Panel>
            ) : asyncFiles.isSuccess && asyncFiles.data.hasOwnProperty('status') ? (
                <>
                    <Panel className="pt-5 pb-5 pl-10 pr-10 mt-10 border-b-0">
                        <div className="head flex items-center">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5 mr-5"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M2 5a2 2 0 012-2h8a2 2 0 012 2v10a2 2 0 002 2H4a2 2 0 01-2-2V5zm3 1h6v4H5V6zm6 6H5v2h6v-2z"
                                    clipRule="evenodd"
                                />
                                <path d="M15 7h1a2 2 0 012 2v5.5a1.5 1.5 0 01-3 0V7z" />
                            </svg>
                            <p>Technical sheet</p>
                        </div>
                    </Panel>
                    <Panel>
                        <div className="p-4 border-red-600">
                            <p className="mt-2 mb-4 bg-bg-gray p-4">
                                <b className="text-[18px] text-red-600">
                                    {asyncFiles.data.data.message}
                                </b>
                            </p>
                        </div>
                    </Panel>
                </>
            ) : (
                <SkeletonElement itemCount={10} className="p-8 pt-11 pb-5 height-full" />
            )}
        </>
    );
};
