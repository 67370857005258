import React, { useEffect } from 'react';

import { ShippingAndTrackingProvider } from '../components/shippingAndTracking/context/shippingAndTracking';
import { Filter } from '../components/shippingAndTracking/filter';
import ShippingAndTrackingTable from '../components/shippingAndTracking/table';
import { useFilterSearchParams } from '../helpers/hooks/orders';

let isPreviousReloadSearchAfterInfo = true;
const ShippingAndTracking: React.FC = () => {
    const [searchParams, setSearchParams] = useFilterSearchParams();

    useEffect(() => {
        setSearchParams({
            ...searchParams,
            searchAfter: undefined,
        });
        isPreviousReloadSearchAfterInfo = false;
    }, []);

    if (searchParams.searchAfter && isPreviousReloadSearchAfterInfo) {
        return null;
    }
    return (
        <ShippingAndTrackingProvider>
            <Filter />
            <ShippingAndTrackingTable />
        </ShippingAndTrackingProvider>
    );
};

export default ShippingAndTracking;
