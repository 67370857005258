import React, { useEffect } from 'react';

import { OrderProvider } from '../components/order/context/orderContext';
import { Filter } from '../components/order/filter';
import { CollapsableProductionTable } from '../components/order/Table';
import { useFilterSearchParams } from '../helpers/hooks/orders';

let isPreviousReloadSearchAfterInfo = true;
export const ProductionFollowUp: React.FC = () => {
    const [searchParams, setSearchParams] = useFilterSearchParams();

    useEffect(() => {
        setSearchParams({
            ...searchParams,
            searchAfter: undefined,
        });
        isPreviousReloadSearchAfterInfo = false;
    }, []);

    if (searchParams.searchAfter && isPreviousReloadSearchAfterInfo) {
        return null;
    }

    return (
        <OrderProvider>
            <Filter />
            <CollapsableProductionTable />
        </OrderProvider>
    );
};
