import { DefaultButton } from '@fluentui/react';
import { UseMutateFunction } from '@tanstack/react-query';
import React, { useMemo } from 'react';
import { Container } from 'react-grid-system';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Panel } from '../../common/panel';
import { usePermission } from '../../helpers/hooks';
import { LoaderContext } from '../../routes/router';
import { IOrder } from '../../store/models.ts/common';
import { ICheckedValues } from '../../store/models.ts/ProductionStages';

interface IProps {
    order: IOrder;
    ackStatusMutate: UseMutateFunction<any, any, any, unknown>;
}

export const TopBar: React.FC<IProps> = ({ order, ackStatusMutate }) => {
    const [searchParams] = useSearchParams();
    const isMaster = useMemo(() => searchParams.get('isMaster'), [searchParams]);
    const ctxt = React.useContext(LoaderContext);
    const { showPublishOrdersPage } = usePermission();
    const [buttonText, setButtonText] = React.useState('Acknowledge');
    const navigate = useNavigate();

    const handleAcknowledgeStatus = (acknowledgeStatus: string) => {
        ctxt?.setLoaderIsActive(true);

        const data: ICheckedValues = {
            poDocIds: [order.poDocId] as string[],
            acknowledgementStatusId: acknowledgeStatus,
        };
        ackStatusMutate(data);
    };

    React.useEffect(() => {
        if (
            order.intAcknowledgementStatusId === null &&
            order.extAcknowledgementStatusId === null
        ) {
            setButtonText('Acknowledge');
        }
        if (showPublishOrdersPage && order.intAcknowledgementStatusId === 'R')
            setButtonText('Acknowledge');
        if (showPublishOrdersPage && order.intAcknowledgementStatusId === 'A')
            setButtonText('Refuse');
        if (!showPublishOrdersPage && order.extAcknowledgementStatusId === 'R')
            setButtonText('Acknowledge');
        if (!showPublishOrdersPage && order.extAcknowledgementStatusId === 'A')
            setButtonText('Refuse');
    }, [order.intAcknowledgementStatusId, order.extAcknowledgementStatusId]);

    return (
        <Panel className="shadow-md sticky top-[69px] bg-white z-index-full">
            <Container style={{ maxWidth: '97.5%' }} className="pl-0">
                <div className="flex items-center">
                    <p
                        className="mt-4 mb-6 text-txt-gray cursor-pointer"
                        onClick={() =>
                            navigate(
                                isMaster === 'false'
                                    ? '../shipping-and-tracking'
                                    : '../production-follow-up',
                            )
                        }
                    >
                        All orders /
                    </p>
                    {''}
                    <p className="mt-4 mb-6 text-txt-gray">
                        <b> Selected order</b>
                    </p>
                </div>
                <hr />
                <div className="bottom flex justify-between mt-5 mb-4">
                    <div className="orderId">
                        <p>Order ID</p>
                        <p>
                            <b className="text-[20px]">{order.orderIdToBeDisplayed}</b>
                        </p>
                    </div>
                    <div className="flex items-center">
                        <div className="left">
                            <p>Vendor acknowledgment</p>
                            {order.extAcknowledgementStatusId !== null ? (
                                <div className="flex items-center">
                                    {order.extAcknowledgementStatusId === 'A' ? (
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-6 w-6 text-hard-green"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            strokeWidth={2}
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M5.636 18.364a9 9 0 010-12.728m12.728 0a9 9 0 010 12.728m-9.9-2.829a5 5 0 010-7.07m7.072 0a5 5 0 010 7.07M13 12a1 1 0 11-2 0 1 1 0 012 0z"
                                            />
                                        </svg>
                                    ) : (
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-6 w-6 text-red-500"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            strokeWidth={2}
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M18.364 5.636a9 9 0 010 12.728m0 0l-2.829-2.829m2.829 2.829L21 21M15.536 8.464a5 5 0 010 7.072m0 0l-2.829-2.829m-4.243 2.829a4.978 4.978 0 01-1.414-2.83m-1.414 5.658a9 9 0 01-2.167-9.238m7.824 2.167a1 1 0 111.414 1.414m-1.414-1.414L3 3m8.293 8.293l1.414 1.414"
                                            />
                                        </svg>
                                    )}

                                    <p className="ml-2">
                                        <b>
                                            {order.extAcknowledgementStatusId === 'A'
                                                ? 'Acknowledged by Vendor'
                                                : 'Refused by Vendor'}
                                        </b>
                                    </p>
                                </div>
                            ) : (
                                <div className="flex items-center">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-6 w-6 text-hard-gray"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M18.364 5.636a9 9 0 010 12.728m0 0l-2.829-2.829m2.829 2.829L21 21M15.536 8.464a5 5 0 010 7.072m0 0l-2.829-2.829m-4.243 2.829a4.978 4.978 0 01-1.414-2.83m-1.414 5.658a9 9 0 01-2.167-9.238m7.824 2.167a1 1 0 111.414 1.414m-1.414-1.414L3 3m8.293 8.293l1.414 1.414"
                                        />
                                    </svg>

                                    <p className="ml-2">
                                        <b>Not acknowledged by Vendor</b>
                                    </p>
                                </div>
                            )}
                            {order.intAcknowledgementStatusId !== null ? (
                                <div className="flex items-center">
                                    {order.intAcknowledgementStatusId === 'A' ? (
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-6 w-6 text-hard-green"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            strokeWidth={2}
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M5.636 18.364a9 9 0 010-12.728m12.728 0a9 9 0 010 12.728m-9.9-2.829a5 5 0 010-7.07m7.072 0a5 5 0 010 7.07M13 12a1 1 0 11-2 0 1 1 0 012 0z"
                                            />
                                        </svg>
                                    ) : (
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-6 w-6 text-red-500"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            strokeWidth={2}
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M18.364 5.636a9 9 0 010 12.728m0 0l-2.829-2.829m2.829 2.829L21 21M15.536 8.464a5 5 0 010 7.072m0 0l-2.829-2.829m-4.243 2.829a4.978 4.978 0 01-1.414-2.83m-1.414 5.658a9 9 0 01-2.167-9.238m7.824 2.167a1 1 0 111.414 1.414m-1.414-1.414L3 3m8.293 8.293l1.414 1.414"
                                            />
                                        </svg>
                                    )}

                                    <p className="ml-2">
                                        <b>
                                            {order.intAcknowledgementStatusId === 'A'
                                                ? 'Acknowledged by Frame'
                                                : 'Refused by Frame'}
                                        </b>
                                    </p>
                                </div>
                            ) : (
                                <div className="flex items-center">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-6 w-6 text-hard-gray"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M18.364 5.636a9 9 0 010 12.728m0 0l-2.829-2.829m2.829 2.829L21 21M15.536 8.464a5 5 0 010 7.072m0 0l-2.829-2.829m-4.243 2.829a4.978 4.978 0 01-1.414-2.83m-1.414 5.658a9 9 0 01-2.167-9.238m7.824 2.167a1 1 0 111.414 1.414m-1.414-1.414L3 3m8.293 8.293l1.414 1.414"
                                        />
                                    </svg>

                                    <p className="ml-2">
                                        <b>Not acknowledged by Frame</b>
                                    </p>
                                </div>
                            )}
                        </div>
                        <DefaultButton
                            text={buttonText}
                            allowDisabledFocus
                            className="ml-5 bg-black text-white"
                            onClick={() =>
                                handleAcknowledgeStatus(buttonText === 'Acknowledge' ? 'A' : 'R')
                            }
                        />
                    </div>
                    <div className="dest">
                        <p>Arrival at destination</p>
                        <p>
                            <b>Not Available</b>
                        </p>
                    </div>
                </div>
            </Container>
        </Panel>
    );
};
