import 'draft-js/dist/Draft.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { useMsal } from '@azure/msal-react';
import { ActionButton } from '@fluentui/react';
import { motion } from 'framer-motion';
import moment from 'moment';
import React from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { useParams } from 'react-router-dom';
import Xarrow, { useXarrow } from 'react-xarrows';

import { Panel } from '../../../common/panel';
import { SkeletonElement } from '../../../common/skeleton';
import { useAsyncComments } from '../../../helpers/hooks/orders';
import TextEditor from './commentEditor';
import ReplyEditor from './replyEditor';

const variants = {
    open: { opacity: 1, x: 0 },
    closed: { opacity: 0, x: 0, display: 'none' },
};

const Comment: React.FC = () => {
    const { id } = useParams();
    const updateXarrow = useXarrow();
    const { accounts } = useMsal();
    const [currentComment, setCurrentComment] = React.useState(0);

    const [showReplies, setShowReplies] = React.useState(false);
    const [showReplyInput, setShowReplyInput] = React.useState(false);

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const asyncComments = useAsyncComments(id!);

    const handleToggleReplies = (id: number) => {
        setCurrentComment(id);
        setShowReplies((prev) => !prev);
    };

    const handleToggleInput = (id: number) => {
        setCurrentComment(id);
        setShowReplyInput((prev) => !prev);
    };

    React.useEffect(() => {
        const timer = setTimeout(() => {
            updateXarrow();
        }, 0);

        return () => clearTimeout(timer);
    }, [showReplies, showReplyInput, currentComment]);

    return (
        <>
            {asyncComments.isSuccess ? (
                <Panel className="pt-5 pb-5 pl-10 pr-10 mt-10 border-b-0">
                    <Container style={{ maxWidth: '97%' }} className="pl-0">
                        <div className="head flex items-center mt-10">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                            >
                                <path d="M2 5a2 2 0 012-2h7a2 2 0 012 2v4a2 2 0 01-2 2H9l-3 3v-3H4a2 2 0 01-2-2V5z" />
                                <path d="M15 7v2a4 4 0 01-4 4H9.828l-1.766 1.767c.28.149.599.233.938.233h2l3 3v-3h2a2 2 0 002-2V9a2 2 0 00-2-2h-1z" />
                            </svg>
                            <p className="ml-5">Comments</p>
                        </div>
                        <Row className="mt-10">
                            <Col md={1}>
                                <div className="square h-[110px] w-[110px] border text-center relative">
                                    <span className="text-[80px] center_hor_ver">
                                        {accounts[0]?.name?.charAt(0)}
                                    </span>
                                </div>
                            </Col>
                            <Col md={11}>
                                <TextEditor />
                            </Col>
                        </Row>
                        {asyncComments.data
                            .filter((cmnt) => cmnt.parentMessageId === null)
                            .map((comment) => (
                                <Row className="mt-10" key={comment.messageId}>
                                    <Col md={1} className="sqr-main">
                                        <div className="square h-[110px] w-[110px] border text-center relative">
                                            <span className="text-[80px] center_hor_ver">
                                                {comment.createdBy.charAt(0)}
                                            </span>
                                        </div>
                                        <div
                                            className="sd w-[1px] h-[5px] ml-[50px]"
                                            id={comment.messageId as any}
                                        ></div>
                                    </Col>
                                    <Col md={11}>
                                        <div className="flex items-center mb-2 mt-2">
                                            <span className="mr-4 text-[15px]">
                                                <b>{comment.createdBy}</b>
                                            </span>
                                            <span className="mr-4">
                                                <b>{comment.title}</b>
                                            </span>
                                            <span className="text-txt-gray">
                                                {moment(comment.createdOn).format(
                                                    'MM-DD-YYYY hh:mm:ss',
                                                )}
                                            </span>
                                        </div>
                                        <div
                                            className="border p-5"
                                            dangerouslySetInnerHTML={{
                                                __html: comment.content.toString(),
                                            }}
                                        />
                                        <div className="flex items-center mt-2">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="h-5 w-5"
                                                viewBox="0 0 20 20"
                                                fill="#707070"
                                            >
                                                <path d="M2 5a2 2 0 012-2h7a2 2 0 012 2v4a2 2 0 01-2 2H9l-3 3v-3H4a2 2 0 01-2-2V5z" />
                                                <path d="M15 7v2a4 4 0 01-4 4H9.828l-1.766 1.767c.28.149.599.233.938.233h2l3 3v-3h2a2 2 0 002-2V9a2 2 0 00-2-2h-1z" />
                                            </svg>
                                            <ActionButton
                                                className="text-txt-gray ml-2 p-0"
                                                onClick={() =>
                                                    handleToggleReplies(comment.messageId)
                                                }
                                            >
                                                Replies (
                                                {
                                                    asyncComments.data.filter(
                                                        (cmnt) =>
                                                            cmnt.parentMessageId ===
                                                            comment.messageId,
                                                    ).length
                                                }
                                                )
                                            </ActionButton>
                                            <span className="ml-3 mr-2">•</span>
                                            <ActionButton
                                                className="p-0"
                                                onClick={() => handleToggleInput(comment.messageId)}
                                            >
                                                Reply
                                            </ActionButton>
                                        </div>
                                        <motion.div
                                            animate={
                                                showReplyInput &&
                                                currentComment === comment.messageId
                                                    ? 'open'
                                                    : 'closed'
                                            }
                                            variants={variants}
                                        >
                                            <Col
                                                md={11.5}
                                                offset={{ md: 0.6 }}
                                                className="mt-5 relative"
                                            >
                                                <div
                                                    id={comment.createdOn}
                                                    className="absolute top-[60px] h-[1px] w-[5px]"
                                                ></div>
                                                <ReplyEditor
                                                    parentId={comment.messageId}
                                                    id={id}
                                                    setShowReplies={setShowReplies}
                                                />
                                            </Col>
                                        </motion.div>
                                        {showReplyInput && currentComment === comment.messageId && (
                                            <Xarrow
                                                start={comment.messageId.toString()}
                                                end={comment.createdOn}
                                                path="grid"
                                                showHead={false}
                                                dashness={true}
                                                strokeWidth={1}
                                                lineColor="#707070"
                                            />
                                        )}

                                        {asyncComments.data
                                            .filter(
                                                (cmnt) =>
                                                    cmnt.parentMessageId === comment.messageId,
                                            )
                                            .map((cm) => (
                                                <motion.div
                                                    animate={
                                                        showReplies &&
                                                        currentComment === comment.messageId
                                                            ? 'open'
                                                            : 'closed'
                                                    }
                                                    variants={variants}
                                                    className="mt-4"
                                                    key={cm.messageId}
                                                >
                                                    <Xarrow
                                                        start={comment.messageId.toString()} //can be react ref
                                                        end={cm.createdOn} //or an id
                                                        path="grid"
                                                        showHead={false}
                                                        dashness={true}
                                                        strokeWidth={1}
                                                        lineColor="#707070"
                                                    />
                                                    <Col md={11.5} offset={{ md: 0.6 }}>
                                                        <div className="flex items-center mb-2">
                                                            <span className="mr-4 text-[14px]">
                                                                <b>{cm.createdBy}</b>
                                                            </span>
                                                            <span className="mr-4">
                                                                <b>{cm.title}</b>
                                                            </span>
                                                            <span className="text-txt-gray">
                                                                {moment(cm.createdOn).format(
                                                                    'MM-DD-YYYY',
                                                                )}
                                                            </span>
                                                        </div>
                                                        <div
                                                            className="border p-5"
                                                            id={cm.createdOn as any}
                                                            dangerouslySetInnerHTML={{
                                                                __html: cm.content.toString(),
                                                            }}
                                                        />
                                                    </Col>
                                                </motion.div>
                                            ))}
                                    </Col>
                                </Row>
                            ))}
                    </Container>
                </Panel>
            ) : (
                <SkeletonElement itemCount={10} className="p-8 pt-11 pb-5 height-full" />
            )}
        </>
    );
};

export default Comment;
