import { Table, TableContainer } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import { Async, asyncRenderError } from '../../../common/async';
import { SkeletonElement } from '../../../common/skeleton';
import { QUERY_KEYS } from '../../../consts';
import { useOnScreen } from '../../../helpers/hooks';
import { useFilterSearchParams, useProductionFollowUpOrders } from '../../../helpers/hooks/orders';
import { notifyRequestErr } from '../../../helpers/utils';
import { LoaderContext } from '../../../routes/router';
import { orderService } from '../../../services/orderService';
import { getWashCareInstructions } from '../../../services/washCareInstructions';
import { IExeption, IOrderElems } from '../../../store/models.ts/common';
import { useOrderContext } from '../context/orderContext';
import { ActionBar } from './actionbar';
import { CollapsableProductionTableBody } from './body';
import { CollapsableProductionTableHeader } from './header';

let lastAsyncOrderData: IOrderElems[];
export const CollapsableProductionTable: React.FC = () => {
    const [filters, updateFilters] = useFilterSearchParams();
    const queryClient = useQueryClient();
    const {
        isLoadMoreData,
        setCheckedRows,
        setLoadMoreData,
        orders: contextOrders,
        setOrders: setContextOrders,
        setWashCareInstructionsOptions,
    } = useOrderContext();
    const ctxt = useContext(LoaderContext);
    const asyncOrders = useProductionFollowUpOrders(filters);
    const ref = useRef<HTMLDivElement>(null);
    const [isVisible, observe] = useOnScreen(ref);
    const [shouldCleanSelectBox, setShouldCleanSelectBox] = useState(false);
    const [stageId, setStageId] = useState<string | null>(null);
    const hasMoreItems = useMemo(() => {
        if (!asyncOrders.data || !asyncOrders.data.elements) return false;
        return asyncOrders.data.totalResults > contextOrders.length;
    }, [asyncOrders, contextOrders]);

    useEffect(() => () => queryClient.removeQueries([QUERY_KEYS.PRODUCTION_FOLLOW_UP_ORDER]), []);

    useEffect(() => {
        if (
            !asyncOrders.isSuccess ||
            !asyncOrders.data.elements ||
            lastAsyncOrderData === asyncOrders.data.elements
        )
            return;

        setContextOrders(
            isLoadMoreData
                ? [...contextOrders, ...asyncOrders.data.elements]
                : asyncOrders.data.elements,
        );
        lastAsyncOrderData = asyncOrders.data.elements;
        setLoadMoreData(null);
    }, [asyncOrders]);

    useEffect(() => {
        if (
            !isVisible ||
            !asyncOrders.data ||
            !asyncOrders.data.elements ||
            asyncOrders.isFetching ||
            !asyncOrders.isSuccess
        )
            return;

        updateFilters({
            ...filters,
            searchAfter: asyncOrders.data.elements.at(-1)?.sorts ?? [],
        });
        setLoadMoreData(true);
    }, [isVisible, asyncOrders]);

    useEffect(() => {
        getWashCareInstructions()
            .then((res) => {
                setWashCareInstructionsOptions(res.data);
            })
            .catch((err) => console.error(err));
    }, []);

    useEffect(() => {
        if (asyncOrders.isSuccess) observe();
    }, [asyncOrders]);

    const { mutate } = useMutation(orderService.assignProductionStatus, {
        onSuccess: async (data) => {
            await queryClient.cancelQueries([QUERY_KEYS.PRODUCTION_FOLLOW_UP_ORDER, filters]);
            setCheckedRows([]);
            ctxt?.setLoaderIsActive(false);
            toast.success('Stage succesfully changed !', {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
            setShouldCleanSelectBox(true);
            setStageId(null);

            const previousOrder: any = queryClient.getQueryData(
                [QUERY_KEYS.PRODUCTION_FOLLOW_UP_ORDER, filters],
                {
                    exact: false,
                },
            );

            for (let i = 0; i < data.length; i++) {
                const index = previousOrder?.elements.findIndex(
                    (el) => el.document.poDocId === data[i].poDocId,
                );

                previousOrder.elements[index].document = data[i];
            }
        },
        onError: async (err: { response: { data: IExeption } }) => {
            notifyRequestErr(err);
            ctxt?.setLoaderIsActive(false);
        },
    });

    return (
        <TableContainer>
            <Async
                branch={asyncOrders}
                renderSuccess={(res) => (
                    <div className="h-[calc(100vh-280px)] help overflow-x-auto">
                        <ActionBar
                            mutate={mutate}
                            stageId={stageId}
                            setStageId={setStageId}
                            totalElementCount={res.totalResults}
                            shouldCleanSelectBox={shouldCleanSelectBox}
                            setShouldCleanSelectBox={setShouldCleanSelectBox}
                        />
                        <Table
                            className="production_follow_table w-full relative"
                            stickyHeader
                            style={{ tableLayout: 'auto' }}
                        >
                            <CollapsableProductionTableHeader />
                            <CollapsableProductionTableBody mutateStatuses={mutate} />
                            {!asyncOrders.isRefetching &&
                                hasMoreItems &&
                                contextOrders.length !== 0 && (
                                    <div className="w-full h-6 sticky left-0" ref={ref}></div>
                                )}
                        </Table>
                        {asyncOrders.isFetching && (
                            <SkeletonElement
                                itemCount={2}
                                animation={'wave'}
                                className="p-8 pt-1 pb-5 w-full sticky left-0"
                            />
                        )}
                    </div>
                )}
                renderEmpty={<p>Empty</p>}
                renderError={asyncRenderError}
                renderLoading={
                    <SkeletonElement itemCount={14} className="p-8 pt-11 pb-5 height-full" />
                }
            />
        </TableContainer>
    );
};
