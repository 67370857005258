import {
    DirectionalHint,
    getTheme,
    IButtonStyles,
    IIconProps,
    TooltipDelay,
} from '@fluentui/react';

export const cancelIcon: IIconProps = { iconName: 'Cancel' };

export const theme = getTheme();

export const tooltipConfiguration = {
    hintDirection: DirectionalHint.topCenter,
    styles: { root: { display: 'inline-block' } },
    delay: TooltipDelay.long,
    content: 'Select if not applicable',
};

export const infoButtonStyles: Partial<IButtonStyles> = {
    root: {
        color: theme.palette.blueDark,
        marginLeft: '15px',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
    icon: {
        fontSize: '20px',
        fontWeight: 'bold',
    },
};

export enum EModalFieldType {
    STRING = 'string',
    DATETIME = 'datetime',
    BOOL_NULL = 'boolnull',
    BOOL_NULL_ONLY_TRUE = 'boolnullonlytrue',
    DROPDOWN_LOGISTICS = 'dropdown-logistics',
    DROPDOWN_WASHCARE = 'dropdown-washcare',
    DOWNLOAD_FILE = 'downloadfile',
}
