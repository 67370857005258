import { TextField, TooltipHost } from '@fluentui/react';
import {
    Autocomplete,
    Checkbox,
    FormControlLabel,
    Radio,
    TextField as MuiTextField,
} from '@mui/material';
import React, { useCallback } from 'react';
import {
    Control,
    Controller,
    FieldValues,
    UseFormGetValues,
    UseFormSetValue,
} from 'react-hook-form';
import { toast } from 'react-toastify';

import { ControlledDatePicker } from '../../../common/controledDatePicker';
import { usePermission } from '../../../helpers/hooks';
import { notifyRequestErr } from '../../../helpers/utils';
import { orderService } from '../../../services/orderService';
import { IPreproductionStageDetail } from '../../../store/models.ts/ProductionFollowUp';
import { cn, downloadBase64DataAsFile } from '../../../utilities';
import { useOrderContext } from '../context/orderContext';
import { EModalFieldType, tooltipConfiguration } from './consts';

interface EditModalFieldsProps {
    setEditedFields: React.Dispatch<React.SetStateAction<string[]>>;
    categoryGroup: IPreproductionStageDetail[];
    getValues: UseFormGetValues<FieldValues>;
    setValue: UseFormSetValue<FieldValues>;
    control: Control<FieldValues, any>;
    editedFields: string[];
    subCategoryId: number;
}

const EditModalFields = ({
    setEditedFields,
    categoryGroup,
    subCategoryId,
    editedFields,
    getValues,
    setValue,
    control,
}: EditModalFieldsProps) => {
    const { allowToManagePreprodStages } = usePermission();
    const { orders, checkedRows } = useOrderContext();
    return (
        <>
            {categoryGroup.map((field, idx) => {
                if (field.visibleIf) {
                    const [dependentStageId, operation, result] = field.visibleIf.split('-');
                    const preProductionStage = orders[0].document.orderPreProductionStages.find(
                        (s) => s.stageId === Number(dependentStageId),
                    );
                    let isVisible = true;
                    if (operation === 'equals') {
                        isVisible =
                            getValues(
                                preProductionStage?.description
                                    .replace(/ /g, '')
                                    .replace(/-/g, '') ?? '',
                            ) === result;
                    }
                    if (!isVisible) return;
                }

                if (idx === 0) subCategoryId = 1;
                const name = field.description.replace(/ /g, '').replace(/-/g, '');
                let fieldComponent: JSX.Element = <></>;
                let hasLine = false;
                if (categoryGroup.length > idx + 1) {
                    hasLine = categoryGroup[idx + 1].subCategoryId !== subCategoryId;
                    subCategoryId = categoryGroup[idx + 1].subCategoryId;
                }

                switch (field.fieldType) {
                    case EModalFieldType.DATETIME:
                        fieldComponent = (
                            <ControlledDatePicker
                                label={field.description}
                                control={control}
                                name={name}
                                placeholder={field.placeHolder}
                                setEditedFields={setEditedFields}
                                className={`${editedFields.includes(name) && 'bg-[#bae1faa9]'} `}
                                disabled={!field.editable || getValues(`${name}NA`)}
                            />
                        );
                        break;
                    case EModalFieldType.STRING:
                        fieldComponent = (
                            <Controller
                                control={control}
                                name={name}
                                render={({ field: { value, onChange } }) => (
                                    <TextField
                                        label={field.description}
                                        type="text"
                                        value={value}
                                        onChange={(...event) => {
                                            setEditedFields?.((prev) => [...prev, name]);
                                            onChange(...event);
                                        }}
                                        placeholder={field.placeHolder}
                                        className={`${
                                            editedFields.includes(name) && 'bg-[#bae1faa9]'
                                        } `}
                                        disabled={!field.editable || getValues(`${name}NA`)}
                                    />
                                )}
                            />
                        );
                        break;
                    case EModalFieldType.BOOL_NULL_ONLY_TRUE:
                    case EModalFieldType.BOOL_NULL:
                        fieldComponent = (
                            <Controller
                                control={control}
                                name={name}
                                render={({ field: { value } }) => (
                                    <>
                                        <div className="flex justify-between">
                                            <label
                                                className={cn(
                                                    'font-bold',
                                                    editedFields.includes(name) && 'bg-[#bae1faa9]',
                                                )}
                                            >
                                                {field.description}
                                            </label>
                                            <button
                                                type="button"
                                                disabled={!field.editable || getValues(`${name}NA`)}
                                                className="-mr-8 hover:underline disabled:hover:no-underline disabled:opacity-60"
                                                onClick={() => {
                                                    setValue(name, null);
                                                    setEditedFields?.((prev) => [...prev, name]);
                                                }}
                                            >
                                                Clear Choice
                                            </button>
                                        </div>
                                        <div className="flex translate-y-1 text-xs">
                                            <FormControlLabel
                                                disabled={!field.editable || getValues(`${name}NA`)}
                                                value="true"
                                                control={<Radio size="small" />}
                                                checked={value === 'true'}
                                                onChange={() => {
                                                    setValue(name, 'true');
                                                    setEditedFields?.((prev) => [...prev, name]);
                                                }}
                                                label={<span className="text-sm">Approved</span>}
                                            />
                                            {field.fieldType !==
                                                EModalFieldType.BOOL_NULL_ONLY_TRUE && (
                                                <FormControlLabel
                                                    value="false"
                                                    disabled={
                                                        !field.editable || getValues(`${name}NA`)
                                                    }
                                                    control={<Radio size="small" />}
                                                    checked={value === 'false'}
                                                    onChange={() => {
                                                        setValue(name, 'false');
                                                        setEditedFields?.((prev) => [
                                                            ...prev,
                                                            name,
                                                        ]);
                                                    }}
                                                    label={
                                                        <span className="text-sm">Rejected</span>
                                                    }
                                                />
                                            )}
                                        </div>
                                    </>
                                )}
                            />
                        );
                        break;
                    case EModalFieldType.DROPDOWN_LOGISTICS:
                    case EModalFieldType.DROPDOWN_WASHCARE:
                        fieldComponent = (
                            <Controller
                                control={control}
                                name={name}
                                render={({ field: { value, onChange } }) => {
                                    const options =
                                        (field.possibleValues?.map((value) => ({
                                            key: value.id,
                                            label: value.desc.substring(0, 20),
                                        })) as any) ?? [];
                                    return (
                                        <>
                                            <p
                                                className={`${
                                                    editedFields.includes(name) && 'bg-[#bae1faa9]'
                                                } font-semibold py-2 -mb-2`}
                                            >
                                                {field.description}
                                            </p>
                                            <Autocomplete
                                                disabled={!field.editable || getValues(`${name}NA`)}
                                                disablePortal
                                                id="combo-box-demo"
                                                options={options}
                                                size="small"
                                                value={options.find((o) => o?.key === value)}
                                                onChange={(_, newValue: any) => {
                                                    onChange({
                                                        target: {
                                                            value: newValue?.key || '',
                                                        },
                                                    });
                                                    setEditedFields?.((prev) => [...prev, name]);
                                                }}
                                                isOptionEqualToValue={(option, val) =>
                                                    option?.key === val?.key
                                                }
                                                getOptionLabel={(option) => option.label}
                                                renderInput={(params) => (
                                                    <MuiTextField
                                                        {...params}
                                                        sx={{
                                                            borderColor: '#e6e6e6!important',
                                                            letterSpacing: '0.15px!important',
                                                            '& .MuiFormLabel-root': {
                                                                fontFamily: 'Work Sans',
                                                                color: '#000000!important',
                                                            },
                                                            '& .MuiInput-root:before': {
                                                                border: 'none!important',
                                                            },
                                                            '& .MuiInput-root:hover:before': {
                                                                border: 'none!important',
                                                            },
                                                            '& .MuiInput-root:after': {
                                                                border: 'none!important',
                                                            },
                                                        }}
                                                        placeholder={field.placeHolder}
                                                    />
                                                )}
                                                sx={{
                                                    bgcolor: 'white',
                                                }}
                                            />
                                        </>
                                    );
                                }}
                            />
                        );
                        break;
                    case EModalFieldType.DOWNLOAD_FILE:
                        let order;
                        if (checkedRows.length === 1) {
                            order = orders.find((o) => o.document.poDocId === checkedRows[0]);
                        }

                        const orderPreProductionStageValue =
                            order?.document.orderPreProductionStages.find(
                                (opps) => opps.stageId === field.stageId,
                            );

                        const onFileDownload = useCallback(() => {
                            orderService
                                .downloadBase64File(
                                    orderPreProductionStageValue.getBase64Url.substring(1),
                                )
                                .then((res) => downloadBase64DataAsFile(res.data, res.fileName))
                                .then(() => {
                                    toast.success('File successfully downloaded!', {
                                        position: toast.POSITION.TOP_CENTER,
                                    });
                                })
                                .catch((err) => {
                                    notifyRequestErr(err);
                                });
                        }, [orderPreProductionStageValue]);

                        return (
                            orderPreProductionStageValue?.reportValue && (
                                <div className="flex flex-col gap-y-2 mb-5">
                                    <label className="font-bold">
                                        {orderPreProductionStageValue?.description}
                                    </label>
                                    <button
                                        type="button"
                                        onClick={onFileDownload}
                                        className="whitespace-pre truncate max-w-[240px] text-[14px] hover:underline w-fit"
                                    >
                                        {orderPreProductionStageValue?.reportValue}
                                    </button>
                                </div>
                            )
                        );
                }

                return (
                    <div className="relative mb-5">
                        {fieldComponent}
                        <Controller
                            control={control}
                            name={`${name}NA`}
                            render={({ field: { value, onChange } }) => (
                                <div
                                    className={`absolute -right-[42px] ${
                                        hasLine ? 'bottom-2' : '-bottom-1'
                                    }`}
                                >
                                    <TooltipHost
                                        content={tooltipConfiguration.content}
                                        directionalHint={tooltipConfiguration.hintDirection}
                                        delay={tooltipConfiguration.delay}
                                        styles={tooltipConfiguration.styles}
                                    >
                                        <Checkbox
                                            value={value}
                                            onChange={(...event) => {
                                                setEditedFields?.((prev) => [...prev, `${name}NA`]);
                                                onChange(...event);
                                            }}
                                            style={{
                                                backgroundColor: editedFields.includes(`${name}NA`)
                                                    ? '#bae1faa9'
                                                    : 'initial',
                                            }}
                                            checked={value}
                                            disabled={!allowToManagePreprodStages}
                                        />
                                    </TooltipHost>
                                </div>
                            )}
                        />
                        {hasLine && (
                            <div className="w-[calc(100%+30px)] my-3 border-b-[1.5px] border-hard-gray"></div>
                        )}
                    </div>
                );
            })}
        </>
    );
};

export default EditModalFields;
