import React from 'react';
import { Col, Container } from 'react-grid-system';

import surveyData from '../components/survey/survey.json';
import { SurveySidebar } from '../components/survey/surveySidebar';
import { SurveyForm } from '../components/vendorSurvey/surveyForm';

export const Survey: React.FC = () => {
    const [activeStepId, setActiveStepId] = React.useState<number[]>([1, 2]);
    const [activeQuestions, setActiveQuestions] = React.useState<any>();

    const handleGetActiveQuestions = React.useCallback(() => {
        const actvQuestion = surveyData.sections.filter(
            (item) =>
                activeStepId[0] === item.parentSectionId && activeStepId[1] === item.sectionId,
        );
        setActiveQuestions(actvQuestion);
    }, [activeQuestions, activeStepId]);

    React.useEffect(() => {
        handleGetActiveQuestions();
    }, [activeStepId]);

    return (
        <>
            <Container style={{ maxWidth: '97.5%' }} className="flex">
                <Col md={2.6}>
                    <SurveySidebar surveyData={surveyData} active={activeStepId} />
                </Col>
                <Col md={9} className="p-[50px] mt-5">
                    {activeQuestions && (
                        <SurveyForm setActiveStepId={setActiveStepId} activeStepId={activeStepId} />
                    )}
                </Col>
            </Container>
        </>
    );
};
