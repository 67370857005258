import { Spinner, SpinnerSize } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { ROUTES } from '../../consts';
import { notifyRequestErr } from '../../helpers/utils';
import { packingListService } from '../../services/packingList';
import { usePackingListDetailContext } from './context';
import PackingListDetailHeader from './header';
import PackingListPackage from './tables/packages';
import PackingListPurchaseOrders from './tables/purchase-orders';
import PackingWarnings from './warning';

const PackingListDetail = () => {
    const [loading, setLoading] = useState(true);
    const {
        setPackingList,
        setPurchaseOrders,
        setAvailablePurchaseOrders,
        setPackageData,
        purchaseOrders,
        packingList,
    } = usePackingListDetailContext();
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();

    useEffect(() => {
        (() => {
            const packingListDetailPromise = packingListService.getPackingLists({
                packinglistId: parseInt(id ?? ''),
                pageIndex: 1,
                pageSize: 1,
            });
            const packingListPurchaseOrdersPromise = packingListService.getPurchaseOrders({
                packinglistid: parseInt(id ?? ''),
            });
            const packingListAvailablePurchaseOrdersPromise =
                packingListService.getAvailablePurchaseOrders({
                    packinglistid: parseInt(id ?? ''),
                });
            const packingListPackagesPromise = packingListService.getPackages({
                packingListId: parseInt(id ?? ''),
            });

            Promise.all([
                packingListDetailPromise,
                packingListPurchaseOrdersPromise,
                packingListAvailablePurchaseOrdersPromise,
                packingListPackagesPromise,
            ])
                .then((data) => {
                    const [
                        packingListData,
                        purchaseOrdersData,
                        availablePurchaseOrdersData,
                        packageData,
                    ] = data;

                    const packingList = packingListData?.elements?.[0];
                    if (!packingList) {
                        toast.error('Packing list not found');
                        return navigate(`/${ROUTES.PACKING_LIST}`);
                    }
                    setPackingList(packingList);
                    setPurchaseOrders(purchaseOrdersData ?? []);
                    setAvailablePurchaseOrders(availablePurchaseOrdersData ?? []);
                    setPackageData(packageData);
                    setLoading(false);
                })
                .catch((err) => {
                    notifyRequestErr(err);
                    return navigate(`/${ROUTES.PACKING_LIST}`);
                });
        })();
    }, []);

    const handlePurchaseOrderRemove = async (podocid: string, size: string) => {
        return await packingListService
            .removePurchaseOrder({
                packinglistid: parseInt(id ?? ''),
                podocid,
                size,
            })
            .then(() => {
                toast.success('Purchase order removed successfully');
                const filteredOrders = purchaseOrders.filter(
                    (order) => order.poDocId !== podocid || order.size !== size,
                );
                setPurchaseOrders(filteredOrders);
            })
            .catch((err) => {
                notifyRequestErr(err);
            });
    };

    if (loading) {
        return (
            <div className="mt-[40vh] flex flex-col items-center gap-y-4 text-xl animate-pulse">
                <Spinner size={SpinnerSize.large} />
                Loading...
            </div>
        );
    }

    if (!packingList) return null;

    return (
        <div className="p-8 flex flex-col gap-y-6">
            <PackingListDetailHeader />
            <PackingWarnings />
            <PackingListPurchaseOrders onPurchaseOrderRemove={handlePurchaseOrderRemove} />
            <PackingListPackage />
        </div>
    );
};

export default PackingListDetail;
