import * as React from 'react';

import { useExpose } from '../../../helpers/hooks';
import {
    IPackingList,
    IPackingListAvailablePurchaseOrder,
    IPackingListPackage,
    IPackingListPackageData,
    IPackingListPurchaseOrder,
} from '../../../store/models.ts/common';
import CreatePackingListDetailModal from '../addPurchaseOrderModal';
import PackageModal from '../packageModal';

type Tcontext = {
    isPackageModalOpen: boolean;
    openPackageModal: () => void;
    closePackageModal: () => void;
    packageData: IPackingListPackageData | null;
    packingList: IPackingList | null;
    isAddPurchaseOrderModalOpen: boolean;
    openAddPurchaseOrderModal: () => void;
    closeAddPurchaseOrderModal: () => void;
    editedPackage: IPackingListPackage | null;
    purchaseOrders: IPackingListPurchaseOrder[];
    availablePurchaseOrders: IPackingListAvailablePurchaseOrder[];
    onPackageEdit: (pckg: IPackingListPackage) => void;
    setPackageData: React.Dispatch<React.SetStateAction<IPackingListPackageData | null>>;
    setPackingList: React.Dispatch<React.SetStateAction<IPackingList | null>>;
    setPurchaseOrders: React.Dispatch<React.SetStateAction<IPackingListPurchaseOrder[]>>;
    setAvailablePurchaseOrders: React.Dispatch<
        React.SetStateAction<IPackingListAvailablePurchaseOrder[]>
    >;
};

const PackingListDetailContext = React.createContext<Tcontext>(null as any);

function PackingListDetailProvider({ children }) {
    const [packingList, setPackingList] = React.useState<IPackingList | null>(null);
    const [purchaseOrders, setPurchaseOrders] = React.useState<IPackingListPurchaseOrder[]>([]);
    const [availablePurchaseOrders, setAvailablePurchaseOrders] = React.useState<
        IPackingListAvailablePurchaseOrder[]
    >([]);
    const [packageData, setPackageData] = React.useState<IPackingListPackageData | null>(null);
    const [editedPackage, setEditedPackage] = React.useState<IPackingListPackage | null>(null);
    const {
        close: closeAddPurchaseOrderModal,
        open: openAddPurchaseOrderModal,
        isOpen: isAddPurchaseOrderModalOpen,
    } = useExpose();
    const {
        close: onClosePackageModal,
        open: openPackageModal,
        isOpen: isPackageModalOpen,
    } = useExpose();

    const closePackageModal = () => {
        onClosePackageModal();
        setEditedPackage(null);
    };

    const onPackageEdit = (pckg: IPackingListPackage) => {
        setEditedPackage(pckg);
        openPackageModal();
    };

    const value = {
        packageData,
        packingList,
        setPackageData,
        onPackageEdit,
        editedPackage,
        setPackingList,
        purchaseOrders,
        availablePurchaseOrders,
        openPackageModal,
        closePackageModal,
        setPurchaseOrders,
        setAvailablePurchaseOrders,
        isPackageModalOpen,
        openAddPurchaseOrderModal,
        closeAddPurchaseOrderModal,
        isAddPurchaseOrderModalOpen,
    };

    return (
        <PackingListDetailContext.Provider value={value}>
            {children}
            <CreatePackingListDetailModal />
            <PackageModal />
        </PackingListDetailContext.Provider>
    );
}

function usePackingListDetailContext() {
    return React.useContext(PackingListDetailContext);
}

export { PackingListDetailProvider, usePackingListDetailContext };
