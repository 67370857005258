import React from 'react';

interface IProps {
    text: string;
    className?: string;
}

export const EmptyPanel: React.FC<IProps> = ({ text, className }) => {
    return (
        <div className={`nothing flex items-center justify-center flex-col w-full ${className}`}>
            <div className="icon">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-[60px] w-[60px] text-txt-gray"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                </svg>
            </div>
            <div className="text pt-3 text-lg font-bold text-txt-gray">
                <p>{text}</p>
            </div>
        </div>
    );
};
