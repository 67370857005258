import { IconButton, Modal, Spinner, SpinnerSize, TextField } from '@fluentui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { iconButtonStyles, modalContentStyles } from '../../../consts';
import { notifyRequestErr } from '../../../helpers/utils';
import { ReactComponent as LayersIcon } from '../../../images/icons/layers.svg';
import { packingListService } from '../../../services/packingList';
import { usePackingListDetailContext } from '../context';
import TooltipIcon from '../../../common/tooltipIcon';

const validationSchema = yup.object({
    purchaseOrders: yup.array(
        yup.object({
            packedQty: yup.number().required().min(0),
            poDocId: yup.string().required(),
            size: yup.string().required(),
        }),
    ),
});

const PackageModal = () => {
    const {
        packageData,
        setPackageData,
        packingList,
        editedPackage,
        purchaseOrders,
        closePackageModal,
        isPackageModalOpen,
    } = usePackingListDetailContext();
    const [loading, setLoading] = useState(false);
    const isEditModal = !!editedPackage;
    const packedPurchaseOrderDetails = packageData?.packedPurchaseOrderDetails ?? [];

    const form = useForm<yup.InferType<typeof validationSchema>>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            purchaseOrders: [],
        },
    });

    useEffect(() => {
        if (isPackageModalOpen)
            form.reset({
                purchaseOrders: purchaseOrders.map((purchaseOrder) => {
                    const packageContent = editedPackage?.packageContents.find(
                        (pc) =>
                            pc.poDocId === purchaseOrder.poDocId && pc.size === purchaseOrder.size,
                    );
                    return {
                        packedQty: packageContent?.packedQty ?? 0,
                        poDocId: purchaseOrder.poDocId,
                        size: purchaseOrder.size,
                    };
                }),
            });
    }, [isPackageModalOpen]);

    const onSubmit = (values: yup.InferType<typeof validationSchema>) => {
        setLoading(true);
        let promise;

        if (!isEditModal) {
            promise = packingListService
                .createPackage({
                    packingListId: packingList?.packingListId ?? 0,
                    packageContents: values.purchaseOrders ?? [],
                })
                .then((data) => {
                    setPackageData(data);
                    toast.success('Carton created successfully!');
                    closePackageModal();
                });
        } else {
            promise = packingListService
                .updatePackage({
                    packageId: editedPackage.packageId,
                    packingListId: packingList?.packingListId ?? 0,
                    packageContents: values.purchaseOrders ?? [],
                })
                .then((data) => {
                    setPackageData(data);
                    toast.success('Carton updated successfully!');
                    closePackageModal();
                });
        }
        promise
            .catch((err) => notifyRequestErr(err))
            .finally(() => {
                setLoading(false);
            });
    };

    const findPackedDetails = (poDocId: string, size: string) => {
        const packedPO = packedPurchaseOrderDetails.find((p) => p.poDocId === poDocId);
        if (!packedPO) return null;

        const packedSku = packedPO.packedSkus.find((sku) => sku.size === size);
        return packedSku || null;
    };

    return (
        <Modal
            titleAriaId={'dd'}
            isOpen={isPackageModalOpen}
            onDismiss={closePackageModal}
            isBlocking={false}
            containerClassName={
                modalContentStyles.container +
                'w-[80%] max-w-[1200px] min-w-[400px] h-fit min-h-[360px] overflow-visible'
            }
            className="edit-pre-modal"
        >
            <div className={modalContentStyles.header}>
                <b id="dsd" className="text-black bold head flex items-center gap-x-4 ">
                    <LayersIcon />
                    {editedPackage ? 'Update Carton' : 'New Carton'}
                </b>
                <IconButton
                    styles={iconButtonStyles}
                    iconProps={{ iconName: 'Cancel' }}
                    ariaLabel="Close popup modal"
                    onClick={closePackageModal}
                />
            </div>
            <form onSubmit={form.handleSubmit(onSubmit)} className="mt-6">
                <div className="px-6">
                    <Table className="border-none rounded-lg">
                        <TableHead className="bg-bg-gray py-10 z-20">
                            <TableRow>
                                <TableCell className="border-none">PO</TableCell>
                                <TableCell className="border-none">PO Date</TableCell>
                                <TableCell className="border-none">Item</TableCell>
                                <TableCell className="border-none">Color</TableCell>
                                <TableCell className="border-none">Size</TableCell>
                                <TableCell className="border-none">Order Qty.</TableCell>
                                <TableCell className="border-none">
                                    <span>Remaining Qty.</span>
                                    <TooltipIcon
                                        title="Total remaining item quantity is calculated based on all
                                            confirmed packing lists including current one."
                                    />
                                </TableCell>
                                <TableCell className="border-none">Units</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {purchaseOrders.map((purchaseOrder, index) => {
                                const {
                                    poDocId,
                                    size,
                                    poIdToBeDisplayed,
                                    poDate,
                                    style,
                                    color,
                                    orderQty,
                                } = purchaseOrder;
                                const packedQty =
                                    form.watch('purchaseOrders')?.[index]?.packedQty ?? 0;
                                const { orderQuantity, packedSkuDetails, totalRemainingQuantity } =
                                    findPackedDetails(poDocId, size) ?? {};
                                return (
                                    <TableRow key={`${poDocId}-${size}`} className="group">
                                        <TableCell className="group-last:border-none">
                                            {poIdToBeDisplayed}
                                        </TableCell>
                                        <TableCell className="group-last:border-none">
                                            {moment(poDate).format('DD MMM YYYY')}
                                        </TableCell>
                                        <TableCell className="group-last:border-none">
                                            {style}
                                        </TableCell>
                                        <TableCell className="group-last:border-none">
                                            {color}
                                        </TableCell>
                                        <TableCell className="group-last:border-none">
                                            {size}
                                        </TableCell>
                                        <TableCell className="group-last:border-none">
                                            {orderQty}
                                        </TableCell>
                                        <TableCell className="group-last:border-none">
                                            {totalRemainingQuantity}
                                        </TableCell>
                                        <TableCell
                                            className={`group-last:border-none ${
                                                packedQty > orderQty && 'bg-[#ffcc00]'
                                            }`}
                                        >
                                            <Controller
                                                control={form.control}
                                                name={`purchaseOrders.${index}.packedQty`}
                                                render={({ field }) => (
                                                    <TextField
                                                        value={field.value + ''}
                                                        type="number"
                                                        onChange={field.onChange}
                                                        placeholder="Count"
                                                        className="w-full"
                                                        styles={{
                                                            fieldGroup: {
                                                                borderColor: form.formState.errors
                                                                    .purchaseOrders?.[index]
                                                                    ? '#f43f5e'
                                                                    : '#D0D5DD',
                                                                outline: 'none',
                                                                height: 30,
                                                            },
                                                        }}
                                                    />
                                                )}
                                            />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </div>
                <div
                    className={
                        modalContentStyles.footer +
                        ' border-t border-gray-200 flex items-end justify-end gap-x-4 mt-20'
                    }
                >
                    <button
                        disabled={loading}
                        type="button"
                        onClick={closePackageModal}
                        className="bg-white px-4 py-2.5 flex gap-x-2 border border-gray-300 hover:bg-gray-100/70 transition disabled:opacity-50"
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        disabled={loading}
                        className="bg-black text-white px-4 py-2.5 flex gap-x-2 hover:opacity-80 disabled:opacity-50 transition"
                    >
                        {loading && <Spinner size={SpinnerSize.medium} className="mr-1" />}
                        Save
                    </button>
                </div>
            </form>
        </Modal>
    );
};

export default PackageModal;
