import { DefaultButton, Dialog, DialogFooter, TextField } from '@fluentui/react';
import { useMutation } from '@tanstack/react-query';
import React from 'react';
import { Col, Row } from 'react-grid-system';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { notifyRequestErr } from '../../helpers/utils';
import { LoaderContext } from '../../routes/router';
import { orderService } from '../../services/orderService';
import { IOrder } from '../../store/models.ts/common';

interface IProps {
    isModalOpen: boolean;
    setModalState: (val: boolean) => void;
    order: IOrder;
}

export const EditDetailForm: React.FC<IProps> = ({ isModalOpen, setModalState, order }) => {
    const [sizeValues, setSizeValues] = React.useState<[] | any>([]);
    const ctxt = React.useContext(LoaderContext);

    const { control, handleSubmit } = useForm({
        reValidateMode: 'onChange',
    });

    const { mutate } = useMutation(orderService.editOrderInfo, {
        onSuccess: async (data) => {
            ctxt?.setLoaderIsActive(false);
            if (data && data[0]) {
                order.sizeGrid = data[0].sizeGrid;
                order.totalShippedQuantity = data[0].totalShippedQuantity;
            }
            toast.success('Order information edited', {
                position: toast.POSITION.TOP_CENTER,
            });
            setModalState(false);
        },
        onError: async (err) => {
            notifyRequestErr(err);
            ctxt?.setLoaderIsActive(false);
        },
    });

    const onSubmit = () => {
        ctxt?.setLoaderIsActive(true);
        const grid = sizeValues.map((value: any) => {
            return { shippedQuantity: value.count, size: value.name };
        });
        const dto = {
            poDocId: order.poDocId,
            sizeGrid: grid,
        };
        mutate(dto);
    };

    React.useEffect(() => {
        order.sizeGrid?.map((item) => {
            setSizeValues((prev: any) => [
                ...prev,
                { name: item.size, count: item.shippedQuantity },
            ]);
        });

        return () => setSizeValues([]);
    }, [order]);

    const handleFormChange = (
        index: number,
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        const data = [...sizeValues];
        data[index]['count'] = Number(event.currentTarget.value);
        setSizeValues(data);
    };

    return (
        <Dialog hidden={!isModalOpen} onDismiss={() => setModalState(true)} minWidth={500}>
            <div className="mb-4 border-b pb-4">
                <h3>Edit Order Info</h3>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col md={6}>
                        <>
                            <p className="mt-[5px] mb-[5px]">
                                <b>Size - Shipped Quantity</b>
                            </p>
                            {sizeValues.length !== 0 &&
                                sizeValues.map((size: any, i: number) => (
                                    <div className="flex items-baseline" key={size.name}>
                                        <div className="flex items-center min-w-[30px] justify-between">
                                            <b>{size.name}</b>
                                            <b className="mr-1">-</b>
                                        </div>
                                        <Controller
                                            control={control}
                                            name={size.name}
                                            render={() => (
                                                <TextField
                                                    multiline={false}
                                                    onChange={(e) => handleFormChange(i, e)}
                                                    resizable={false}
                                                    rows={5}
                                                    className="mb-4"
                                                    value={sizeValues[i]['count']}
                                                    type="number"
                                                    min={0}
                                                    minLength={0}
                                                />
                                            )}
                                        />
                                    </div>
                                ))}
                        </>
                    </Col>
                    <Col md={6}>
                        <>
                            <p className="mt-[5px] mb-[5px]">
                                <b>Size - Original Quantity</b>
                            </p>
                            {order.sizeGrid.length !== 0 &&
                                order.sizeGrid.map((item: any) => (
                                    <div className="flex items-baseline" key={item.name}>
                                        <div className="flex items-center min-w-[30px] justify-between">
                                            <b>{item.size}</b>
                                            <b className="mr-1">-</b>
                                        </div>
                                        <TextField className="mb-4" value={item.count} disabled />
                                    </div>
                                ))}
                        </>
                    </Col>
                    {/* <Col md={8}>
                        <ControlledDatePicker
                            control={control}
                            name="etd"
                            defaultValue={'true'}
                            label="ETD"
                        />
                        <Controller
                            control={control}
                            name="unitPrice"
                            render={({ field }) => (
                                <div className="mt-4">
                                    <p className="font-semibold mb-2">{`Price in ${
                                        order.cost?.currencyId || 'USD'
                                    }`}</p>
                                    <TextField
                                        multiline={false}
                                        onChange={field.onChange}
                                        resizable={false}
                                        rows={5}
                                        value={field.value.toString()}
                                        type="number"
                                        min={0}
                                        minLength={0}
                                        step={0.01}
                                        disabled={true}
                                    />
                                </div>
                            )}
                        />
                    </Col> */}
                </Row>
                <DialogFooter>
                    <DefaultButton onClick={() => setModalState(false)} text="Cancel" />
                    <DefaultButton type="submit" text="Submit" />
                </DialogFooter>
            </form>
        </Dialog>
    );
};
