import { ActionButton, DefaultButton, TextField } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { Container } from 'react-grid-system';
import { Controller, useForm } from 'react-hook-form';

import { textFieldStyles } from '../../../consts';
import { useFilterSearchParams } from '../../../helpers/hooks/orders';
import { notifyRequestErr } from '../../../helpers/utils';
import { orderService } from '../../../services/orderService';
import { IOrderFilter } from '../../../store/models.ts/common';
import { useShippingAndTrackingContext } from '../context/shippingAndTracking';
import SelectField from './select';

const defualtValues: Omit<IOrderFilter, 'pageIndex' | 'pageSize'> = {
    orderId: '',
    season: '',
    style: '',
    vendorDs: '',
    destination: '',
    productionStatusId: [],
    delivery: '',
    colorDescription: '',
    searchKey: '',
};

export const Filter: React.FC = () => {
    const { setCheckedRows, setLoadMoreData } = useShippingAndTrackingContext();
    const [searchBarElements, setSearchBarElements] = useState<any>();
    const [searchBarDataLoading, setSearchBarDataLoading] = useState(true);
    const [searchValues, setSearchValues] = useState<any>();
    const [filterValues, updateFilters] = useFilterSearchParams();
    const { handleSubmit, control, setValue, reset } = useForm<
        Omit<IOrderFilter, 'page' | 'items'>
    >({
        defaultValues: defualtValues,
        reValidateMode: 'onChange',
    });

    useEffect(() => {
        orderService
            .getSearchBarData(false)
            .then((res) => {
                setSearchBarElements(res);
            })
            .catch((err) => notifyRequestErr(err))
            .finally(() => {
                setSearchBarDataLoading(false);
            });
        const paramSearchValues = {};
        Object.keys(filterValues).forEach((key) => {
            if (
                ['orderIds', 'vendors', 'styles', 'seasons', 'deliveries', 'warehouses'].includes(
                    key,
                )
            ) {
                paramSearchValues[key] = filterValues[key].map((item) => ({ value: item }));
            } else if (['colorDescription', 'searchKey'].includes(key)) {
                setValue(key as any, filterValues[key]);
            }
        });
        setSearchValues(paramSearchValues);
    }, []);

    const onSubmit = (data: Partial<IOrderFilter>) => {
        if (!searchValues) return;
        setCheckedRows([]);
        const updatedFilters = { ...data };
        Object.keys(searchValues).forEach(
            (key) => (updatedFilters[key] = searchValues[key].map((item) => item.value)),
        );
        updateFilters(updatedFilters);
        setLoadMoreData(false);
    };

    const handleResetForm = () => {
        reset(defualtValues);
        setSearchValues({});
        updateFilters({});
        setLoadMoreData(false);
    };

    return (
        <>
            <Container style={{ maxWidth: '97.5%' }} className="mt-10 mb-10 filter">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex justify-between items-start">
                        <div className="items-end flex flex-wrap gap-x-4 gap-y-2">
                            <SelectField
                                fieldName="orderIds"
                                fieldPlaceholder="PO#"
                                fieldOptionValue="poDocId"
                                searchValues={searchValues}
                                setSearchValues={setSearchValues}
                                fieldOptionLabel="poIdToBeDisplayed"
                                searchBarElements={searchBarElements}
                                searchBarDataLoading={searchBarDataLoading}
                                filterOption={(option, searchText) => {
                                    return (
                                        option.value
                                            .replaceAll('-', '')
                                            .includes(searchText.replaceAll('-', '')) ||
                                        option.label
                                            .replaceAll('-', '')
                                            .includes(searchText.replaceAll('-', ''))
                                    );
                                }}
                            />
                            <SelectField
                                fieldName="seasons"
                                fieldPlaceholder="Season"
                                fieldOptionValue="season"
                                fieldOptionLabel="season"
                                searchValues={searchValues}
                                setSearchValues={setSearchValues}
                                searchBarElements={searchBarElements}
                                searchBarDataLoading={searchBarDataLoading}
                            />
                            <SelectField
                                fieldName="deliveries"
                                fieldPlaceholder="Delivery"
                                fieldOptionValue="delivery"
                                fieldOptionLabel="delivery"
                                searchValues={searchValues}
                                setSearchValues={setSearchValues}
                                searchBarElements={searchBarElements}
                                searchBarDataLoading={searchBarDataLoading}
                            />
                            <SelectField
                                fieldName="styles"
                                fieldPlaceholder="Style"
                                fieldOptionValue="style"
                                fieldOptionLabel="style"
                                searchValues={searchValues}
                                setSearchValues={setSearchValues}
                                searchBarElements={searchBarElements}
                                searchBarDataLoading={searchBarDataLoading}
                            />
                            <SelectField
                                fieldName="vendors"
                                fieldPlaceholder="Vendor"
                                fieldOptionValue="vendorId"
                                fieldOptionLabel="vendorDs"
                                searchValues={searchValues}
                                setSearchValues={setSearchValues}
                                searchBarElements={searchBarElements}
                                searchBarDataLoading={searchBarDataLoading}
                            />
                            <SelectField
                                fieldName="warehouses"
                                fieldPlaceholder="Warehouse"
                                fieldOptionValue="warehouseId"
                                fieldOptionLabel="warehouseDs"
                                searchValues={searchValues}
                                setSearchValues={setSearchValues}
                                searchBarElements={searchBarElements}
                                searchBarDataLoading={searchBarDataLoading}
                            />
                            <Controller
                                control={control}
                                name="colorDescription"
                                render={({ field: { value, onChange } }) => (
                                    <TextField
                                        value={value}
                                        onChange={onChange}
                                        placeholder="Color Description"
                                        className="filter_border_style"
                                        styles={textFieldStyles}
                                    />
                                )}
                            />
                            <Controller
                                control={control}
                                name="searchKey"
                                render={({ field: { value, onChange } }) => (
                                    <TextField
                                        value={value}
                                        onChange={onChange}
                                        placeholder="Full Search"
                                        className="filter_border_style"
                                        styles={textFieldStyles}
                                    />
                                )}
                            />
                        </div>
                        <div className="flex items-center justify-end w-[210px]">
                            <DefaultButton
                                text="Search"
                                allowDisabledFocus
                                className="mr-4"
                                type="submit"
                            />
                            <div className="flex items-center">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-5 w-5"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                                <ActionButton
                                    className="underline p-0 whitespace-nowrap"
                                    onClick={handleResetForm}
                                >
                                    Clear filters
                                </ActionButton>
                            </div>
                        </div>
                    </div>
                </form>
            </Container>
            <div className="mt-10 mb-10  container mx-auto filter"></div>
        </>
    );
};
