import 'react-toastify/dist/ReactToastify.css';

import React from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { Navbar } from '../common/navbar';
import { SurveyListTable } from '../components/survey/table';
import { ROUTES } from '../consts';
import { usePermission, VendorSurveyContext } from '../helpers/hooks';
import { Dashboard } from '../pages/dashboard';
import PackingList from '../pages/packingList';
import PackingListDetail from '../pages/packingListDetail';
import { ProductionFollowUp } from '../pages/productionFollowUp';
import ShippingAndTracking from '../pages/shippingAndTracking';
import { Survey } from '../pages/survey';
import { ViewOrderDetail } from '../pages/viewOrderDetail';
import { getSurveyInfosForVendor } from '../services/survey';

export interface IProvider {
    loaderIsActive: boolean;
    setLoaderIsActive: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LoaderContext = React.createContext<IProvider | null>(null);

export const Router: React.FC = () => {
    const [loaderIsActive, setLoaderIsActive] = React.useState(false);
    const [vendorSurveyValue, setVendorSurveyValue] = React.useState<any | null>(null);
    const { showVendorSurveyPage } = usePermission();

    React.useEffect(() => {
        if (showVendorSurveyPage === true) {
            getSurveyInfosForVendor().then((data) => {
                if (data.status === 204) {
                    setVendorSurveyValue(null);
                } else {
                    setVendorSurveyValue(data.data);
                }
            });
        } else {
            setVendorSurveyValue(null);
        }
    }, [showVendorSurveyPage]);

    return (
        <>
            <LoaderContext.Provider value={{ loaderIsActive, setLoaderIsActive }}>
                <LoadingOverlay active={loaderIsActive} spinner text="Loading..." className="z-10">
                    <div>
                        <VendorSurveyContext.Provider value={vendorSurveyValue}>
                            <Navbar />
                            <Routes>
                                <Route
                                    path={ROUTES.PRODUCTION_FOLLOW_UP}
                                    element={<ProductionFollowUp />}
                                />
                                <Route
                                    path={ROUTES.SHIPPING_AND_TRACKING}
                                    element={<ShippingAndTracking />}
                                />
                                <Route path={ROUTES.DASHBOARD} element={<Dashboard />} />

                                <Route
                                    path={`${ROUTES.ORDER_DETAILS}/:id`}
                                    element={<ViewOrderDetail />}
                                />

                                <Route path={ROUTES.SURVEY_LIST} element={<SurveyListTable />} />

                                <Route path={ROUTES.SURVEY} element={<Survey />} />
                                <Route path={ROUTES.PACKING_LIST} element={<PackingList />} />
                                <Route
                                    path={`${ROUTES.PACKING_LIST}/:id`}
                                    element={<PackingListDetail />}
                                />
                            </Routes>
                        </VendorSurveyContext.Provider>
                    </div>
                    <ToastContainer />
                </LoadingOverlay>
            </LoaderContext.Provider>
        </>
    );
};
