import { AxiosError } from 'axios';
import React from 'react';

import { IError } from '../store/models.ts/common';

/**
 * Check whether the object is IError (Typeguard function).
 *
 * @param {any | IError} obj Checked object.
 */
export function isError(obj: any | IError): obj is IError {
    return !!obj && (<IError>obj).error === true;
}

/**
 * Error wrapper.
 *
 * @param error Server-side or browser-side error.
 * @returns Error wrapped into IErrorsResult object.
 */
export function errorWrapper(error: AxiosError): IError {
    let result: IError;

    if (error.response) {
        if (error.response.data.code || error.response.data.message) {
            result = {
                ...error.response.data,
                httpCode: error.response.status,
            };
        } else if (isError(error.response.data)) {
            // server managed error
            result = error.response.data;
        } else {
            let message;

            if (
                error.response.headers &&
                error.response.headers['content-type'] &&
                error.response.headers['content-type'].indexOf('application/json') !== -1 &&
                error.response.data
            ) {
                message = error.response.data.toString();
            } else {
                message =
                    'Service is not available, please contact our support center if you need a help!';
            }

            result = {
                code: error.response.statusText || 'UNKNOWN_ERROR',
                httpCode: error.response.status || 400,
                message,
            };
        }
    } else {
        result = {
            code: error.code || 'UNKNOWN_ERROR',
            httpCode: error.code === 'ECONNABORTED' ? 408 : 400,
            message:
                'Service is not available, please contact our support center if you need a help!',
        };
    }

    return result;
}

/**
 * Logs error to the server.
 *
 * @param {Error} error JavaScript Error object.
 * @param {React.ErrorInfo} info Stacktrace information.
 */
export function logError(error: Error, info: React.ErrorInfo): void {
    console.error(`Unknown exception caught!`, error, info);
}
