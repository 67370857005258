import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { BaseUrl } from '../../consts';
import { getAuthHead } from '../../helpers/authHelper';
import { IPageable } from '../models.ts/common';
import { IOrder } from '../models.ts/ProductionFollowUp';
import { IPublishViewOrderFilters } from '../models.ts/publishViewOrder';

//http://localhost:8061/v1/ => replace baseUrl for mock data

export const fetchPublishViewOrders = createAsyncThunk(
    'productionFollowOrders/getAll',
    async (filter: IPublishViewOrderFilters) => {
        const auth = await getAuthHead();
        const response = await axios.post(
            `${BaseUrl}purchaseorders/getPurchaseOrders`,
            { ...filter },
            auth,
        );
        return response.data as IPageable<IOrder>;
    },
);
