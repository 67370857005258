import { Checkbox, TableCell, TableHead, TableRow } from '@mui/material';
import React from 'react';

import { useShippingAndTrackingContext } from '../context/shippingAndTracking';

export const ShippingAndTrackingTableHeader: React.FC = () => {
    const { checkedRows, setCheckedRows, orders } = useShippingAndTrackingContext();

    const isIndeterminate = checkedRows.length && checkedRows.length < orders.length ? true : false;

    const fullyChecked = checkedRows.length && checkedRows.length === orders.length ? true : false;

    const handleCheckAll = () => {
        setCheckedRows(orders.map((elem) => elem.document.poDocId));
    };

    const handleUncheckAll = () => {
        setCheckedRows([]);
    };

    const handleCheckChanges = () => {
        if (isIndeterminate || !checkedRows.length) {
            handleCheckAll();
        } else {
            handleUncheckAll();
        }
    };

    return (
        <TableHead className="bg-bg-gray py-10 container relative">
            <TableRow className="parent-row ">
                {/* <TableCell className="px-0 pl-2">
                    <button
                        disabled={!checkedRows.length}
                        onClick={() => toggleEditModal(false)}
                        className="cursor-pointer flex gap-2 items-center text-black border border-[#8a8886] px-3 py-1 rounded-sm disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                        Edit
                    </button>
                </TableCell> */}
                <TableCell>
                    <Checkbox
                        color="primary"
                        indeterminate={isIndeterminate}
                        checked={fullyChecked}
                        onChange={handleCheckChanges}
                    />
                </TableCell>
                <TableCell>Season / Delivery</TableCell>
                <TableCell>Style type</TableCell>
                <TableCell>Vendor</TableCell>
                <TableCell>WHS</TableCell>
                <TableCell>PO#</TableCell>
                <TableCell>Style#</TableCell>
                <TableCell>Color Desc</TableCell>
                <TableCell>Style Desc</TableCell>
                <TableCell>Original QTY</TableCell>
                <TableCell>Shipped QTY</TableCell>
                <TableCell>QTY Difference</TableCell>
                <TableCell>Packing List</TableCell>
                <TableCell>Invoice</TableCell>
                <TableCell>Hand Over Date to F.F</TableCell>
                <TableCell>AWB</TableCell>
                <TableCell>Planned ETD</TableCell>
                <TableCell>Planned in DC Date</TableCell>
                <TableCell>Planned ETA</TableCell>
                <TableCell>Received Date</TableCell>
                <TableCell>Received QTY</TableCell>
                <TableCell>Delivery Status</TableCell>
            </TableRow>
        </TableHead>
    );
};
