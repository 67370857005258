import ProgressBar from '@ramonak/react-progress-bar';
import React from 'react';
import { Col, Row } from 'react-grid-system';
import { useSelector } from 'react-redux';

import { EmptyPanel } from '../../common/emptyPanel';
import { Panel } from '../../common/panel';
import { SkeletonElement } from '../../common/skeleton';
import { EProcessStatus } from '../../helpers/utils/redux';
import { dashboardSelector } from '../../store/reducers/dashboardSlice';

export const QuantityPanel: React.FC = () => {
    const quantities = useSelector(dashboardSelector);
    return (
        <Panel>
            <div className="panelHeader border-b-2 p-8">
                <p className="text-[20px]">
                    <b>Ordered quantity by production stage</b>
                </p>
            </div>
            {quantities.status === EProcessStatus.PENDING ? (
                <SkeletonElement itemCount={11} className="p-8 pt-10 pb-4 height-full" />
            ) : quantities.status === EProcessStatus.SUCCESS ? (
                <Row className="quantities p-8 pt-5 pb-1">
                    {quantities.data?.productionStagesOrderedQuantities.length ? (
                        quantities.data?.productionStagesOrderedQuantities?.map((quantity) => (
                            <Col
                                md={12}
                                key={quantity.productionStageDs}
                                className="pb-[10px] pt-[5px]"
                            >
                                <p>
                                    <b>{quantity.productionStageDs}</b>
                                </p>
                                <ProgressBar
                                    completed={
                                        (quantity.pieces *
                                            quantities.data.orderSummaries.totalNumberOfPieces) /
                                        100
                                    }
                                    bgColor="#8AB3D9"
                                    height="4px"
                                    isLabelVisible={false}
                                    className="mb-4 mt-3"
                                />
                            </Col>
                        ))
                    ) : (
                        <EmptyPanel
                            text="Nothing to show here!"
                            className="pb-20 pt-20 h-[550px]"
                        />
                    )}
                </Row>
            ) : (
                <span>Error...</span>
            )}
        </Panel>
    );
};
