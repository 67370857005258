import { type ClassValue, clsx } from 'clsx';
import { saveAs } from 'file-saver';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs));
}

export async function downloadBase64DataAsFile(data: string, fileName: string) {
    return fetch(
        `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${data}`,
    )
        .then((res) => res.blob())
        .then((blobRes) => {
            const blob = new Blob([blobRes], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            saveAs(blob, fileName);
        });
}
