import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { commonRestConfig } from './consts';
import { errorWrapper } from './error';

/**
 * HTTP request method.
 */
type TRequestMethod = 'get' | 'post' | 'put' | 'delete' | 'patch';

/** Refresh token request promise. */
let refreshTokenPromise: Promise<any> | null = null;

const BASE_URL = commonRestConfig.baseURL;
/**
 * Executing HTTP request.
 *
 * @param {TRequestMethod} method HTTP request method.
 * @param {string} url Request URL.
 * @param {TRequestData} data Data for POST/PUT requests.
 * @param {AxiosRequestConfig} config Optional Axios config.
 */
function requestAPI<TRequestData, TResponseData>(
    method: TRequestMethod,
    url: string,
    data: TRequestData,
    config?: AxiosRequestConfig,
): Promise<TResponseData> {
    const request: AxiosRequestConfig = {
        ...commonRestConfig,
        ...config,
        data,
        method,
        url,
    };

    return new Promise<TResponseData>((resolve, reject) => {
        axios
            .request(request)
            .then((response: AxiosResponse<TResponseData>) => {
                resolve(response.data);
            })
            .catch((error: any) => {
                const wrappedError = errorWrapper(error);

                switch (wrappedError.httpCode) {
                    case 400:
                        reject(error);
                        break;
                    // case 401:
                    //     /** If status === UNAUTHORIZED - move to login page. */
                    //     location.href = `/`;
                    //     break;
                    case 406:
                        /** If status === NOT_ACCEPTABLE - refresh token. */

                        if (!refreshTokenPromise) {
                            const header = {
                                headers: {
                                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                                },
                            };
                            refreshTokenPromise = postRequest(
                                `${BASE_URL}/renew-token`,
                                data,
                                header,
                            );
                        }

                        refreshTokenPromise.then(
                            (response: any) => {
                                if (response && response.status === 'SUCCESS') {
                                    if (response.data) {
                                        localStorage.setItem('token', response.data);
                                    }
                                } else {
                                    window.location.href = '/login.html';
                                    localStorage.clear();
                                }
                                /** If token was refreshed, try request again. */
                                const header = {
                                    headers: {
                                        Authorization: 'Bearer ' + localStorage.getItem('token'),
                                    },
                                };
                                requestAPI(method, url, data, header).then(resolve as any);
                                refreshTokenPromise = null;
                            },
                            () => {
                                /** Else move to login page. */
                                location.href = '/';
                                refreshTokenPromise = null;
                            },
                        );
                        break;
                    case 500:
                        reject(error);
                        break;
                    default:
                        reject(wrappedError);
                }
            });
    });
}

/**
 * GET request method.
 *
 * @param {string} url REST path.
 * @param {AxiosRequestConfig} config Optional Axios config.
 */
export function getRequest<TResponseData>(
    url: string,
    config?: AxiosRequestConfig,
): Promise<TResponseData> {
    return requestAPI('get', url, {}, config);
}

/**
 * POST request method.
 *
 * @param {string} url REST path.
 * @param {TRequestData} data Data for POST request.
 * @param {AxiosRequestConfig} config Optional Axios config.
 */
export function postRequest<TRequestData, TResponseData>(
    url: string,
    data?: TRequestData,
    config?: AxiosRequestConfig,
): Promise<TResponseData> {
    return requestAPI('post', url, data, config);
}

/**
 * PUT request method.
 *
 * @param {string} url REST path.
 * @param {TRequestData} data Data for PUT request.
 * @param {AxiosRequestConfig} config Optional Axios config.
 */
export function putRequest<TRequestData, TResponseData>(
    url: string,
    data?: TRequestData,
    config?: AxiosRequestConfig,
): Promise<TResponseData> {
    return requestAPI('put', url, data, config);
}

/**
 * PATCH request method.
 *
 * @param {string} url REST path.
 * @param {TRequestData} data Data for PUT request.
 * @param {AxiosRequestConfig} config Optional Axios config.
 */
export function patchRequest<TRequestData, TResponseData>(
    url: string,
    data?: TRequestData,
    config?: AxiosRequestConfig,
): Promise<TResponseData> {
    return requestAPI('patch', url, data, config);
}

/**
 * DELETE request method.
 *
 * @param {string} url REST path.
 * @param {AxiosRequestConfig} config Optional Axios config.
 */
export function delRequest<TResponseData, TRequestData>(
    url: string,
    data?: TRequestData,
    config?: AxiosRequestConfig,
): Promise<TResponseData> {
    return requestAPI('delete', url, data, config);
}
