import { Menu } from '@headlessui/react';
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';

import { EPackingListStatus } from '../../enums';
import { notifyRequestErr } from '../../helpers/utils';
import { packingListService } from '../../services/packingList';
import { StatusBadge } from '../packing-list/table/row';
import { usePackingListDetailContext } from './context';
import { Link } from 'react-router-dom';
import { cn } from '../../utilities';

const PackingListDetailHeader = () => {
    const { packingList, setPackingList, packageData } = usePackingListDetailContext();
    const [statusLoading, setStatusLoading] = useState<number | null>(null);
    const [isPrintLoading, setIsPrintLoading] = useState<boolean>(false);
    if (!packingList || !packageData) return null;

    const { packagesCount, totalPackedQty } = packageData;
    const { statusId, packingListId, vendorDescription, createdOn } = packingList;
    const disableConfirm =
        statusId !== EPackingListStatus.DRAFT || packageData.packages.length === 0;
    const disableCancel = statusId === EPackingListStatus.CANCELLED;

    const updateStatus = useCallback(
        (statusId: number) => {
            setStatusLoading(statusId);
            packingListService
                .updatePackingList({
                    packingListId: packingList.packingListId,
                    statusId,
                })
                .then((data) => {
                    toast.success('Packing list status updated successfully');
                    packingList.statusId = data.statusId;
                    setPackingList({ ...packingList });
                })
                .catch((err) => {
                    notifyRequestErr(err);
                })
                .finally(() => {
                    setStatusLoading(null);
                });
        },
        [packingList, setPackingList],
    );

    const printLabel = () => {
        if (!packingList) return;
        setIsPrintLoading(true);
        const id = packingList.packingListId;

        packingListService
            .printLabel(id)
            .then((res: { fileName: string; fileBase64: string }) => {
                const linkSource = `data:application/pdf;base64,${res.fileBase64}`;
                const downloadLink = document.createElement('a');
                const fileName = res.fileName;

                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();
            })
            .catch((err) => {
                notifyRequestErr(err);
            })
            .finally(() => setIsPrintLoading(false));
    };

    return (
        <header className="flex items-center justify-between py-4 px-5 z-10">
            <div>
                <div className="flex gap-x-6">
                    <h1 className="text-[32px] leading-[32px] font-bold text-gray-700">
                        Packing List #PL {packingListId}
                    </h1>
                    <StatusBadge statusId={statusId} />
                </div>
                <h2 className="my-3 text-lg leading-[18px]">{vendorDescription}</h2>
                <p className="text-gray-500">{moment(createdOn).format('DD MMM YYYY')}</p>
            </div>
            <div className="flex gap-x-6">
                <div
                    className="w-[246px] p-6 rounded-lg border border-gray-200"
                    style={{
                        boxShadow:
                            '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
                    }}
                >
                    <h4 className="text-gray-500 text-sm leading-5">Total packed Units</h4>
                    <p className="mt-2 text-gray-900 text-4xl leading-[44px] tracking-[-0.72px]">
                        {`${totalPackedQty} unit${totalPackedQty > 1 ? 's' : ''}`}
                    </p>
                </div>
                <div
                    className="w-[246px] p-6 rounded-lg border border-gray-200"
                    style={{
                        boxShadow:
                            '0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)',
                    }}
                >
                    <h4 className="text-gray-500 text-sm leading-5">Total count of cartons</h4>
                    <p className="mt-2 text-gray-900 text-4xl leading-[44px] tracking-[-0.72px]">
                        {`${packagesCount} carton${packagesCount > 1 ? 's' : ''}`}
                    </p>
                </div>
                <div className="grid grid-cols-2 gap-5 pt-3.5 pb-2">
                    <Menu as="div" className="relative inline-block text-left z-[99999]">
                        <div>
                            <Menu.Button
                                type="button"
                                disabled={!!statusLoading || disableCancel}
                                className="w-full bg-white px-4 py-2.5 flex justify-center gap-x-2 border border-gray-300 hover:bg-gray-100/70 disabled:hover:bg-gray-300 disabled:hover:bg-transparent disabled:opacity-50 transition rounded-lg disabled:cursor-not-allowed"
                            >
                                {statusLoading === EPackingListStatus.CANCELLED
                                    ? 'Cancelling...'
                                    : 'Cancel Packing List'}
                            </Menu.Button>
                        </div>
                        <Menu.Items className="absolute right-0 mt-2 w-[400px] origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none ">
                            <div className="p-1">
                                <Menu.Item disabled>
                                    {() => (
                                        <p className="px-4 py-2">
                                            <span className="text-lg">
                                                Are you sure to cancel packing list?
                                            </span>
                                            <br />
                                            <ul className="list-disc pl-4">
                                                <li className="text-sm text-black/50 my-2">
                                                    After cancelling packing list you will not be
                                                    able to update or confirm packing list.
                                                </li>
                                                <li className="text-sm text-black font-bold">
                                                    ATTENTION - After cancelling this PL, please
                                                    remove all the printed labels from the boxes.
                                                </li>
                                            </ul>
                                        </p>
                                    )}
                                </Menu.Item>
                                <div className="flex gap-1">
                                    <Menu.Item>
                                        {() => (
                                            <button
                                                className={`hover:bg-black/50 hover:text-white transition group flex w-full items-center justify-center rounded-md px-6 py-2 text-base`}
                                            >
                                                Deny
                                            </button>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {() => (
                                            <button
                                                onClick={() =>
                                                    updateStatus(EPackingListStatus.CANCELLED)
                                                }
                                                className={`hover:bg-red-500 hover:text-white transition group flex w-full items-center justify-center rounded-md px-6 py-2 text-base`}
                                            >
                                                Confirm
                                            </button>
                                        )}
                                    </Menu.Item>
                                </div>
                            </div>
                        </Menu.Items>
                    </Menu>
                    <button
                        type="button"
                        disabled={!!statusLoading || disableConfirm}
                        onClick={() => updateStatus(EPackingListStatus.CONFIRMED)}
                        className="bg-black justify-center text-white px-4 py-2.5 flex gap-x-2 hover:opacity-80 disabled:hover:opacity-50 disabled:opacity-50 transition rounded-lg disabled:bg-[#E9E9E9] disabled:text-[#969696] disabled:cursor-not-allowed"
                    >
                        {statusLoading === EPackingListStatus.CONFIRMED
                            ? 'Confirming...'
                            : 'Confirm Packing List'}
                    </button>
                    <Link
                        to="/packing-list"
                        className="w-full bg-white px-4 py-2.5 flex justify-center gap-x-2 border border-gray-300 hover:bg-gray-100/70 disabled:hover:bg-gray-300 disabled:hover:bg-transparent disabled:opacity-50 transition rounded-lg disabled:cursor-not-allowed"
                    >
                        Go Back
                    </Link>
                    <button
                        onClick={printLabel}
                        disabled={isPrintLoading}
                        className={cn(
                            `bg-[#E9E9E9] py-2.5 rounded-lg hover:bg-[#d1d1d1] text-black transition disabled:opacity-50 disabled:hover:bg-[#E9E9E9]`,
                            isPrintLoading && 'cursor-wait',
                        )}
                    >
                        {isPrintLoading ? 'Printing...' : 'Print Label'}
                    </button>
                </div>
            </div>
        </header>
    );
};

export default PackingListDetailHeader;
