import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { Dialog, DialogFooter, DialogType } from '@fluentui/react/lib/Dialog';
import { useId } from '@fluentui/react-hooks';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useContext, useMemo, useState } from 'react';
import { Container } from 'react-grid-system';
import { useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Async, asyncRenderError } from '../common/async';
import { SkeletonElement } from '../common/skeleton';
import Comment from '../components/viewOrderDetails/comment';
import FileSystem from '../components/viewOrderDetails/fileSystem';
import { PoInfo } from '../components/viewOrderDetails/poInfo';
import { TopBar } from '../components/viewOrderDetails/topBar';
import { QUERY_KEYS } from '../consts';
import { useAsyncOrderDetails } from '../helpers/hooks/orders';
import { notifyRequestErr } from '../helpers/utils';
import { LoaderContext } from '../routes/router';
import { orderService } from '../services/orderService';
import { IExeption } from '../store/models.ts/common';

const dialogStyles = { main: { maxWidth: 500 } };

export const ViewOrderDetail: React.FC = () => {
    const { id } = useParams();
    const ctxt = useContext(LoaderContext);
    const queryClient = useQueryClient();
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(true);
    const [searchParams] = useSearchParams();
    const isMaster = useMemo(() => searchParams.get('isMaster'), [searchParams]);
    const [fileData, setFileData] = useState({
        orderId: '',
        fileName: '',
        fileNameToBeDisplayed: '',
        fileType: '',
    });
    const labelId: string = useId('dialogLabel');
    const subTextId: string = useId('subTextLabel');

    const asyncOrderDetails = useAsyncOrderDetails(id!);

    const { mutate } = useMutation(orderService.deleteFile, {
        onSuccess: async () => {
            toast.success('File deleted !', {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
            ctxt?.setLoaderIsActive(false);

            queryClient.invalidateQueries([QUERY_KEYS.SHEETS]);
        },
        onError: async (err: { response: { data: IExeption } }) => {
            notifyRequestErr(err);
            ctxt?.setLoaderIsActive(false);
        },
    });

    const { mutate: ackStatus } = useMutation(orderService.editOrderAckStatus, {
        onSuccess: async (data) => {
            ctxt?.setLoaderIsActive(false);
            toast.success('Status changed!', {
                position: toast.POSITION.TOP_CENTER,
            });

            const previousOrder: any = queryClient.getQueryData([QUERY_KEYS.ORDER_DETAILS], {
                exact: false,
            });

            previousOrder.intAcknowledgementStatusId = data[0].intAcknowledgementStatusId;
        },
        onError: async (err: { response: { data: IExeption } }) => {
            notifyRequestErr(err);
            ctxt?.setLoaderIsActive(false);
        },
    });

    const handleApproveDeletion = () => {
        setIsConfirmModalOpen(true);
        ctxt?.setLoaderIsActive(true);
        mutate(fileData);
    };

    const modalProps = React.useMemo(
        () => ({
            titleAriaId: labelId,
            subtitleAriaId: subTextId,
            isBlocking: false,
            styles: dialogStyles,
        }),
        [labelId, subTextId],
    );

    const dialogContentProps = {
        type: DialogType.normal,
        title: 'Are you sure ?',
        closeButtonAriaLabel: 'Close',
        subText: `*${fileData.fileNameToBeDisplayed}* - named file will be deleted !`,
    };

    return (
        <div className="bg-bg-gray editor-wrapp">
            <>
                <Async
                    branch={asyncOrderDetails}
                    renderSuccess={(order) => (
                        <>
                            <TopBar order={order} ackStatusMutate={ackStatus} />
                            <Container style={{ maxWidth: '97%' }} className="pl-0">
                                <PoInfo order={order} />
                                {isMaster !== 'false' && (
                                    <FileSystem
                                        setFileData={setFileData}
                                        setIsConfirmModalOpen={setIsConfirmModalOpen}
                                    />
                                )}
                            </Container>
                            <Comment />
                            <Dialog
                                hidden={isConfirmModalOpen}
                                onDismiss={() => setIsConfirmModalOpen(true)}
                                dialogContentProps={dialogContentProps}
                                modalProps={modalProps}
                            >
                                <DialogFooter>
                                    <PrimaryButton
                                        onClick={handleApproveDeletion}
                                        text="Delete"
                                        className="bg-black text-white"
                                    />
                                    <DefaultButton
                                        onClick={() => setIsConfirmModalOpen(true)}
                                        text="Cancel"
                                    />
                                </DialogFooter>
                            </Dialog>
                        </>
                    )}
                    renderEmpty={<p>Nothing to see here</p>}
                    renderError={asyncRenderError}
                    renderLoading={
                        <SkeletonElement itemCount={10} className="p-8 pt-11 pb-5 height-full" />
                    }
                />
            </>
        </div>
    );
};
