import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import PackingListDetail from '../components/packing-list-detail';
import { PackingListDetailProvider } from '../components/packing-list-detail/context';
import { fetchOrders } from '../store/asyncActions/order';

const PackingListDetailPage = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchOrders({ isMaster: false }));
    }, []);

    return (
        <PackingListDetailProvider>
            <PackingListDetail />
        </PackingListDetailProvider>
    );
};

export default PackingListDetailPage;
