import { ActionButton, DefaultButton } from '@fluentui/react';
import { ChevronLeftIcon } from '@fluentui/react-icons-mdl2';
import React from 'react';

interface IProps {
    handlePrevStep: () => void;
    activeStepId: number[];
}

export const ActionsButton: React.FC<IProps> = ({ handlePrevStep, activeStepId }) => {
    return (
        <div className="footer flex items-center mt-[150px] justify-between">
            <ActionButton
                onClick={handlePrevStep}
                style={{ color: '#AAAA' }}
                className="previous-button"
                disabled={activeStepId[1] === 2}
            >
                <ChevronLeftIcon className="mr-[10px]" />
                Previous section
            </ActionButton>
            <DefaultButton className="continue-button" text="Save and continue" type="submit" />
        </div>
    );
};
