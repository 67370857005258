import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { BaseUrl } from '../../consts';
import { getAuthHead } from '../../helpers/authHelper';
import { IPageable } from '../models.ts/common';
import { IOrder, IProductionFollowOrderFilters } from '../models.ts/ProductionFollowUp';
import { IProductionStageRes } from '../models.ts/ProductionStages';

//http://localhost:8061/v1/ => replace baseUrl for mock data

export const fetchProductionFollowOrders = createAsyncThunk(
    'productionFollowOrders/getAll',
    async (filter: IProductionFollowOrderFilters) => {
        const auth = await getAuthHead();
        const response = await axios.post(
            `${BaseUrl}purchaseorders/getPurchaseOrders`,
            { ...filter },
            auth,
        );
        return response.data as IPageable<IOrder>;
    },
);

export const fetchProductionStages = createAsyncThunk(
    'productionFollowOrders/getStages',
    async () => {
        const auth = await getAuthHead();
        const response = await axios.get(`${BaseUrl}purchaseorders/getProductionStages`, auth);
        return response.data as IProductionStageRes[];
    },
);
