import { DefaultButton } from '@fluentui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { ISurveybuttonStyles } from '../../consts';
import { LoaderContext } from '../../routes/router';
import { saveSurveySection } from '../../services/survey';
import { ActionsButton } from './actionButtons';
import { RatioInput } from './ratioInput';

export interface IGenderFormType {
    firstGenderQuestionSelect: string;
    ratioValue: string;
}

interface IProps {
    handlePrevStep: () => void;
    activeStepId: number[];
    handleNewStep: () => void;
    setGenderValues: React.Dispatch<React.SetStateAction<IGenderFormType | null>>;
    genderValues: IGenderFormType | null;
}

const formSchema = yup.object().shape({
    firstGenderQuestionSelect: yup.string().required('This field is required'),
    ratioValue: yup.string().required('This field is required'),
});

export const Gender: React.FC<IProps> = ({
    handleNewStep,
    handlePrevStep,
    activeStepId,
    genderValues,
    setGenderValues,
}) => {
    const ctxt = React.useContext(LoaderContext);
    const {
        control,
        formState: { errors },
        setValue,
        handleSubmit,
    } = useForm({
        resolver: yupResolver(formSchema),
        defaultValues:
            genderValues !== null
                ? genderValues
                : {
                      firstGenderQuestionSelect: '',
                      ratioValue: '',
                  },
        reValidateMode: 'onChange',
    });

    const onSubmit = React.useCallback(
        (data: IGenderFormType) => {
            const newVal = [
                { questionId: 20, answer: data.firstGenderQuestionSelect },
                { questionId: 21, answer: data.ratioValue },
            ];
            setGenderValues(data);
            ctxt?.setLoaderIsActive(true);
            saveSurveySection(newVal).then(() => {
                handleNewStep();
                ctxt?.setLoaderIsActive(false);
            });
        },
        [errors],
    );

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="survey-main">
                <p className="border-b border-black pb-1 mt-[100px]">Question 1</p>
                <h3>Is the vendor facilities female owned?</h3>

                <Controller
                    control={control}
                    name="firstGenderQuestionSelect"
                    render={({ field }) => (
                        <>
                            <DefaultButton
                                text="Yes"
                                onClick={() => {
                                    setValue('firstGenderQuestionSelect', 'true');
                                }}
                                styles={ISurveybuttonStyles}
                                className={`mr-4  ${
                                    field.value === 'true' ? 'bg-black text-white' : ''
                                }`}
                            />
                            <DefaultButton
                                text="No"
                                onClick={() => {
                                    setValue('firstGenderQuestionSelect', 'false');
                                }}
                                styles={ISurveybuttonStyles}
                                className={`mr-4  ${
                                    field.value === 'false' ? 'bg-black text-white' : ''
                                }`}
                            />
                            <span className="text-red-500">
                                {errors.firstGenderQuestionSelect?.message}
                            </span>
                        </>
                    )}
                />
            </div>
            <div className="survey-main">
                <p className="border-b border-black pb-1 mt-[100px]">Question 2</p>
                <h3>
                    What is the ratio of identifying men/women in leadership positions at the vendor
                    facilities?
                </h3>

                <Controller
                    control={control}
                    name="ratioValue"
                    render={() => (
                        <>
                            <RatioInput setValue={setValue} genderValues={genderValues} />
                            <span className="text-red-500">{errors.ratioValue?.message}</span>
                        </>
                    )}
                />
            </div>
            <ActionsButton handlePrevStep={handlePrevStep} activeStepId={activeStepId} />
        </form>
    );
};
