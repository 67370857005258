import 'draft-js/dist/Draft.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { DefaultButton } from '@fluentui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { convertToRaw, EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import draftToHtml from 'draftjs-to-html';
import React from 'react';
import { Editor } from 'react-draft-wysiwyg';

import { QUERY_KEYS, TEXT_EDITOR_TOOLBAR } from '../../../consts';
import { notifyRequestErr } from '../../../helpers/utils';
import { LoaderContext } from '../../../routes/router';
import { orderService } from '../../../services/orderService';
import { IExeption } from '../../../store/models.ts/common';

interface IProps {
    parentId: number;
    id: string | undefined;
    setShowReplies: React.Dispatch<React.SetStateAction<boolean>>;
}

const ReplyEditor: React.FC<IProps> = ({ parentId, id, setShowReplies }) => {
    const ctxt = React.useContext(LoaderContext);
    const queryClient = useQueryClient();
    const editorState = EditorState.createEmpty();
    const [replyContent, setReplyContent] = React.useState(editorState);

    const onEditorStateChange = (editorState: any) => {
        setReplyContent(editorState);
    };

    const { mutate } = useMutation(orderService.postComments, {
        onSuccess: async () => {
            ctxt?.setLoaderIsActive(false);
            setReplyContent(editorState);
            setShowReplies(true);

            queryClient.invalidateQueries([QUERY_KEYS.COMMENTS]);
        },
        onError: async (err: { response: { data: IExeption } }) => {
            // toast.error(err?.response?.data.message, {
            //     position: toast.POSITION.BOTTOM_RIGHT,
            // });
            notifyRequestErr(err);
            ctxt?.setLoaderIsActive(false);
        },
    });

    const handleSubmitReply = (parentId: number, id: string) => {
        const htmlOutput = stateToHTML(replyContent.getCurrentContent());
        if (id) {
            mutate({
                parentMessageId: parentId,
                title: null,
                content: htmlOutput,
                poDocId: id,
            });
        }
    };

    return (
        <>
            <Editor
                editorState={replyContent}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={onEditorStateChange}
                toolbar={TEXT_EDITOR_TOOLBAR}
            />
            <textarea
                style={{ display: 'none' }}
                disabled
                value={draftToHtml(convertToRaw(replyContent.getCurrentContent()))}
            />
            <div className="text-end">
                <DefaultButton
                    text="Reply"
                    allowDisabledFocus
                    className="mt-4 bg-black text-white pt-4 pb-4"
                    type="submit"
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    onClick={() => handleSubmitReply(parentId, id!)}
                />
            </div>
        </>
    );
};
export default ReplyEditor;
