import { DefaultButton, TextField } from '@fluentui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { ISurveybuttonStyles } from '../../consts';
import { LoaderContext } from '../../routes/router';
import { deleteFile, saveSurveySection } from '../../services/survey';
import { ActionsButton } from './actionButtons';
import { VendorSurveyDropzone } from './surveyDropzone';

export interface IRecyclingFormType {
    recyclingTextSelect: boolean | any;
    recyclingFileSelect: boolean | any;
    recyclingText?: string;
    recyclingFile?: any;
}

interface IProps {
    handlePrevStep: () => void;
    activeStepId: number[];
    handleNewStep: () => void;
    setRecyclingValues: React.Dispatch<React.SetStateAction<IRecyclingFormType | null>>;
    recyclingValues: IRecyclingFormType | null;
}

const formSchema = yup.object().shape({
    recyclingTextSelect: yup.string().required('This field is required'),
    recyclingFileSelect: yup.string().required('This field is required'),
    recyclingText: yup.string().when('recyclingTextSelect', {
        is: 'true',
        then: yup.string().required('This field is required'),
    }),
    recyclingFile: yup.array().when('recyclingFileSelect', {
        is: 'true',
        then: yup.array().min(1, 'This field is required').required(),
    }),
});

export const Recycling: React.FC<IProps> = ({
    handleNewStep,
    handlePrevStep,
    activeStepId,
    recyclingValues,
    setRecyclingValues,
}) => {
    const [showTextField, setShowTextField] = React.useState(false);
    const [showFileField, setShowFileField] = React.useState(false);
    const [_, setTest] = React.useState(false);
    const ctxt = React.useContext(LoaderContext);
    const {
        control,
        formState: { errors },
        setValue,
        handleSubmit,
        reset,
        getValues,
    } = useForm({
        resolver: yupResolver(formSchema),
        defaultValues:
            recyclingValues !== null
                ? recyclingValues
                : {
                      recyclingTextSelect: '',
                      recyclingFileSelect: '',
                      recyclingText: '',
                      recyclingFile: [],
                  },
        reValidateMode: 'onChange',
    });

    const onSubmit = React.useCallback(
        (data: IRecyclingFormType) => {
            const newVal = [
                { questionId: 9, answer: data.recyclingTextSelect },
                { questionId: 10, answer: data.recyclingText },
                { questionId: 11, answer: data.recyclingFileSelect },
                { questionId: 12, answer: data.recyclingFile.toString() },
            ];
            setRecyclingValues(data);
            ctxt?.setLoaderIsActive(true);
            saveSurveySection(newVal).then(() => {
                handleNewStep();
                ctxt?.setLoaderIsActive(false);
            });
        },
        [errors],
    );

    const removeFile = React.useCallback(
        (file: any) => {
            ctxt?.setLoaderIsActive(true);
            deleteFile(file).then(() => {
                const newFiles = [...getValues('recyclingFile')];
                setValue(
                    'recyclingFile',
                    newFiles.filter((item) => item !== file.toString()),
                );
                ctxt?.setLoaderIsActive(false);
            });
        },
        [getValues('recyclingFile')],
    );

    React.useEffect(() => {
        if (recyclingValues && recyclingValues !== ({} as any)) {
            reset(recyclingValues as any);
            if (recyclingValues?.recyclingText?.length) {
                setShowTextField(true);
            }
            if (recyclingValues?.recyclingFile && recyclingValues?.recyclingFile[0]?.length) {
                setShowFileField(true);
            }
        }
    }, [recyclingValues]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="survey-main">
                <p className="border-b border-black pb-1 mt-[100px]">Question 1</p>
                <h3>
                    Does the vendor facility recycle or use technology, machinery or processes that
                    attribute to more effective recycling efforts?
                </h3>

                <Controller
                    control={control}
                    name="recyclingTextSelect"
                    render={({ field }) => (
                        <>
                            <DefaultButton
                                text="Yes"
                                onClick={() => {
                                    setValue('recyclingTextSelect', 'true');
                                    setShowTextField(true);
                                }}
                                styles={ISurveybuttonStyles}
                                className={`mr-4  ${
                                    field.value === 'true' ? 'bg-black text-white' : ''
                                }`}
                            />
                            <DefaultButton
                                text="No"
                                onClick={() => {
                                    setValue('recyclingTextSelect', 'false');
                                    setShowTextField(false);
                                    setValue('recyclingText', '');
                                }}
                                styles={ISurveybuttonStyles}
                                className={`mr-4  ${
                                    field.value === 'false' ? 'bg-black text-white' : ''
                                }`}
                            />
                            <span className="text-red-500">
                                {errors.recyclingTextSelect?.message}
                            </span>
                        </>
                    )}
                />
                {showTextField && (
                    <div className="border-l border-black pl-5 mt-[30px]">
                        <span className="subQuestion mb-5">
                            Please outline what technology, machinery or processes the facility uses
                        </span>
                        <>
                            <Controller
                                control={control}
                                name="recyclingText"
                                render={({ field }) => (
                                    <TextField
                                        multiline={true}
                                        onChange={field.onChange}
                                        resizable={false}
                                        rows={5}
                                        className="mt-4"
                                        value={field.value}
                                    />
                                )}
                            />
                            <span className="text-red-500">{errors.recyclingText?.message}</span>
                        </>
                    </div>
                )}
            </div>
            <div className="survey-main">
                <p className="border-b border-black pb-1 mt-[100px]">Question 2</p>
                <h3>Does the vendor facility have certification(s) with regards to recycling?</h3>

                <Controller
                    control={control}
                    name="recyclingFileSelect"
                    render={({ field }) => (
                        <>
                            <DefaultButton
                                text="Yes"
                                onClick={() => {
                                    setValue('recyclingFileSelect', 'true');
                                    setShowFileField(true);
                                }}
                                styles={ISurveybuttonStyles}
                                className={`mr-4  ${
                                    field.value === 'true' ? 'bg-black text-white' : ''
                                }`}
                            />
                            <DefaultButton
                                text="No"
                                onClick={() => {
                                    setValue('recyclingFileSelect', 'false');
                                    setShowFileField(false);
                                    setValue('recyclingFile', ['']);
                                }}
                                styles={ISurveybuttonStyles}
                                className={`mr-4  ${
                                    field.value === 'false' ? 'bg-black text-white' : ''
                                }`}
                            />
                            <span className="text-red-500">
                                {errors.recyclingFileSelect?.message}
                            </span>
                        </>
                    )}
                />
                {showFileField && (
                    <div className="border-l border-black pl-5 mt-[30px]">
                        <span className="subQuestion mb-5">
                            Please upload certifications as evidence (relevant certifications are
                            Global Recycled Standard, Made in Green by OEKO-TEX, Nordic Ecolabel,
                            R-Cert, Step by OEKO-TEX - this list is non-exhaustive)
                        </span>
                        <Controller
                            control={control}
                            name="recyclingFile"
                            render={() => (
                                <VendorSurveyDropzone
                                    questionId={12}
                                    setValue={setValue}
                                    name="recyclingFile"
                                />
                            )}
                        />
                        {getValues('recyclingFile') && getValues('recyclingFile')[0]?.length !== 0 && (
                            <div className="acceptedFile mt-10">
                                <h3 className="mb-5">Accepted files :</h3>
                                {getValues('recyclingFile')?.map((item: any) => (
                                    <div
                                        key={item}
                                        className="flex items-center p-4 py-2 border justify-between mb-2"
                                    >
                                        <p>{item.split('-')[6]}</p>
                                        <button
                                            type="button"
                                            onClick={() => {
                                                removeFile(item);
                                                setTest((prev) => !prev);
                                            }}
                                            className="px-5 py-3 border-l pr-0 text-2xl"
                                        >
                                            X
                                        </button>
                                    </div>
                                ))}
                            </div>
                        )}
                        <span className="text-red-500 mt-3">{errors.recyclingFile?.message}</span>
                    </div>
                )}
            </div>
            <ActionsButton handlePrevStep={handlePrevStep} activeStepId={activeStepId} />
        </form>
    );
};
