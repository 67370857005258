import { createSlice } from '@reduxjs/toolkit';

import { EProcessStatus } from '../../helpers/utils/redux';
import { fetchSuppliers } from '../asyncActions/supplier';
import { ISupplier } from '../models.ts/common';
import { RootState } from '../store';

export interface IInitialState {
    data: ISupplier[];
    status: EProcessStatus;
}
const initialState: IInitialState = {
    data: [],
    status: EProcessStatus.IDLE,
};

export const supplierSlice = createSlice({
    name: 'supplier',
    initialState: initialState,
    reducers: {},

    extraReducers: {
        [fetchSuppliers.pending as any]: (state: IInitialState) => {
            state.status = EProcessStatus.PENDING;
        },
        [fetchSuppliers.fulfilled as any]: (state: IInitialState, action: any) => {
            state.status = EProcessStatus.SUCCESS;
            state.data = action.payload;
        },
        [fetchSuppliers.rejected as any]: (state: IInitialState) => {
            state.status = EProcessStatus.ERROR;
        },
    },
});

export const supplierSelector = (state: RootState) => state.supplier;

export default supplierSlice.reducer;
