import React, { RefObject, useEffect, useMemo, useState } from 'react';

import { IPermission } from '../../store/models.ts/common';

export const useExpose = (def = false) => {
    const [isOpen, setIsOpen] = React.useState(def);

    const open = React.useCallback(() => setIsOpen(true), []);
    const close = React.useCallback(() => setIsOpen(false), []);
    const toggle = React.useCallback((val) => {
        if (!val) {
            setIsOpen((prev) => !prev);
        } else {
            setIsOpen(val);
        }
    }, []);

    return { isOpen, open, close, toggle };
};

export const PermissionContext = React.createContext<IPermission | any>(null);
export function usePermission(): IPermission {
    return React.useContext(PermissionContext);
}

export const VendorSurveyContext = React.createContext<any | null>(null);

export function useOnScreen(ref: RefObject<HTMLElement>): [boolean, () => void] {
    const [isIntersecting, setIntersecting] = useState(false);

    const observer = useMemo(
        () => new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting)),
        [ref],
    );

    useEffect(() => {
        if (!ref.current) return;
        observer.observe(ref.current);
        return () => observer.disconnect();
    }, []);

    function observe() {
        if (!ref.current) return;
        observer.disconnect();
        observer.observe(ref.current);
    }

    return [isIntersecting, observe];
}
